import { JsonArray } from 'json-to-csv-in-browser';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'shared/hooks';

export const useSubmainReportData = () => {
  const { t } = useTranslation();
  const { units } = useAppSelector((st) => st.units);

  const { username } = useAppSelector((st) => st.auth);
  const { projectData } = useAppSelector((st) => st.projectData);
  const { submainValues } = useAppSelector((st) => st.submainState);

  const reportData = [
    {
      [t('catalog')]: projectData.manifoldPipe1,
      [`${t('length')} (${units.length})`]: projectData.manifoldPipe1Length,
      [`${t('dia')} (${units.pipeDiameter})`]: projectData.manifoldPipe1Dia,
      [`${t('veloctiy')} (${units.velocity})`]: submainValues.velocity1,
    },
    {
      [t('catalog')]: projectData.manifoldPipe2,
      [`${t('length')} (${units.length})`]: projectData.manifoldPipe2Length,
      [`${t('dia')} (${units.pipeDiameter})`]: projectData.manifoldPipe2Dia,
      [`${t('veloctiy')} (${units.velocity})`]: submainValues.velocity2,
    },
    {
      [t('catalog')]: projectData.manifoldPipe3,
      [`${t('length')} (${units.length})`]: projectData.manifoldPipe3Length,
      [`${t('dia')} (${units.pipeDiameter})`]: projectData.manifoldPipe3Dia,
      [`${t('veloctiy')} (${units.velocity})`]: submainValues.velocity3,
    },
  ];

  const infoData = [
    {
      [t('preparedFor')]: `${projectData.tradingName ?? ''} ${projectData.growerName ?? ''}`,
      [t('by')]: username,
      [t('_date')]: new Date(Date.now()).toLocaleString(),
    },
  ];

  const generalData = [
    {
      [t('pressureAtValve')]: submainValues.valvePressure ?? '',
      [t('disTo1stLateral')]: submainValues.firstLateral ?? '',
    },
  ];

  const tableData = submainValues.resultReportArray.map((item) => ({
    [`${t('length')} (${units.length})`]: item.len,
    [`${t('flow')} (${units.totalFlow})`]: item.flow,
    [`${t('velocity')} (${units.velocity})`]: item.vel,
    [`${t('headloss')} (${units.length})`]: item.headloss,
    [`${t('pressure')} (${units.pressure})`]: item.pressure,
  }));

  if (reportData && infoData && generalData && tableData) {
    const jsonArrayInfo = new JsonArray(infoData);
    const jsonArrayGeneral = new JsonArray(generalData);
    const jsonArrayReport = new JsonArray(reportData);
    const jsonArrayTable = new JsonArray(tableData);

    const strGeneral = jsonArrayGeneral.convertToCSVstring();
    const strInfo = jsonArrayInfo.convertToCSVstring();
    const strReport = jsonArrayReport.convertToCSVstring();
    const strTable = jsonArrayTable.convertToCSVstring();
    const strDisclaimer = t('repDisclaimer').trim();

    return `${strInfo} 
    
                      ${t('manifoldRep')}
                    
                      ${strReport}
                      
                      ${strGeneral}
                      
                      ${strTable}
    ${strDisclaimer}`;
  }
};
