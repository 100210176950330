import { useEffect, useMemo } from 'react';
import { useAppDispatch } from './useAppDispatch';
import { useAppSelector } from './useAppSelector';
import { CatalogItem } from 'shared/models';
import { SelectChangeEvent } from '@mui/material';
import { getEmitterGroupsThunk, getEmittersThunk, setLateralStateValue, setProjectValue } from 'shared/slices';
import { METRIC_DEFAULTS } from 'shared/constants';
import { Math_round } from 'shared/lib/calculation/mathRound';
import { ConvertUnit } from 'shared/lib/calculation/unitConverter';

const ALL = 'All';

export const useEmitterFilters = () => {
  const dispatch = useAppDispatch();
  const { masterGroups } = useAppSelector((state) => state.masterGroups);
  const { projectData } = useAppSelector((st) => st.projectData);
  const {
    lateralValues: { emitterNominalFlow, emitterSpacing },
  } = useAppSelector((st) => st.lateralState);
  const { units } = useAppSelector((st) => st.units);
  const { emitters, emitterGroups } = useAppSelector((st) => st.emitters);

  const updateEmitters = async (group: string) => {
    const emitters = await dispatch(
      getEmittersThunk({
        group,
        region: projectData.region,
        standard: projectData.standard,
        limited: projectData.limited,
      }),
    ).unwrap();

    if (!emitters) return;

    dispatch(
      setProjectValue({
        emitterCatalog: emitters.GetCatalogsResult.RootResults[0]?.CATLOG,
        ...(!projectData.integrated && {
          // EmitterSpacing: Math_round(
          //   ConvertUnit(
          //     emitters.GetCatalogsResult.RootResults[0].Spacing,
          //     METRIC_DEFAULTS.EmitterSpacing,
          //     units.emitterSpacing,
          //     null
          //   ),
          //   3
          // ),
          zoneEmitterSpacing: Math_round(
            ConvertUnit(
              emitters.GetCatalogsResult.RootResults[0]?.Spacing,
              METRIC_DEFAULTS.EmitterSpacing,
              units.emitterSpacing,
              null,
            ),
            3,
          ),
          zoneEmitterFlowRate: Math_round(
            ConvertUnit(emitters.GetCatalogsResult.RootResults[0]?.Qnom ?? 0, METRIC_DEFAULTS.Flow, units.flow, null),
            3,
          ),
          emitterPMax: Math_round(
            ConvertUnit(emitters.GetCatalogsResult.RootResults[0]?.Pmax, METRIC_DEFAULTS.Pressure, units.pressure, null),
            3,
          ),
          emitterPMin: Math_round(
            ConvertUnit(emitters.GetCatalogsResult.RootResults[0]?.Pmin, METRIC_DEFAULTS.Pressure, units.pressure, null),
            3,
          ),
          emitterA: emitters.GetCatalogsResult.RootResults[0]?.EMITTERQA,
          emitterB: emitters.GetCatalogsResult.RootResults[0]?.EMITTERQB,
          lateralKd: emitters.GetCatalogsResult.RootResults[0]?.KD,
          cvv: emitters.GetCatalogsResult.RootResults[0]?.MManuFactCV,
          hwCoef: emitters.GetCatalogsResult.RootResults[0]?.HWCOF,
          emitterNominalPressure: emitters.GetCatalogsResult.RootResults[0]?.Qnom,
          emitterNominalFlow: emitters.GetCatalogsResult.RootResults[0]?.PressureNominal_m_,
        }),
      }),
    );
  };

  const onMasterGroupChange = async (e: SelectChangeEvent<unknown>) => {
    dispatch(setProjectValue({ emitterMasterGroup: +(e.target.value as string) }));
    resetFilters();

    const groups = await dispatch(getEmitterGroupsThunk({ masterGroup: +(e.target.value as string) })).unwrap();

    if (!groups) return;

    dispatch(setProjectValue({ emitterGroup: groups.GetGroupTypesResult.RootResults[0].GROUPS }));

    await updateEmitters(groups.GetGroupTypesResult.RootResults[0].GROUPS);
  };

  const onGroupTypeChange = async (e: SelectChangeEvent<unknown>) => {
    dispatch(setProjectValue({ emitterGroup: e.target.value as string }));
    resetFilters();

    await updateEmitters(e.target.value as string);
  };
  const onEmitterChange = (_: any, item: CatalogItem) => {
    dispatch(
      setProjectValue({
        emitterCatalog: item.CATLOG,
        ...(!projectData.integrated && {
          // EmitterSpacing: Math_round(
          //   ConvertUnit(item.Spacing, METRIC_DEFAULTS.EmitterSpacing, units.emitterSpacing, null),
          //   3
          // ),
          zoneEmitterSpacing: Math_round(
            ConvertUnit(item.Spacing, METRIC_DEFAULTS.EmitterSpacing, units.emitterSpacing, null),
            3,
          ),
          emitterPMax: Math_round(ConvertUnit(item.Pmax, METRIC_DEFAULTS.Pressure, units.pressure, null), 3),
          emitterPMin: Math_round(ConvertUnit(item.Pmin, METRIC_DEFAULTS.Pressure, units.pressure, null), 3),
          emitterA: item.EMITTERQA,
          emitterB: item.EMITTERQB,
          lateralKd: item.KD,
          cvv: item.MManuFactCV,
          hwCoef: item.HWCOF,
          emitterNominalPressure: item.Qnom,
          emitterNominalFlow: item.PressureNominal_m_,
        }),
      }),
    );
  };

  const onNominalFlowChange = (e: SelectChangeEvent<unknown>) => {
    dispatch(
      setLateralStateValue({
        emitterNominalFlow: e.target.value === ALL ? e.target.value : +(e.target.value as string),
      }),
    );
  };
  const onSpacingChange = (e: SelectChangeEvent<unknown>) => {
    dispatch(
      setLateralStateValue({ emitterSpacing: e.target.value === ALL ? e.target.value : +(e.target.value as string) }),
    );
  };

  const nominalFlowFilter = (item: CatalogItem) => {
    if (projectData.region === 'USA' && item.AltQnom !== null) {
      return emitterNominalFlow !== ALL ? item.AltQnom === emitterNominalFlow : true;
    }

    return emitterNominalFlow !== ALL ? item.Qnom === emitterNominalFlow : true;
  };
  const spacingFilter = (item: CatalogItem) => {
    if (projectData.region === 'USA' && item.AltSpacing !== null) {
      return emitterSpacing !== ALL ? item.AltSpacing === emitterSpacing : true;
    }
    emitterSpacing;
    return emitterSpacing !== ALL ? item.Spacing === emitterSpacing : true;
  };

  const resetFilters = () => {
    dispatch(setLateralStateValue({ emitterNominalFlow: ALL, emitterSpacing: ALL }));
  };

  const emittersFiltered = useMemo(() => {
    return emitters.filter((item) => nominalFlowFilter(item) && spacingFilter(item));
  }, [emitterNominalFlow, emitterSpacing, emitters]);

  useEffect(() => {
    if (emittersFiltered.length && !emittersFiltered.find((item) => item.CATLOG === projectData.emitterCatalog)) {
      onEmitterChange(null, emittersFiltered[0]);
    }
  }, [emittersFiltered]);

  const nominalFlows = useMemo(() => {
    return [
      ALL,
      ...new Set(
        emitters
          .map((item) => (projectData.region === 'USA' && item.AltQnom !== null ? item.AltQnom : item.Qnom))
          .sort((a, b) => a - b),
      ),
    ];
  }, [emitters]);

  useEffect(() => {
    if (!emittersFiltered.find((item) => item.CATLOG === projectData.emitterCatalog)) {
      dispatch(
        setLateralStateValue({
          emitterNominalFlow: ALL,
          emitterSpacing: ALL,
        }),
      );
    }
  }, [nominalFlows]);

  const spacings = useMemo(() => {
    return [
      ALL,
      ...new Set(
        emitters
          .filter((item) => nominalFlowFilter(item))
          .map((item) => (projectData.region === 'USA' && item.AltSpacing !== null ? item.AltSpacing : item.Spacing))
          .sort((a, b) => a - b),
      ),
    ];
  }, [emitterNominalFlow, emitters]);

  useEffect(() => {
    if (!emittersFiltered.find((item) => item.CATLOG === projectData.emitterCatalog)) {
      dispatch(
        setLateralStateValue({
          emitterSpacing: ALL,
        }),
      );
    }
  }, [spacings]);

  const emitterSubtype = useMemo(
    () =>
      emitterGroups.find((item) => item.GROUPS === projectData.emitterGroup?.trim())
        ? projectData.emitterGroup?.trim()
        : '',
    [projectData.emitterGroup, emitterGroups],
  );
  const emitterProduct = useMemo(
    () => emittersFiltered.find((item) => item.CATLOG === projectData.emitterCatalog) ?? emittersFiltered[0],
    [projectData.emitterCatalog, emittersFiltered],
  );

  const emitterNominalFlowValue = useMemo(
    () => nominalFlows.find((item) => item === emitterNominalFlow) ?? '',
    [emitterNominalFlow, nominalFlows],
  );

  const emitterSpacingValue = useMemo(
    () => spacings.find((item) => item === emitterSpacing) ?? '',
    [emitterSpacing, spacings],
  );

  const filteredMasterGroups = useMemo(
    () => masterGroups.filter((item) => item.EMITTER),
    [masterGroups, projectData.integrated],
  );

  return {
    masterGroups: filteredMasterGroups,
    masterGroupId: projectData.emitterMasterGroup,
    onMasterGroupChange,
    emitterSubtype,
    onGroupTypeChange,
    nominalFlow: emitterNominalFlowValue,
    onNominalFlowChange,
    spacing: emitterSpacingValue,
    onSpacingChange,
    nominalFlows,
    spacings,
    emitterProduct,
    emittersFiltered,
    onEmitterChange,
  };
};
