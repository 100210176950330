import { SelectChangeEvent } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { customAxios } from 'shared/api';
import { useAppSelector } from 'shared/hooks';
import { CatalogItem } from 'shared/models';

const ALL = 'All';

export const useLaterals = ({
                              handleLateralDataChange,
                              region,
                              standard,
                              limited,
                            }: {
  region: string;
  standard: boolean;
  limited: boolean;
  handleLateralDataChange: (
    value: Partial<{
      masterGroup: number;
      group: string;
      lateral: string;
    }>,
  ) => void;
}) => {
  const { lateralGroups, laterals: defaultLaterals } = useAppSelector((state) => state.laterals);
  const { projectData } = useAppSelector((state) => state.projectData);

  const [groupTypes, setGroupTypes] = useState<any>(lateralGroups);
  const [laterals, setLaterals] = useState<CatalogItem[]>(defaultLaterals);

  const [groupsLoading, setGroupsLoading] = useState(false);
  const [lateralsLoading, setLateralsLoading] = useState(false);

  const [masterGroupId, setMasterGroupId] = useState(projectData.lateralMasterGroup);
  const [groupTypeId, setGroupTypeId] = useState(projectData.lateralGroup);
  const [diameter, setDiameter] = useState<string | number>(ALL);
  const [classType, setClassType] = useState<string | number>(ALL);
  const [flowPerUnit, setFlowPerUnit] = useState<string | number>(ALL);
  const [nominalFlow, setNominalFlow] = useState<string | number>(ALL);
  const [spacing, setSpacing] = useState<string | number>(ALL);
  const [lateral, setLateral] = useState<CatalogItem>(
    () => laterals.find((item) => item.CATLOG === projectData.lateralCatalog) as CatalogItem,
  );

  useEffect(() => {
    handleLateralDataChange({ lateral: lateral?.CATLOG });
  }, [lateral]);

  useEffect(() => {
    handleLateralDataChange({ group: groupTypeId });
  }, [groupTypeId]);

  useEffect(() => {
    handleLateralDataChange({ masterGroup: masterGroupId });
  }, [masterGroupId]);

  useEffect(() => {
    updateLaterals(groupTypeId, region, standard, limited);
  }, [region, standard, limited]);

  const updateLaterals = async (group: string, region: string, standard: boolean, limited: boolean) => {
    setLateralsLoading(true);
    const lateralsResponse = await customAxios.get<any>(
      'api/ClientBin/WaterSL-Web-WaterDomainService.svc/JSON/GetCatalogPerDiaClassFlowSpacing',
      {
        params: {
          group: group,
          region: region,
          standard: standard,
          limited: limited,
        },
      },
    );

    setLaterals(lateralsResponse.data.GetCatalogPerDiaClassFlowSpacingResult.RootResults as CatalogItem[]);
    setLateral(lateralsResponse.data.GetCatalogPerDiaClassFlowSpacingResult.RootResults[0] ?? {});
    setLateralsLoading(false);
  };

  const onMasterGroupChange = async (e: SelectChangeEvent<unknown>) => {
    setMasterGroupId(+(e.target.value as string));
    resetFilters();

    setGroupsLoading(true);
    const groupTypesResponse = await customAxios.get<any>(
      'api/ClientBin/WaterSL-Web-WaterDomainService.svc/JSON/GetGroupTypes',
      { params: { masterGroup: e.target.value } },
    );

    setGroupTypes(groupTypesResponse.data.GetGroupTypesResult.RootResults);
    setGroupTypeId(groupTypesResponse.data.GetGroupTypesResult.RootResults[0].GROUPS);
    setGroupsLoading(false);

    await updateLaterals(groupTypesResponse.data.GetGroupTypesResult.RootResults[0].GROUPS, region, standard, limited);
  };
  const onGroupTypeChange = async (e: SelectChangeEvent<unknown>) => {
    setGroupTypeId(e.target.value as string);
    resetFilters();

    await updateLaterals(e.target.value as string, region, standard, limited);
  };
  const onLateralChange = (e: any, item: CatalogItem) => {
    setLateral(item);
  };
  const onDiameterChange = (e: SelectChangeEvent<unknown>) => {
    setDiameter(e.target.value as string);
  };
  const onClassTypeChange = (e: SelectChangeEvent<unknown>) => {
    setClassType(e.target.value as string);
  };
  const onFlowPerUnitChange = (e: SelectChangeEvent<unknown>) => {
    setFlowPerUnit(e.target.value as string);
  };
  const onNominalFlowChange = (e: SelectChangeEvent<unknown>) => {
    setNominalFlow(e.target.value as string);
  };
  const onSpacingChange = (e: SelectChangeEvent<unknown>) => {
    setSpacing(e.target.value as string);
  };

  const diameterFilter = (item: CatalogItem) => {
    // if (projectData.Region === 'USA' && item.AltInlet !== null) {
    //   return lateralDiameter ? item.AltInlet === lateralDiameter : true;
    // }
    return diameter !== 'All' ? item.INLET === diameter : true;
  };

  const classTypeFilter = (item: CatalogItem) => {
    // if (projectData.Region === 'USA' && item.AltClass !== null) {
    //   return lateralClassType ? item.AltClass?.toString().trim() === lateralClassType : true;
    // }

    return classType !== 'All' ? item.Class.trim() === classType : true;
  };

  const flowPerUnitFilter = (item: CatalogItem) => {
    // if (projectData.Region === 'USA' && item.Q_gpm_100ft_ !== null) {
    //   return lateralFlowPerUnit ? item.Q_gpm_100ft_ === lateralFlowPerUnit : true;
    // }

    return flowPerUnit != 'All' ? item.Q_l_hr_100m_ === flowPerUnit : true;
  };

  const nominalFlowFilter = (item: CatalogItem) => {
    // if (projectData.Region === 'USA' && item.AltQnom !== null) {
    //   return lateralNominalFlow ? item.AltQnom === lateralNominalFlow : true;
    // }
    return nominalFlow != 'All' ? item.Qnom === nominalFlow : true;
  };
  const spacingFilter = (item: CatalogItem) => {
    // if (projectData.Region === 'USA' && item.AltSpacing !== null) {
    //   return lateralSpacing ? item.AltSpacing === lateralSpacing : true;
    // }
    return spacing != 'All' ? item.Spacing === spacing : true;
  };

  const resetFilters = () => {
    setDiameter(ALL);
    setClassType(ALL);
    setFlowPerUnit(ALL);
    setNominalFlow(ALL);
    setSpacing(ALL);
  };

  const lateralsFiltered = useMemo(() => {
    const result = laterals.filter(
      (item) =>
        diameterFilter(item) &&
        classTypeFilter(item) &&
        flowPerUnitFilter(item) &&
        nominalFlowFilter(item) &&
        spacingFilter(item),
    );

    if (!result.find((item) => item.CATLOG === lateral?.CATLOG)) {
      setLateral(result[0]);
    }

    return result;
  }, [diameter, classType, nominalFlow, spacing, laterals]);

  const diameters = useMemo(() => [ALL, ...new Set(laterals.map((item) => item.INTRNL))], [laterals]);
  const classTypes = useMemo(() => {
    setClassType(ALL);
    setFlowPerUnit(ALL);
    setNominalFlow(ALL);
    setSpacing(ALL);

    return [ALL, ...new Set(laterals.filter((item) => diameterFilter(item)).map((item) => item.Class))];
  }, [diameter, laterals]);
  const flowPerUnits = useMemo(() => {
    setFlowPerUnit(ALL);
    setNominalFlow(ALL);
    setSpacing(ALL);

    return [
      ALL,
      ...new Set(
        laterals
          .filter((item) => diameterFilter(item) && classTypeFilter(item))
          .map(
            (item) =>
              // projectData.Region === 'USA' && item.Q_gpm_100ft_ !== null ? item.Q_gpm_100ft_ ?? 0 : item.Q_l_hr_100m_
              item.Q_l_hr_100m_,
          ),
      ),
    ];
  }, [diameter, classType, laterals]);
  const nominalFlows = useMemo(() => {
    setNominalFlow(ALL);
    setSpacing(ALL);

    return [
      ALL,
      ...new Set(
        laterals
          .filter((item) => diameterFilter(item) && classTypeFilter(item) && flowPerUnitFilter(item))
          .map((item) => item.Qnom),
      ),
    ];
  }, [diameter, classType, flowPerUnit, laterals]);
  const spacings = useMemo(() => {
    setSpacing(ALL);

    return [
      ALL,
      ...new Set(
        laterals
          .filter(
            (item) =>
              diameterFilter(item) && classTypeFilter(item) && nominalFlowFilter(item) && flowPerUnitFilter(item),
          )
          .map((item) => item.Spacing),
      ),
    ];
  }, [diameter, classType, nominalFlow, flowPerUnit, laterals]);

  return {
    masterGroupId,
    onMasterGroupChange,
    onGroupTypeChange,
    diameter,
    onDiameterChange,
    diameters,
    classType,
    onClassTypeChange,
    classTypes,
    flowPerUnit,
    onFlowPerUnitChange,
    flowPerUnits,
    nominalFlow,
    onNominalFlowChange,
    nominalFlows,
    spacing,
    onSpacingChange,
    spacings,
    lateral,
    onLateralChange,
    lateralsFiltered,
    groupTypes,
    groupsLoading,
    lateralsLoading,
    groupTypeId,
  };
};
