import { FC, PropsWithChildren, useEffect } from 'react';
import {
  EDCGraph,
  EDCPlants,
  EDCSectionGraph,
  EDCTimer,
  EDCZoom,
  ElectricCalculator,
  EnterLengthValues,
  Exit,
  HydraulicCalculator,
  InjectionRate,
  Language,
  LateralDefineLengthReport,
  LateralMaxLengthReport,
  MainlineReport,
  NewProject,
  OpenProject,
  PressureFlowCalculator,
  PumpCalculator,
  Regions,
  SaveAsProject,
  SubmainReport,
  SystemSummaryReport,
  Template,
  TemporaryLogoutInformation,
  Units,
} from 'features';
import { EDCTables } from 'widgets';
import { useAppDispatch, useAppSelector, useMobileDetect } from 'shared/hooks';
import { showAddToHome } from 'shared/slices';
import { AddToHome } from 'features/AddToHome';

export const Modals: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useAppDispatch();
  const mobileInfo = useMobileDetect();
  const projectsLoading = useAppSelector((state) => state.projects.loading);
  const masterGroupsLoading = useAppSelector((state) => state.masterGroups.loading);
  const unitsLoading = useAppSelector((state) => state.units.loading);
  const projectDataLoading = useAppSelector((state) => state.projectData.loading);
  const emittersLoadedOnce = useAppSelector((state) => state.emitters.emittersLoadedOnce);
  const lateralsLoadedOnce = useAppSelector((state) => state.laterals.lateralsLoadedOnce);
  const mainlinesLoadedOnce = useAppSelector((state) => state.mainlines.mainlinesLoadedOnce);
  const submainsLoadedOnce = useAppSelector((state) => state.submains.submainsLoadedOnce);

  const loading =
    projectsLoading ||
    masterGroupsLoading ||
    unitsLoading ||
    projectDataLoading ||
    !lateralsLoadedOnce ||
    !emittersLoadedOnce ||
    !mainlinesLoadedOnce ||
    !submainsLoadedOnce;

  useEffect(() => {
    const isMobile = (mobileInfo?.isAndroid() || mobileInfo?.isIos()) && mobileInfo?.standalone === false;

    if (!localStorage.getItem('hasSeenAddToHome') && isMobile) {
      dispatch(showAddToHome());
    }
  });

  return (
    <>
      {children}

      {/*<ReloadModal />*/}
      <Language />
      <TemporaryLogoutInformation />

      {!loading ? (
        <>
          <AddToHome />
          <HydraulicCalculator />
          <PumpCalculator />
          <ElectricCalculator />
          <InjectionRate />
          <PressureFlowCalculator />
          <Template />
          <NewProject />
          <OpenProject />
          <SaveAsProject />
          <Regions />
          <Units />
          <Exit />
          <LateralDefineLengthReport />
          <LateralMaxLengthReport />
          <EnterLengthValues />
          <SubmainReport />
          <MainlineReport />
          <SystemSummaryReport />
          <EDCGraph />
          <EDCTables />
          <EDCPlants />
          <EDCSectionGraph />
          <EDCZoom />
          <EDCTimer />
        </>
      ) : null}
    </>
  );
};
