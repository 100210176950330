import { Box, Divider, MenuItem, SelectChangeEvent } from '@mui/material';
import { t } from 'i18next';
import { FC, useState } from 'react';
import { EDC_SPRINKLERS, EDC_MICRO_SPRINKLERS, EDC_FOGGERS } from 'shared/constants';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { setEDCCategoryFilter } from 'shared/slices';
import { EDCSelectCategory, Select } from 'shared/ui';

interface Props {
  emitterFamilies: string[];
  value: string;
  onChange: (value: string) => Promise<void>;
  loading: boolean;
}

export const EDCProductNameSelect: FC<Props> = ({ onChange, emitterFamilies, value, loading }) => {
  const dispatch = useAppDispatch();
  const { categoryFilter } = useAppSelector((st) => st.edcState);

  const sprinklers = categoryFilter.sprinkler ? emitterFamilies.filter((item) => EDC_SPRINKLERS.includes(item)) : [];
  const microSprinklers = categoryFilter.microSprinkler
    ? emitterFamilies.filter((item) => EDC_MICRO_SPRINKLERS.includes(item))
    : [];
  const foggers = categoryFilter.fogger ? emitterFamilies.filter((item) => EDC_FOGGERS.includes(item)) : [];

  const isEmitterFamilyAvailable =
    sprinklers.includes(value) || microSprinklers.includes(value) || foggers.includes(value);

  const concatenatedProducts = [...sprinklers, ...microSprinklers, ...foggers].sort();

  const [open, setOpen] = useState(false);

  const handleChange = (value: string) => {
    onChange(value);
    setOpen(false);
  };

  return (
    <Select
      label={`${t('name')}`}
      value={isEmitterFamilyAvailable ? value : ''}
      loading={loading}
      disabled={loading}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      paperMaxHeight={'500px'}
    >
      <EDCSelectCategory
        checked={categoryFilter.sprinkler}
        handleChange={(e) => dispatch(setEDCCategoryFilter({ sprinkler: e.target.checked }))}
        label="Sprinkler"
      />
      <EDCSelectCategory
        checked={categoryFilter.microSprinkler}
        handleChange={(e) => dispatch(setEDCCategoryFilter({ microSprinkler: e.target.checked }))}
        label="Micro Sprinkler"
      />
      <EDCSelectCategory
        checked={categoryFilter.fogger}
        handleChange={(e) => dispatch(setEDCCategoryFilter({ fogger: e.target.checked }))}
        label="Fogger"
      />
      <Divider />

      <Box sx={{ height: '280px', overflow: 'auto' }}>
        {concatenatedProducts.map((item) => (
          <MenuItem onClick={() => handleChange(item)} key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </Box>

      {concatenatedProducts.map((item) => (
        <MenuItem key={item} value={item} sx={{ display: 'none' }}>
          {item}
        </MenuItem>
      ))}
    </Select>
  );
};
