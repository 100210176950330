import { setEDCCalcResult, setEDCStateError, setEDCStateValue } from 'shared/slices';
import { useAppDispatch } from './useAppDispatch';
import { EDCCalcResult } from 'shared/lib/calculation/models';
import { HandleField } from 'shared/lib/calculation/edc/helper/HandleFiled/HandleField';
import { useAppSelector } from './useAppSelector';
import { calculateSC, calculateWettest, getTransformedArray } from 'shared/lib';

export const useEDCCalculate = () => {
  const dispatch = useAppDispatch();

  const { units } = useAppSelector((state) => state.units);
  const { currentEmitter } = useAppSelector((state) => state.emittersEDC);

  const {
    edcValues: {
      emitterSpacing,
      lateralSpacing,
      edgeInRow,
      edgeOffRow,
      bedWidth,
      placement,
      edge,
      mouseClickPositionY1,
      mouseClickPositionY2,
      scPercent,
      wettestPercent,
    },
    edcPlantsValues: {
      betweenPlants,
      betweenRows,
      plantDiameter,
      staggeredRows,
      inRows,
      offRows,
      centerPlantsBetweenLaterals,
    },
  } = useAppSelector((state) => state.edcState);

  const calculateEDC = () => {
    const isCalculationAvailable =
      !!+emitterSpacing && !!+lateralSpacing && !!+bedWidth && (+edge !== 0 ? !!edgeInRow && !!edgeOffRow : true);

    if (!isCalculationAvailable || !Object.keys(currentEmitter)) return;

    const placementCalculated = +edge === 0 ? placement : 5;

    const values = {
      unitSettings: units,
      FieldRainAt: null,
      tempdrainat: null,
      Placement: placementCalculated,
      cbEDCEmitter: { SelectedItem: currentEmitter },
      dEDGEPOSITIONX: +edgeInRow,
      dEDGEPOSITIONY: +edgeOffRow,
      EdgeType: +edge,
      _Shadows: false,
      lblMaximumUnit: units.appnRate,
      txtBedWidth: +bedWidth,
      txtLateralSpacing: lateralSpacing.toString(),
      txtEmitterSpacing: emitterSpacing.toString(),
      lblLateralSpacingUnit: units.lateralSpacing,
      lblEmitterSpacingUnit: units.emitterSpacing,
      lblBedWidthUnit: units.length,
      lastplacement: placementCalculated,
      BetweenPlants: +betweenPlants,
      BetweenRows: +betweenRows,
      PlantDiameter: +plantDiameter,
      StaggeredRows: staggeredRows,
      GroupPlantRows: false,
      RowsinGroup: 0,
      BetweenGroups: 0,
      InRows: +inRows,
      OffRows: +offRows,
      CenterPlantsbetweenlaterals: centerPlantsBetweenLaterals,
      range: true,
      _ShowGrowth: true,
      Graph: true,
      mouseClickPositionY1,
      mouseClickPositionY2,
    };

    console.log('####: values', values);

    const result = HandleField(values);

    if (result.Minimum !== undefined && result.Minimum <= 0) {
      dispatch(setEDCStateError({ minimum: true }));
    } else {
      dispatch(setEDCStateError({ minimum: false }));
    }
    if (result.Maximum !== undefined && result.Maximum <= 0) {
      dispatch(setEDCStateError({ maximum: true }));
    } else {
      dispatch(setEDCStateError({ maximum: false }));
    }
    if (result.Average !== undefined && result.Average <= 0) {
      dispatch(setEDCStateError({ average: true }));
    } else {
      dispatch(setEDCStateError({ average: false }));
    }
    if (result.TheCu !== undefined && result.TheCu <= 0) {
      dispatch(setEDCStateError({ cu: true }));
    } else {
      dispatch(setEDCStateError({ cu: false }));
    }
    if (result.TheDu !== undefined && result.TheDu <= 0) {
      dispatch(setEDCStateError({ du: true }));
    } else {
      dispatch(setEDCStateError({ du: false }));
    }

    dispatch(setEDCCalcResult(result as EDCCalcResult));

    dispatch(
      setEDCStateValue({
        average: result.Average,
        minimum: result.Minimum,
        maximum: result.Maximum,
        du: result.TheDu,
        cu: result.TheCu,
        sprinklersPerHa: Math.round(result.txtSprinklerCount ? +result.txtSprinklerCount : 0),
      }),
    );

    console.log('####: result', result);

    const transformedFieldAtRain = getTransformedArray(
      result.FieldRainAt?.filter((item: any[]) => item.filter(Boolean).length),
      +placement,
      +edge,
    );
    const transformedTempRainAt = getTransformedArray(
      result.tempdrainat?.filter((item: any[]) => item.filter(Boolean).length) ?? [],
      +placement,
      +edge,
    );
    const array = result?.tempdrainat?.length ? transformedTempRainAt : transformedFieldAtRain;

    const { minSquare, sc } = calculateSC(array, scPercent, result.EdgeLine ?? [], edge);
    const { maxSquare, wettest } = calculateWettest(array, wettestPercent, result.EdgeLine ?? [], edge);

    dispatch(setEDCStateValue({ scSquare: minSquare, wettestSquare: maxSquare, sc, wettest }));
  };

  return calculateEDC;
};
