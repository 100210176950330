import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  SelectChangeEvent,
  useMediaQuery,
} from '@mui/material';
import { ChangeEvent, FC, FocusEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { REGIONS } from 'shared/constants';
import { useAppSelector } from 'shared/hooks';

import { Input, PaperBox, Select } from 'shared/ui';

interface Props {
  region: string;
  handleInfoChange: (e: FocusEvent<HTMLInputElement>) => void;
  handleRegionChange: (e: SelectChangeEvent<unknown>) => void;
  handleCheckboxChange: (e: ChangeEvent<HTMLInputElement>) => void;
  checkboxes: {
    standard: boolean;
    limited: boolean;
  };
}

export const GeneralBlock: FC<Props> = ({
                                          handleInfoChange,
                                          checkboxes,
                                          handleCheckboxChange,
                                          handleRegionChange,
                                          region,
                                        }) => {
  const { t } = useTranslation();

  const { projectData } = useAppSelector((st) => st.projectData);
  const isMobile = useMediaQuery('(max-width: 550px)');

  return (
    <PaperBox>
      <Grid container columnSpacing={1}>
        <Grid item xs={isMobile ? 6 : 3}>
          <Input
            type='text'
            name='Designer'
            label={`${t('designer')}`}
            defaultValue={projectData.designer}
            onBlur={handleInfoChange}
          />
        </Grid>
        <Grid item xs={isMobile ? 6 : 3}>
          <Input
            type='text'
            name='Company'
            label={`${t('company')}`}
            defaultValue={projectData.company}
            onBlur={handleInfoChange}
          />
        </Grid>
        <Grid item xs={isMobile ? 6 : 3}>
          <Input
            type='text'
            name='Phone'
            label={`${t('phone')}`}
            defaultValue={projectData.phone}
            onBlur={handleInfoChange}
          />
        </Grid>
        <Grid item xs={isMobile ? 6 : 3}>
          <Select label={`${t('region')}`} value={region} onChange={handleRegionChange}>
            {REGIONS.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>

      <Grid container columnSpacing={1}>
        <Grid item xs={isMobile ? 6 : 3}>
          <Input
            type='text'
            name='Mobile'
            label={`${t('mobile')}`}
            defaultValue={projectData.mobile}
            onBlur={handleInfoChange}
          />
        </Grid>
        <Grid item xs={isMobile ? 6 : 3}>
          <Input
            type='text'
            name='Fax'
            label={`${t('fax')}`}
            defaultValue={projectData.fax}
            onBlur={handleInfoChange}
          />
        </Grid>
        <Grid item xs={isMobile ? 6 : 3}>
          <Input
            type='text'
            name='Email'
            label={`${t('email')}`}
            defaultValue={projectData.email}
            onBlur={handleInfoChange}
          />
        </Grid>
        <Grid item xs={isMobile ? 6 : 3} mt={1}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  name='Standard'
                  sx={{ pt: 0, pb: 0 }}
                  value={checkboxes.standard}
                  checked={checkboxes.standard}
                  onChange={handleCheckboxChange}
                />
              }
              label={t('standard')}
            />
            <FormControlLabel
              control={
                <Checkbox
                  name='Limited'
                  sx={{ pt: 0, pb: 0 }}
                  value={checkboxes.limited}
                  checked={checkboxes.limited}
                  onChange={handleCheckboxChange}
                />
              }
              label={t('limited')}
            />
          </FormGroup>
        </Grid>
      </Grid>
    </PaperBox>
  );
};
