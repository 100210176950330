import { Box, Grid, MenuItem, useMediaQuery } from '@mui/material';
import { submain_ico } from 'shared/assets';
import { useAppSelector } from 'shared/hooks';
import { IconLabel, ProductSelect, AvailableInfo, Select, PaperBox } from 'shared/ui';
import { useSubmains } from '../hooks';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  region: string;
  standard: boolean;
  limited: boolean;
  handleSubmainDataChange: (
    value: Partial<{
      masterGroup: number;
      group: string;
      submain: string;
    }>
  ) => void;
}

export const SubmainBlock: FC<Props> = ({ handleSubmainDataChange, limited, region, standard }) => {
  const { masterGroups } = useAppSelector((state) => state.masterGroups);
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width: 550px)');

  const {
    classType,
    classTypes,
    groupTypeId,
    groupTypes,
    groupsLoading,
    submain,
    submainsFiltered,
    submainsLoading,
    masterGroupId,
    onClassTypeChange,
    onGroupTypeChange,
    onSubmainChange,
    onMasterGroupChange,
  } = useSubmains({ handleSubmainDataChange, limited, region, standard });

  return (
    <Box mb={1}>
      <Box sx={{ mb: 1 }}>
        <IconLabel iconSrc={submain_ico}>Submain</IconLabel>
      </Box>
      <PaperBox>
        <Grid container columnSpacing={1}>
          <Grid item xs={4}>
            <Select label={`${t('emitterType')}`} value={masterGroupId} onChange={onMasterGroupChange}>
              {masterGroups
                .filter((item) => item.SUBMAIN)
                .map((item) => (
                  <MenuItem key={item.ID} value={item.ID}>
                    {item.MASTERGROUPNAME}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
          <Grid item xs={4}>
            <Select
              label={`${t('emitterSubtype')}`}
              value={groupTypeId}
              onChange={onGroupTypeChange}
              loading={groupsLoading}
              disabled={groupsLoading}
            >
              {groupTypes.map((item: any) => (
                <MenuItem key={item.GROUPS} value={item.GROUPS}>
                  {item.GROUPTYPENAME}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={4}>
            <Select
              label={`${t('class')}`}
              value={classType}
              onChange={onClassTypeChange}
              disabled={groupsLoading || submainsLoading}
            >
              {classTypes.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
        <Grid container columnSpacing={1} mt={1}>
          <Grid item xs={isMobile ? 12 : 8}>
            <ProductSelect
              value={submain}
              onChange={onSubmainChange}
              options={submainsFiltered}
              loading={submainsLoading}
              disabled={submainsLoading || groupsLoading}
              error={!submain}
            />
          </Grid>
          <Grid item xs={isMobile ? 12 : 4} mt={1}>
            <AvailableInfo amount={submainsFiltered.length} />
          </Grid>
        </Grid>
      </PaperBox>
    </Box>
  );
};
