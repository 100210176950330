import { Box, Grid, MenuItem, Typography, useMediaQuery } from '@mui/material';
import { t } from 'i18next';
import { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { emitter_ico } from 'shared/assets';
import { useAppDispatch, useAppSelector, useEmitterFilters } from 'shared/hooks';
import { formattedInputValue } from 'shared/lib';
import { setProjectValue } from 'shared/slices';
import {
  Accordion,
  AvailableInfo,
  IconLabel,
  Input,
  PaperBox,
  ProductSelect,
  Row,
  Select,
  ShepherdBlock,
} from 'shared/ui';

const EmitterContent = () => {
  const isTablet = useMediaQuery('(max-width:850px)');
  const isMobile = useMediaQuery('(max-width:550px)');
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { emitterGroups, emittersLoading, emitterGroupsLoading } = useAppSelector((state) => state.emitters);
  const { lateralErrors } = useAppSelector((st) => st.lateralState);
  const { projectData } = useAppSelector((state) => state.projectData);
  const { units } = useAppSelector((state) => state.units);

  const {
    masterGroups,
    masterGroupId,
    onMasterGroupChange,
    emitterSubtype,
    onGroupTypeChange,
    nominalFlow,
    onNominalFlowChange,
    nominalFlows,
    emitterProduct,
    emittersFiltered,
    onEmitterChange,
  } = useEmitterFilters();

  const changeEmitterSpacing = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setProjectValue({ emitterSpacing: formattedInputValue(e) }));
  };

  return (
    <>
      <Grid item container xs={12} columnSpacing={isMobile ? 1 : 3}>
        <Grid item xs={isTablet ? 6 : 3}>
          <Select label={`${t('type')}`} value={masterGroupId} onChange={onMasterGroupChange}>
            {masterGroups.map((item) => (
              <MenuItem key={item.ID} value={item.ID}>
                {/* TODO: need to change in DB! Temporary solution for the moment */}
                {item.MASTERGROUPNAME === 'Sprayer' ? 'Micro Sprinkler' : item.MASTERGROUPNAME}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={isTablet ? 6 : 3}>
          <Select
            label={`${t('subtype')}`}
            value={emitterSubtype}
            onChange={onGroupTypeChange}
            loading={emitterGroupsLoading}
            disabled={emitterGroupsLoading}
          >
            {emitterGroups.map((item) => (
              <MenuItem key={item.GROUPS} value={item.GROUPS}>
                {item.GROUPTYPENAME}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={isTablet ? 6 : 3}>
          <Select
            label={`${t('nominalFlow')}`}
            value={nominalFlow}
            onChange={onNominalFlowChange}
            disabled={emitterGroupsLoading || emittersLoading}
          >
            {nominalFlows.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={isTablet ? 6 : 3}>
          <Input
            label={`${t('emitterSpacing')} (${units.lateralSpacing})`}
            value={projectData.emitterSpacing ?? 0}
            onChange={changeEmitterSpacing}
            isErrorBox={lateralErrors.spacing}
          />
        </Grid>
      </Grid>

      <Grid item container xs={12} alignItems="center" columnSpacing={3}>
        <Grid item xs={isMobile ? 12 : 9}>
          <ShepherdBlock id="lateral-emitter-product-selected">
            <ProductSelect
              label={`${t('selectedProduct')}`}
              value={emitterProduct}
              onChange={onEmitterChange}
              options={emittersFiltered}
              loading={emittersLoading}
              disabled={emittersLoading || emitterGroupsLoading}
              error={!emitterProduct}
            />
          </ShepherdBlock>
        </Grid>

        <Grid item xs={isMobile ? 12 : 3} mt={isMobile ? 2 : 0}>
          <AvailableInfo amount={emittersFiltered.length} />
        </Grid>
      </Grid>
    </>
  );
};

export const EmitterBlock: FC = () => {
  const isTablet = useMediaQuery('(max-width:850px)');
  const { emitterProduct } = useEmitterFilters();
  const { projectData } = useAppSelector((st) => st.projectData);

  return (
    <Box pt="10px" width="100%">
      {isTablet ? (
        <Accordion
          defaultExpanded
          header={
            <Row
              sx={{
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              <IconLabel iconSrc={emitter_ico}>{t('emitter')}</IconLabel>
              <Typography noWrap fontSize={14} lineHeight="16px" color="primary.main" pl={4}>
                {projectData.region === 'USA' ? emitterProduct.AltCATLOGDESC : emitterProduct.CATLOGDESC}
              </Typography>
            </Row>
          }
        >
          <EmitterContent />
        </Accordion>
      ) : (
        <>
          <IconLabel iconSrc={emitter_ico}>{t('emitter')}</IconLabel>

          <PaperBox>
            <EmitterContent />
          </PaperBox>
        </>
      )}
    </Box>
  );
};
