import { CatalogItem } from 'shared/models';
import { Math_round } from './calculation/mathRound';

export const getEmitterKD = (currentEmitter: CatalogItem, currentLateral: CatalogItem) => {
  if (currentEmitter.CATLOGDESC.includes('katif') || currentEmitter.CATLOGDESC.includes('KATIF')) {
    return Math_round(40400 * Math.pow(currentLateral.INTRNL, -4.1183), 3);
  } else if (
    currentEmitter.CATLOGDESC.includes('JET+') ||
    currentEmitter.CATLOGDESC.includes('J.F.R') ||
    currentEmitter.CATLOGDESC.includes('TORNADO') ||
    currentEmitter.CATLOGDESC.includes('SPOT SPITER')
  ) {
    return Math_round(363.3 * Math.pow(currentLateral.INTRNL, -2.3), 3);
  } else if (
    currentEmitter.CATLOGDESC.includes('supertif') ||
    currentEmitter.CATLOGDESC.includes('SUPERTIF') ||
    currentEmitter.CATLOGDESC.includes('TUFFTIF') ||
    currentEmitter.CATLOGDESC.includes('E100')
  ) {
    return Math_round(25.08 * Math.pow(2.7182818284, currentLateral.INTRNL * -0.2817), 3);
  }

  return Math_round(currentEmitter.KD, 3);
};

export const getEmitterKDMaxLength = (currentEmitter: CatalogItem, currentLateral: CatalogItem) => {
  if (currentEmitter.CATLOGDESC.includes('katif') || currentEmitter.CATLOGDESC.includes('KATIF')) {
    return 40400 * Math.pow(currentLateral.INTRNL, -4.1183);
  } else if (
    currentEmitter.CATLOGDESC.includes('JET+') ||
    currentEmitter.CATLOGDESC.includes('J.F.R') ||
    currentEmitter.CATLOGDESC.includes('TORNADO') ||
    currentEmitter.CATLOGDESC.includes('SPOT SPITER')
  ) {
    return 363.3 * Math.pow(currentLateral.INTRNL, -2.3);
  } else if (
    currentEmitter.CATLOGDESC.includes('supertif') ||
    currentEmitter.CATLOGDESC.includes('SUPERTIF') ||
    currentEmitter.CATLOGDESC.includes('TUFFTIF') ||
    currentEmitter.CATLOGDESC.includes('E100')
  ) {
    return 25.08 * Math.pow(2.7182818284, currentLateral.INTRNL * -0.2817);
  }

  return currentEmitter.KD;
};
