import { Checkbox, FormControlLabel, Grid, useMediaQuery } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { setEDCStateValue, setEDCTablesResult, toggleEDCTables } from 'shared/slices';
import { Button, IconLabel, Input, Modal, PaperBox } from 'shared/ui';
import { calcTables } from 'shared/lib/calculation/edc/helper/calcTables';

import { EDCTablesGraph, EDCTablesGrid } from 'features';
import { combined_ico, emitter_ico } from 'shared/assets';
import { useEDCTablesExport } from 'features/EDCTables/hooks/useEDCTablesExport';
import { download } from 'json-to-csv-in-browser';

export const EDCTables = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isTablet = useMediaQuery('(max-width:850px)');

  const { openEDCTables } = useAppSelector((st) => st.modals);
  const { units } = useAppSelector((state) => state.units);
  const { edcValues } = useAppSelector((state) => state.edcState);
  const { currentEmitter } = useAppSelector((state) => state.emittersEDC);

  const toggle = () => dispatch(toggleEDCTables());

  const onMax1Change = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setEDCStateValue({ max1: +e.target.value }));
  };
  const onMax2Change = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setEDCStateValue({ max2: +e.target.value }));
  };
  const onMin1Change = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setEDCStateValue({ min1: +e.target.value }));
  };
  const onMin2Change = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setEDCStateValue({ min2: +e.target.value }));
  };
  const onStep1Change = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setEDCStateValue({ step1: +e.target.value }));
  };
  const onStep2Change = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setEDCStateValue({ step2: +e.target.value }));
  };

  const onCuChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setEDCStateValue({ tablesCu: e.target.checked }));
  };
  const onDuChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setEDCStateValue({ tablesDu: e.target.checked }));
  };
  const onAvgChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setEDCStateValue({ tablesAvg: e.target.checked }));
  };
  const onMinimumChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setEDCStateValue({ tablesMin: e.target.checked }));
  };

  const handleCalculateClick = () => {
    const placementCalculated = +edcValues.edge === 0 ? edcValues.placement : 5;

    const params = {
      maxL: +edcValues.max1,
      maxE: +edcValues.max2,
      stepL: +edcValues.step1,
      stepE: +edcValues.step2,
      minL: +edcValues.min1,
      minE: +edcValues.min2,
      avg: edcValues.tablesAvg,
      min: edcValues.tablesMin,
      cu: edcValues.tablesCu,
      du: edcValues.tablesDu,
      placement: edcValues.placement,
      unitSettings: units,
    };
    const calcParams = {
      Placement: placementCalculated,
      cbEDCEmitter: { SelectedItem: currentEmitter },
      EdgeType: +edcValues.edge,
      _Shadows: false,
      txtLateralSpacing: edcValues.lateralSpacing.toString(),
      txtEmitterSpacing: edcValues.emitterSpacing.toString(),
      txtBedWidth: +edcValues.bedWidth,
      lblBedWidthUnit: units.length,
      dEDGEPOSITIONX: +edcValues.edgeOffRow,
      dEDGEPOSITIONY: +edcValues.edgeInRow,
      BILATERAL_TRIANGULAR_LAYOUT: 6,
      TRIANGULAR_Y_LAYOUT: 1,
      LATERAL_LAYOUT: 2,
      lastplacement: edcValues.placement,
      range: false,
      Graph: false,
      mouseClickPositionY1: 0,
      mouseClickPositionY2: 0,
    };

    console.log('####: params', params);
    console.log('####: calcParams', calcParams);

    const result = calcTables(params, calcParams);

    dispatch(setEDCTablesResult(result));

    console.log('####: result', result);
  };

  const data = useEDCTablesExport();

  const onClickExport = () => download('tables.csv', data);

  return (
    <Modal maxWidth='lg' title={t('tables')} open={openEDCTables} onClose={toggle}>
      <Grid container columnSpacing={2} rowSpacing={1}>
        <Grid item xs={12}>
          <IconLabel iconSrc={emitter_ico}>{t('Between Emitters')}</IconLabel>
        </Grid>
        <Grid item container xs={isTablet ? 12 : 8} pt={0}>
          <Grid item xs={12}>
            <PaperBox sx={{ mt: 0 }}>
              <Grid container columnSpacing={2} rowSpacing={1}>
                <Grid item xs={4}>
                  <Input label={`${t('min')} (${units.length})`} value={edcValues.min1} onChange={onMin1Change} />
                </Grid>
                <Grid item xs={4}>
                  <Input label={`${t('max')} (${units.length})`} value={edcValues.max1} onChange={onMax1Change} />
                </Grid>
                <Grid item xs={4}>
                  <Input label={`${t('step')} (${units.length})`} value={edcValues.step1} onChange={onStep1Change} />
                </Grid>
              </Grid>
            </PaperBox>
          </Grid>
          <Grid item xs={12} mt={1}>
            <IconLabel iconSrc={combined_ico}>{t('Between Laterals')}</IconLabel>

            <PaperBox sx={{ mt: 0 }}>
              <Grid container columnSpacing={2} rowSpacing={1}>
                <Grid item xs={4}>
                  <Input label={`${t('min')} (${units.length})`} value={edcValues.min2} onChange={onMin2Change} />
                </Grid>
                <Grid item xs={4}>
                  <Input label={`${t('max')} (${units.length})`} value={edcValues.max2} onChange={onMax2Change} />
                </Grid>
                <Grid item xs={4}>
                  <Input label={`${t('step')} (${units.length})`} value={edcValues.step2} onChange={onStep2Change} />
                </Grid>
              </Grid>
            </PaperBox>
          </Grid>
        </Grid>

        <Grid item xs={isTablet ? 12 : 4}>
          <PaperBox sx={{ mt: 0, pb: 3 }}>
            <Grid container columnSpacing={2} rowSpacing={1}>
              <Grid item xs={6}>
                <FormControlLabel
                  label='Cu'
                  control={<Checkbox checked={edcValues.tablesCu} onChange={onCuChange} />}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  label='Du'
                  control={<Checkbox checked={edcValues.tablesDu} onChange={onDuChange} />}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  label={`${t('average')}`}
                  control={<Checkbox checked={edcValues.tablesAvg} onChange={onAvgChange} />}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  label={`${t('minimum')}`}
                  control={<Checkbox checked={edcValues.tablesMin} onChange={onMinimumChange} />}
                />
              </Grid>
            </Grid>
          </PaperBox>
        </Grid>
      </Grid>

      <Grid container columnSpacing={2} rowSpacing={1} mt={1}>
        <Grid item xs={isTablet ? 6 : 2}>
          <Button onClick={handleCalculateClick}>{t('calculate')}</Button>
        </Grid>
        <Grid item xs={isTablet ? 6 : 2}>
          <Button onClick={data ? onClickExport : undefined}>{t('export')}</Button>
        </Grid>
        <Grid item xs={5}></Grid>
        <Grid item xs={isTablet ? 12 : 3}>
          <Button>{t('setMinimum')}</Button>
        </Grid>
      </Grid>

      <Grid container columnSpacing={1} rowGap={1} mt={2}>
        <Grid item sm={6} xs={12}>
          <EDCTablesGrid />
        </Grid>
        <Grid item sm={6} xs={12}>
          <EDCTablesGraph />
        </Grid>
      </Grid>
    </Modal>
  );
};
