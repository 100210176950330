import { EmitterEDC } from 'shared/models';
import { Math_round } from './calculation/mathRound';

export const formatEDCProductName = (emitter: EmitterEDC) => {
  return `Emitter: ${emitter.Ename} Spinner: ${emitter.Spinner} Nozzle: ${emitter.Nozzle} ${
    emitter.Orient ? 'UPSD' : 'UP'
  } Pressure: ${emitter.pressure}[bar] Riser: ${emitter.RIser}[cm] FlowRate: ${Math_round(
    emitter.FlowRate,
    3
  )}[l/h] Range: ${emitter.Range}[m]`;
};
