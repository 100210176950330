import { JsonArray } from 'json-to-csv-in-browser';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'shared/hooks';

export const useMainlineReportData = () => {
  const { t } = useTranslation();
  const { units } = useAppSelector((st) => st.units);

  const { username } = useAppSelector((st) => st.auth);
  const { projectData } = useAppSelector((st) => st.projectData);
  const { mainlineValues } = useAppSelector((st) => st.mainlineState);

  const reportData = [
    {
      [t('catalog')]: projectData.mainlinePipe1,
      [`${t('length')} (${units.length})`]: projectData.mainlinePipe1Length,
      [`${t('dia')} (${units.pipeDiameter})`]: projectData.mainlinePipe1Dia,
      [`${t('veloctiy')} (${units.velocity})`]: mainlineValues.velocity1,
    },
    {
      [t('catalog')]: projectData.mainlinePipe2,
      [`${t('length')} (${units.length})`]: projectData.mainlinePipe2Length,
      [`${t('dia')} (${units.pipeDiameter})`]: projectData.mainlinePipe2Dia,
      [`${t('veloctiy')} (${units.velocity})`]: mainlineValues.velocity2,
    },
    {
      [t('catalog')]: projectData.mainlinePipe3,
      [`${t('length')} (${units.length})`]: projectData.mainlinePipe3Length,
      [`${t('dia')} (${units.pipeDiameter})`]: projectData.mainlinePipe3Dia,
      [`${t('veloctiy')} (${units.velocity})`]: mainlineValues.velocity3,
    },
  ];

  const infoData = [
    {
      [t('preparedFor')]: `${projectData.tradingName ?? ''} ${projectData.growerName ?? ''}`,
      [t('by')]: username,
      [t('_date')]: new Date(Date.now()).toLocaleString(),
    },
  ];

  const generalData = [
    {
      [t('totalFlow')]: projectData.totalFlow ?? '',
      [t('maxVelocity')]: mainlineValues.maxVelocity ?? '',
      [t('headloss')]: projectData.mainlinePipeHeadloss ?? '',
    },
  ];

  if (reportData && infoData && generalData) {
    const jsonArrayInfo = new JsonArray(infoData);
    const jsonArrayGeneral = new JsonArray(generalData);
    const jsonArrayReport = new JsonArray(reportData);

    const strGeneral = jsonArrayGeneral.convertToCSVstring();
    const strInfo = jsonArrayInfo.convertToCSVstring();
    const strReport = jsonArrayReport.convertToCSVstring();
    const strDisclaimer = t('repDisclaimer').trim();

    return `${strInfo} 
    
                      ${t('mainlineRep')}
                    
                      ${strReport}
                      
                      ${strGeneral}
      ${strDisclaimer}`;
  }
};
