import { JsonArray } from 'json-to-csv-in-browser';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'shared/hooks';
import { parseSlopes } from 'shared/lib';

export const useMaxLengthReportData = () => {
  const { t } = useTranslation();
  const { units } = useAppSelector((st) => st.units);

  const { username } = useAppSelector((st) => st.auth);
  const { projectData } = useAppSelector((st) => st.projectData);
  const { lateralValues } = useAppSelector((st) => st.lateralState);
  const { laterals } = useAppSelector((st) => st.laterals);
  const { emitters } = useAppSelector((st) => st.emitters);

  const currentProduct = projectData.integrated
    ? laterals.find((item) => item.CATLOG === projectData.lateralCatalog)
    : emitters.find((item) => item.CATLOG === projectData.emitterCatalog);

  const reportData = lateralValues.resultMaxLengthReportArray?.map((item) => ({
    [`${t('length')} (${units.length})`]: item.length,
    [`${t('inletPr')} (${units.pressure})`]: item.inletPressure,
    [`${t('lateralFlow')} (${units.totalFlow})`]: item.lateralFlow,
    [`${t('emitterFlow')} (${units.flow})`]: item.averageFlow,
    [`${t('velocity')} (${units.velocity})`]: item.velocity,
    [`${t('headloss')} (${units.length})`]: item.headloss,
    [`${t('totalHeadloss')} (${units.length})`]: item.totalHeadloss,
  }));

  const generalData = [
    {
      [`${t('inletPr')} (${units.pressure})`]: projectData.lateralInletPressure,
      [`${t('internalDia')} (${units.pipeDiameter})`]: projectData.lateralInletDia,
      [`${t('slope')} (%)`]: parseSlopes(projectData.lateralSlopes ?? '')[0]?.slope ?? '',
      [`${t('pMin')} (${units.pressure})`]: lateralValues.resultMaxLengthPMin,
      [`${t('pMax')} (${units.pressure})`]: lateralValues.resultMaxLengthPMax,
      [`${t('nominalFlow')} (${units.flow})`]: projectData.emitterNominalFlow,
      [`${t('nominalPr')} (${units.pressure})`]: projectData.emitterNominalPressure,
      [`${t('flushingVelocity')} (${units.velocity})`]: projectData.flushingVelocity ?? 0,
      [`${t('eu')} (%)`]: lateralValues.resultMaxLengthEU,
      [`${t('Du')} (%)`]: (lateralValues.resultMaxLengthDU as number) * 100,
      [`${t('Qmin/Qmax')}`]: 0,
    },
  ];

  const infoData = [
    {
      [t('preparedFor')]: `${projectData.tradingName ?? ''} ${projectData.growerName ?? ''}`,
      [t('by')]: username,
      [t('_date')]: new Date(Date.now()).toLocaleString(),
    },
  ];

  if (reportData && infoData && generalData) {
    const jsonArrayInfo = new JsonArray(infoData);
    const jsonArrayGeneral = new JsonArray(generalData);
    const jsonArrayReport = new JsonArray(reportData);

    const strGeneral = jsonArrayGeneral.convertToCSVstring();
    const strInfo = jsonArrayInfo.convertToCSVstring();
    const strReport = jsonArrayReport.convertToCSVstring();
    const strDisclaimer = t('repDisclaimer').trim();

    return `${strInfo} 
    
                      ${t('lateralRep')}
                      ${currentProduct?.CATLOGDESC}
                      ${strGeneral}
                      
                      ${strReport}
                      
    ${strDisclaimer}`;
  }
};
