import { useEffect, useMemo } from 'react';
import { SelectChangeEvent } from '@mui/material';
import { useAppDispatch } from './useAppDispatch';
import { useAppSelector } from './useAppSelector';
import { getEmittersEDCThunk, setCurrentEmitter, setCurrentEmitterFamily, setEDCStateValue } from 'shared/slices';
import { EmitterEDC } from 'shared/models';
import { useTranslation } from 'react-i18next';

const ALL = 'All';

export const useEDCFilters = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const {
    edcValues: { orient, spinner, nozzle, pressure, riser, flowRate, range },
  } = useAppSelector((st) => st.edcState);
  const { emittersEDC, currentEmitter } = useAppSelector((st) => st.emittersEDC);
  const { emitterFamilies, currentEmitterFamily } = useAppSelector((st) => st.emitterFamilies);

  // const onEmitterFamilyChange = async (e: SelectChangeEvent<unknown>) => {
  const onEmitterFamilyChange = async (value: string) => {
    // dispatch(setCurrentEmitterFamily(e.target.value as string));
    dispatch(setCurrentEmitterFamily(value as string));
    resetFilters();

    // const emitters = await dispatch(getEmittersEDCThunk({ ename: e.target.value as string })).unwrap();
    const emitters = await dispatch(getEmittersEDCThunk({ ename: value })).unwrap();

    if (!emitters) return;

    const isUPSDAble = emitters.GetEmittersForEnameResult.RootResults.find((item) => item.Orient);
    const isUPAble = emitters.GetEmittersForEnameResult.RootResults.find((item) => !item.Orient);
    const isBothAble = isUPSDAble && isUPAble;

    const orient = isBothAble ? 'both' : isUPAble ? 'up' : 'upsd';
    dispatch(setEDCStateValue({ orient }));
  };
  const onEmitterEDCChange = (_: any, item: EmitterEDC) => {
    dispatch(setCurrentEmitter(item));
  };
  const onOrientChange = (e: SelectChangeEvent<unknown>) => {
    dispatch(setEDCStateValue({ orient: e.target.value as string }));
  };
  const onSpinnerChange = (e: SelectChangeEvent<unknown>) => {
    dispatch(setEDCStateValue({ spinner: e.target.value as string }));
  };
  const onNozzleChange = (e: SelectChangeEvent<unknown>) => {
    dispatch(setEDCStateValue({ nozzle: e.target.value as string }));
  };
  const onPressureChange = (e: SelectChangeEvent<unknown>) => {
    dispatch(setEDCStateValue({ pressure: e.target.value as string }));
  };
  const onRiserChange = (e: SelectChangeEvent<unknown>) => {
    dispatch(setEDCStateValue({ riser: e.target.value as string }));
  };
  const onFlowRateChange = (e: SelectChangeEvent<unknown>) => {
    dispatch(setEDCStateValue({ flowRate: e.target.value as string }));
  };
  const onRangeChange = (e: SelectChangeEvent<unknown>) => {
    dispatch(setEDCStateValue({ range: e.target.value as string }));
  };

  const orientFilter = (item: EmitterEDC) => {
    if (orient === 'upsd') return item.Orient === true;
    if (orient === 'up') return item.Orient === false;
    return true;
  };

  const spinnerFilter = (item: EmitterEDC) => (spinner !== ALL ? item.Spinner === spinner : true);
  const nozzleFilter = (item: EmitterEDC) => (nozzle !== ALL ? item.Nozzle === nozzle : true);
  const pressureFilter = (item: EmitterEDC) => (pressure !== ALL ? item.pressure === pressure : true);
  const riserFilter = (item: EmitterEDC) => (riser !== ALL ? item.RIser === riser : true);
  const flowRateFilter = (item: EmitterEDC) => (flowRate !== ALL ? item.FlowRate === flowRate : true);
  const rangeFilter = (item: EmitterEDC) => (range !== ALL ? item.Range === range : true);

  const emittersEDCFiltered = useMemo(() => {
    return emittersEDC.filter(
      (item) =>
        orientFilter(item) &&
        spinnerFilter(item) &&
        nozzleFilter(item) &&
        pressureFilter(item) &&
        riserFilter(item) &&
        flowRateFilter(item) &&
        rangeFilter(item)
    );
  }, [emittersEDC, orient, spinner, nozzle, pressure, riser, flowRate, range]);

  useEffect(() => {
    if (
      emittersEDCFiltered.length &&
      !emittersEDCFiltered.find((item) => item.recnumber === currentEmitter.recnumber)
    ) {
      onEmitterEDCChange(null, emittersEDCFiltered[0]);
    }
  }, [emittersEDCFiltered]);

  const orients = useMemo(() => {
    const isUPSDAble = emittersEDC.find((item) => item.Orient);
    const isUPAble = emittersEDC.find((item) => !item.Orient);
    const isBothAble = isUPSDAble && isUPAble;

    return [
      ...(isBothAble ? [{ value: 'both', label: `${t('both')}` }] : []),
      ...(isUPAble ? [{ value: 'up', label: `${t('upright')}` }] : []),
      ...(isUPSDAble
        ? [
            {
              value: 'upsd',
              label: `${t('inverted')}`,
            },
          ]
        : []),
    ];
  }, [emittersEDC]);

  const spinners = useMemo(() => {
    return [
      ALL,
      ...new Set(
        emittersEDC
          .filter((item) => orientFilter(item))
          .map((item) => item.Spinner)
          .sort()
      ),
    ];
  }, [emittersEDC, orient]);

  useEffect(() => {
    if (!emittersEDCFiltered.find((item) => item.recnumber === currentEmitter.recnumber)) {
      dispatch(
        setEDCStateValue({
          spinner: ALL,
          nozzle: ALL,
          pressure: ALL,
          riser: ALL,
          flowRate: ALL,
          range: ALL,
        })
      );
    }
  }, [spinners]);

  const nozzles = useMemo(() => {
    return [
      ALL,
      ...new Set(
        emittersEDC
          .filter((item) => orientFilter(item) && spinnerFilter(item))
          .map((item) => item.Nozzle)
          .sort()
      ),
    ];
  }, [emittersEDC, orient, spinner]);

  useEffect(() => {
    if (!emittersEDCFiltered.find((item) => item.recnumber === currentEmitter.recnumber)) {
      dispatch(
        setEDCStateValue({
          nozzle: ALL,
          pressure: ALL,
          riser: ALL,
          flowRate: ALL,
          range: ALL,
        })
      );
    }
  }, [nozzles]);

  const pressures = useMemo(() => {
    return [
      ALL,
      ...new Set(
        emittersEDC
          .filter((item) => orientFilter(item) && spinnerFilter(item) && nozzleFilter(item))
          .map((item) => item.pressure)
          .sort((a, b) => a - b)
      ),
    ];
  }, [emittersEDC, orient, spinner, nozzle]);

  useEffect(() => {
    if (!emittersEDCFiltered.find((item) => item.recnumber === currentEmitter.recnumber)) {
      dispatch(
        setEDCStateValue({
          pressure: ALL,
          riser: ALL,
          flowRate: ALL,
          range: ALL,
        })
      );
    }
  }, [pressures]);

  const risers = useMemo(() => {
    return [
      ALL,
      ...new Set(
        emittersEDC
          .filter((item) => orientFilter(item) && spinnerFilter(item) && nozzleFilter(item) && pressureFilter(item))
          .map((item) => item.RIser)
          .sort((a, b) => a - b)
      ),
    ];
  }, [emittersEDC, orient, spinner, nozzle, pressure]);

  useEffect(() => {
    if (!emittersEDCFiltered.find((item) => item.recnumber === currentEmitter.recnumber)) {
      dispatch(
        setEDCStateValue({
          riser: ALL,
          flowRate: ALL,
          range: ALL,
        })
      );
    }
  }, [risers]);

  const flowRates = useMemo(() => {
    return [
      ALL,
      ...new Set(
        emittersEDC
          .filter(
            (item) =>
              orientFilter(item) &&
              spinnerFilter(item) &&
              nozzleFilter(item) &&
              pressureFilter(item) &&
              riserFilter(item)
          )
          .map((item) => item.FlowRate)
          .sort((a, b) => a - b)
      ),
    ];
  }, [emittersEDC, orient, spinner, nozzle, pressure, riser]);

  useEffect(() => {
    if (!emittersEDCFiltered.find((item) => item.recnumber === currentEmitter.recnumber)) {
      dispatch(
        setEDCStateValue({
          flowRate: ALL,
          range: ALL,
        })
      );
    }
  }, [flowRates]);

  const ranges = useMemo(() => {
    return [
      ALL,
      ...new Set(
        emittersEDC
          .filter(
            (item) =>
              orientFilter(item) &&
              spinnerFilter(item) &&
              nozzleFilter(item) &&
              pressureFilter(item) &&
              riserFilter(item) &&
              flowRateFilter(item)
          )
          .map((item) => item.Range)
          .sort((a, b) => a - b)
      ),
    ];
  }, [emittersEDC, orient, spinner, nozzle, pressure, riser, flowRate]);

  useEffect(() => {
    if (!emittersEDCFiltered.find((item) => item.recnumber === currentEmitter.recnumber)) {
      dispatch(
        setEDCStateValue({
          range: ALL,
        })
      );
    }
  }, [ranges]);

  const resetFilters = () => {
    dispatch(
      setEDCStateValue({
        spinner: ALL,
        nozzle: ALL,
        pressure: ALL,
        riser: ALL,
        flowRate: ALL,
        range: ALL,
      })
    );
  };

  const orientValue = useMemo(() => orients.find((item) => item.value === orient)?.value ?? '', [orient, orients]);
  const spinnerValue = useMemo(() => spinners.find((item) => item === spinner) ?? ALL, [spinner, spinners]);
  const nozzleValue = useMemo(() => nozzles.find((item) => item === nozzle) ?? ALL, [nozzle, nozzles]);
  const riserValue = useMemo(() => risers.find((item) => item === riser) ?? ALL, [riser, risers]);
  const pressureValue = useMemo(() => pressures.find((item) => item === pressure) ?? ALL, [pressure, pressures]);
  const flowRateValue = useMemo(() => flowRates.find((item) => item === flowRate) ?? ALL, [flowRate, flowRates]);
  const rangeValue = useMemo(() => ranges.find((item) => item === range) ?? ALL, [range, ranges]);

  const emitterEDCProduct = useMemo(
    () => emittersEDCFiltered.find((item) => item.recnumber === currentEmitter.recnumber) ?? emittersEDCFiltered[0],
    [currentEmitter, emittersEDCFiltered]
  );

  return {
    emitterFamilies,
    currentEmitterFamily,
    onEmitterFamilyChange,
    orients,
    orientValue,
    onOrientChange,
    spinners,
    spinnerValue,
    onSpinnerChange,
    nozzles,
    nozzleValue,
    onNozzleChange,
    pressures,
    pressureValue,
    onPressureChange,
    risers,
    riserValue,
    onRiserChange,
    flowRates,
    flowRateValue,
    onFlowRateChange,
    ranges,
    rangeValue,
    onRangeChange,
    emittersEDCFiltered,
    emitterEDCProduct,
    onEmitterEDCChange,
  };
};
