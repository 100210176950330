import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { customAxios } from 'shared/api';
import { EmitterEDC, EmittersEDCBody, EmittersEDCResponse } from 'shared/models';

interface UserState {
  loading: boolean;
  emittersEDCLoadedOnce: boolean;
  emittersEDC: EmitterEDC[];
  currentEmitter: EmitterEDC;
  comparisonEmitterLoading: boolean;
  comparisonEmittersEDCLoadedOnce: boolean;
  comparisonEmittersEDC: EmitterEDC[];
  comparisonCurrentEmitter: EmitterEDC;
}

const initialState: UserState = {
  loading: true,
  emittersEDCLoadedOnce: true,
  currentEmitter: {} as EmitterEDC,
  emittersEDC: [],
  comparisonEmitterLoading: true,
  comparisonEmittersEDCLoadedOnce: true,
  comparisonEmittersEDC: [],
  comparisonCurrentEmitter: {} as EmitterEDC,
};

export const getEmittersEDCThunk = createAsyncThunk('getEmittersEDC', async (params: EmittersEDCBody, thunkAPI) => {
  try {
    const response = await customAxios.get<EmittersEDCResponse>(
      'api/ClientBin/WaterSL-Web-WaterDomainService.svc/JSON/GetEmittersForEname',
      { params }
    );
    return response.data;
  } catch (e) {
    if (e instanceof Error) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
});

export const getComparisonEmittersEDCThunk = createAsyncThunk(
  'getComparisonEmittersEDC',
  async (params: EmittersEDCBody, thunkAPI) => {
    try {
      const response = await customAxios.get<EmittersEDCResponse>(
        'api/ClientBin/WaterSL-Web-WaterDomainService.svc/JSON/GetEmittersForEname',
        { params }
      );
      return response.data;
    } catch (e) {
      if (e instanceof Error) {
        return thunkAPI.rejectWithValue(e.message);
      }
    }
  }
);

const emittersEDCSlice = createSlice({
  name: 'emittersEDC',
  initialState,
  reducers: {
    setCurrentEmitter(state, action: PayloadAction<EmitterEDC>) {
      state.currentEmitter = action.payload;
    },
    setComparisonCurrentEmitter(state, action: PayloadAction<EmitterEDC>) {
      state.comparisonCurrentEmitter = action.payload;
    },
    resetEmittersEDC: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEmittersEDCThunk.pending.type, (state) => {
        state.loading = true;
      })
      .addCase(getEmittersEDCThunk.fulfilled.type, (state, action: PayloadAction<EmittersEDCResponse>) => {
        state.emittersEDC = action.payload.GetEmittersForEnameResult.RootResults;
        state.currentEmitter = action.payload.GetEmittersForEnameResult.RootResults[0] ?? {};
        state.loading = false;
        if (!state.emittersEDCLoadedOnce) state.emittersEDCLoadedOnce = true;
      })
      .addCase(getEmittersEDCThunk.rejected.type, (state) => {
        state.loading = false;
      })
      .addCase(getComparisonEmittersEDCThunk.pending.type, (state) => {
        state.comparisonEmitterLoading = true;
      })
      .addCase(getComparisonEmittersEDCThunk.fulfilled.type, (state, action: PayloadAction<EmittersEDCResponse>) => {
        state.comparisonEmittersEDC = action.payload.GetEmittersForEnameResult.RootResults;
        state.comparisonCurrentEmitter = action.payload.GetEmittersForEnameResult.RootResults[0] ?? {};
        state.comparisonEmitterLoading = false;
        if (!state.emittersEDCLoadedOnce) state.comparisonEmittersEDCLoadedOnce = true;
      })
      .addCase(getComparisonEmittersEDCThunk.rejected.type, (state) => {
        state.comparisonEmitterLoading = false;
      });
  },
});

export const { resetEmittersEDC, setCurrentEmitter, setComparisonCurrentEmitter } = emittersEDCSlice.actions;

export const emittersEDCReducer = emittersEDCSlice.reducer;
