import { Box, Grid, useMediaQuery } from '@mui/material';
import { FC } from 'react';
import { irrigation_emitter, laterals_bg, pressure_table } from 'shared/assets';
import { IconLabel, ResetButton, Row, ShepherdBlock } from 'shared/ui';
import { Irrigation, PressureLossTable } from './components';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { CatalogItem } from 'shared/models';
import { setProjectValue, setSystemSummaryStateValue } from 'shared/slices';
import { MainControlBlock } from 'features';
import { useTranslation } from 'react-i18next';

export const SystemSummary: FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const isLaptop = useMediaQuery('(max-width:1500px)');
  const isTablet = useMediaQuery('(max-width:850px)');

  const { projectData } = useAppSelector((state) => state.projectData);
  const { emitters } = useAppSelector((state) => state.emitters);
  const { laterals } = useAppSelector((state) => state.laterals);

  const currentProduct = projectData.integrated
    ? (laterals.find((item) => item.CATLOG === projectData.lateralCatalog) as CatalogItem)
    : (emitters.find((item) => item.CATLOG === projectData.emitterCatalog) as CatalogItem);

  const onReset = () => {
    dispatch(
      setSystemSummaryStateValue({
        avgDischarge: 0,
        avgIrrigationRate: 0,
        maxTotalIrrigationTime: 0,
        numberOfOperations: 0,
        timeOfIrrigation: 0,
        waterSourceUsage: 0,
      }),
    );
    dispatch(
      setProjectValue({
        zoneIrrigationArea: 0,
        zoneEmitterFlowRate: 0,
        zoneEmitterSpacing: 0,
        avgPeakConsumptiveUse: 0,
        sourceFlowRate: 0,
        zoneMaxIrrigationTimePerDay: 0,
        zoneLateralsPerRow: 0,
        manifoldSpacing: 0,
      }),
    );
  };

  return (
    <>
      <Box maxWidth={isLaptop ? '100%' : '1300px'} mb={3}>
        <Grid container columnSpacing={isLaptop ? 0 : 2} rowSpacing={0}>
          <Grid item xs={isLaptop ? 12 : 8.4}>
            <Row justifyContent='space-between' alignItems={isTablet ? 'center' : 'flex-start'} flexWrap='wrap'>
              <IconLabel iconSrc={irrigation_emitter}>
                {projectData.region === 'USA' ? currentProduct.AltCATLOGDESC : currentProduct.CATLOGDESC}
              </IconLabel>
              <Row justifyContent='flex-end' flexGrow={1}>
                <ResetButton onClick={onReset} />
              </Row>
            </Row>
          </Grid>
          {!isLaptop && (
            <Grid item xs={3.6}>
              <IconLabel iconSrc={pressure_table}>{t('pressureLossTable')}</IconLabel>
            </Grid>
          )}
        </Grid>

        <Grid container columnSpacing={isLaptop ? 0 : 2} rowSpacing={2}>
          <Grid item xs={isLaptop ? 12 : 8.4}>
            <ShepherdBlock id='sys-sum-input'>
              <Irrigation currentProduct={currentProduct} />
            </ShepherdBlock>
          </Grid>

          {isLaptop && (
            <Grid item xs={12}>
              <IconLabel iconSrc={pressure_table}>{t('pressureLossTable')}</IconLabel>
            </Grid>
          )}
          <Grid item xs={isLaptop ? 12 : 3.6} mt={isLaptop ? -2 : 0}>
            <ShepherdBlock id='sys-sum-pressure-table'>
              <PressureLossTable />
            </ShepherdBlock>
          </Grid>

          {!isTablet && (
            <Grid item xs={12}>
              <ShepherdBlock id='sys-sum-result-graphically'>
                <MainControlBlock bg={laterals_bg} sx={{ ml: 0 }} />
              </ShepherdBlock>
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
};
