import { Box, Grid, MenuItem, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { combined_ico, lateral_ico } from 'shared/assets';
import { useAppSelector } from 'shared/hooks';
import { AvailableInfo, IconLabel, PaperBox, ProductSelect, Select } from 'shared/ui';
import { useLaterals } from '../hooks';
import { FC } from 'react';

interface Props {
  region: string;
  standard: boolean;
  limited: boolean;
  integrated: boolean;
  handleLateralDataChange: (
    value: Partial<{
      masterGroup: number;
      group: string;
      lateral: string;
    }>,
  ) => void;
}

export const LateralBlock: FC<Props> = ({ handleLateralDataChange, limited, region, standard, integrated }) => {
  const { t } = useTranslation();
  const { masterGroups } = useAppSelector((state) => state.masterGroups);
  const isMobile = useMediaQuery('(max-width: 550px)');

  const { projectData } = useAppSelector((st) => st.projectData);

  const {
    classType,
    classTypes,
    diameter,
    diameters,
    flowPerUnit,
    flowPerUnits,
    groupTypeId,
    groupTypes,
    groupsLoading,
    lateralsLoading,
    lateral,
    lateralsFiltered,
    masterGroupId,
    nominalFlow,
    nominalFlows,
    onClassTypeChange,
    onDiameterChange,
    onFlowPerUnitChange,
    onGroupTypeChange,
    onLateralChange,
    onMasterGroupChange,
    onNominalFlowChange,
    onSpacingChange,
    spacing,
    spacings,
  } = useLaterals({ handleLateralDataChange, limited, region, standard });

  return (
    <Box mb={1}>
      <Box sx={{ mb: 1 }}>
        <IconLabel iconSrc={projectData.integrated ? lateral_ico : combined_ico}>{t('lateral')}</IconLabel>
      </Box>
      <PaperBox>
        <Grid container columnSpacing={1}>
          <Grid item xs={isMobile ? 6 : 3}>
            <Select label='Lateral Type' value={masterGroupId} onChange={onMasterGroupChange}>
              {masterGroups
                .filter((item) => item.LATERAL)
                .map((item) => (
                  <MenuItem key={item.ID} value={item.ID}>
                    {item.MASTERGROUPNAME}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
          <Grid item xs={isMobile ? 6 : 3}>
            <Select
              label='Lateral subType'
              value={groupTypeId}
              onChange={onGroupTypeChange}
              loading={groupsLoading}
              disabled={groupsLoading}
            >
              {groupTypes.map((item: any) => (
                <MenuItem key={item.GROUPS} value={item.GROUPS}>
                  {item.GROUPTYPENAME}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={isMobile ? 6 : 3}>
            <Select
              label={`${t('dia')}`}
              value={diameter}
              onChange={onDiameterChange}
              disabled={groupsLoading || lateralsLoading}
            >
              {diameters.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={isMobile ? 6 : 3}>
            <Select
              label={`${t('wall_thickness')}`}
              value={classType}
              onChange={onClassTypeChange}
              disabled={groupsLoading || lateralsLoading}
            >
              {classTypes.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
        {integrated && (
          <Grid container columnSpacing={1}>
            <Grid item xs={isMobile ? 6 : 3}>
              <Select
                label={`${t('flowPer')}`}
                value={flowPerUnit}
                onChange={onFlowPerUnitChange}
                disabled={groupsLoading || lateralsLoading}
              >
                {flowPerUnits.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={isMobile ? 6 : 3}>
              <Select
                label={`${t('nominalFlow')}`}
                value={nominalFlow}
                onChange={onNominalFlowChange}
                disabled={groupsLoading || lateralsLoading}
              >
                {nominalFlows.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={isMobile ? 6 : 3}>
              <Select
                label={`${t('spacing')}`}
                value={spacing}
                onChange={onSpacingChange}
                disabled={groupsLoading || lateralsLoading}
              >
                {spacings.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        )}
        <Grid container columnSpacing={1} mt={1}>
          <Grid item xs={isMobile ? 12 : 9}>
            <ProductSelect
              value={lateral}
              onChange={onLateralChange}
              options={lateralsFiltered}
              loading={lateralsLoading}
              disabled={lateralsLoading || groupsLoading}
              error={!lateral}
            />
          </Grid>
          <Grid item xs={isMobile ? 12 : 3} mt={1}>
            <AvailableInfo amount={lateralsFiltered.length} />
          </Grid>
        </Grid>
      </PaperBox>
    </Box>
  );
};
