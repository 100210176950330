import { Box, Button, Checkbox, FormControlLabel, FormGroup, MenuItem, SelectChangeEvent } from '@mui/material';
import {
  getEmittersThunk,
  getLateralsThunk,
  getMainlinesThunk,
  getSubmainsThunk,
  setProjectValue,
  toggleRegions,
} from 'shared/slices';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { REGIONS } from 'shared/constants';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { Modal, Select } from 'shared/ui';

export const Regions = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { openRegions } = useAppSelector((st) => st.modals);
  const { projectData } = useAppSelector((state) => state.projectData);

  const [region, setRegion] = useState(projectData.region);

  const [checkValues, setCheckValues] = useState({ standard: projectData.standard, limited: projectData.limited });

  const handleRegionChange = (e: SelectChangeEvent<unknown>) => {
    setRegion(e.target.value as string);
  };

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCheckValues((prev) => ({ ...prev, [e.target.name]: e.target.checked }));
  };

  const toggle = () => dispatch(toggleRegions());

  const onSaveClick = () => {
    const { lateralGroup, emitterGroup, submainGroup, mainlineGroup } = projectData;

    dispatch(setProjectValue({ region: region, limited: checkValues.limited, standard: checkValues.standard }));
    dispatch(
      getLateralsThunk({
        group: lateralGroup,
        region: region,
        standard: checkValues.standard,
        limited: checkValues.limited,
      }),
    );
    dispatch(
      getEmittersThunk({
        group: emitterGroup,
        region: region,
        standard: checkValues.standard,
        limited: checkValues.limited,
      }),
    );
    dispatch(
      getSubmainsThunk({
        group: submainGroup,
        region: region,
        standard: checkValues.standard,
        limited: checkValues.limited,
      }),
    );
    dispatch(
      getMainlinesThunk({
        group: mainlineGroup,
        region: region,
        standard: checkValues.standard,
        limited: checkValues.limited,
      }),
    );
    toggle();
  };

  return (
    <Modal maxWidth='xs' title={t('regions')} open={openRegions} onClose={toggle}>
      <Box>
        <Box display='flex' columnGap={1} alignItems='flex-end' mb={2} flexWrap='wrap'>
          <Box flexGrow={1}>
            <Select value={region} onChange={handleRegionChange} label={`${t('region')}`}>
              {REGIONS.sort((a, b) => a.label.localeCompare(b.label)).map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    size='small'
                    name='standard'
                    value={checkValues.standard}
                    checked={checkValues.standard}
                    onChange={handleCheckboxChange}
                  />
                }
                label={t('standard')}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    size='small'
                    name='limited'
                    value={checkValues.limited}
                    checked={checkValues.limited}
                    onChange={handleCheckboxChange}
                  />
                }
                label={t('limited')}
              />
            </FormGroup>
          </Box>
        </Box>

        <Box display='flex' columnGap={1}>
          <Button onClick={onSaveClick} variant='contained' sx={{ flexBasis: '50%' }}>
            {t('save')}
          </Button>

          <Button onClick={toggle} variant='outlined' sx={{ flexBasis: '50%' }}>
            {t('cancel')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
