import { Box, Grid, MenuItem, useMediaQuery } from '@mui/material';
import { EDCProductNameSelect } from 'features/EDCProductNameSelect';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { select_product } from 'shared/assets';
import { useAppDispatch, useAppSelector, useEDCFilters } from 'shared/hooks';
import { toggleEDCGraph, toggleEDCTables } from 'shared/slices';
import { Accordion, Button, IconLabel, PaperBox, ProductEDCSelect, Select, ShepherdBlock } from 'shared/ui';

const SelectProductContent: FC = () => {
  const isTablet = useMediaQuery('(max-width:850px)');
  const { t } = useTranslation();

  const { loading } = useAppSelector((st) => st.emittersEDC);

  const {
    emitterFamilies,
    currentEmitterFamily,
    onEmitterFamilyChange,
    orients,
    orientValue,
    onOrientChange,
    spinners,
    spinnerValue,
    onSpinnerChange,
    nozzles,
    nozzleValue,
    onNozzleChange,
    pressures,
    pressureValue,
    onPressureChange,
    risers,
    riserValue,
    onRiserChange,
    flowRates,
    flowRateValue,
    onFlowRateChange,
    ranges,
    rangeValue,
    onRangeChange,
    emittersEDCFiltered,
    emitterEDCProduct,
    onEmitterEDCChange,
  } = useEDCFilters();

  return (
    <Grid item container xs={12} columnSpacing={isTablet ? 1 : 3} rowSpacing={1}>
      <Grid item xs={isTablet ? 6 : 3}>
        <EDCProductNameSelect
          value={currentEmitterFamily}
          onChange={onEmitterFamilyChange}
          emitterFamilies={emitterFamilies}
          loading={loading}
        />
      </Grid>
      <Grid item xs={isTablet ? 6 : 3}>
        <Select label={`${t('orientation')}`} value={orientValue} onChange={onOrientChange} disabled={loading}>
          {orients.map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </Grid>

      <Grid item xs={isTablet ? 4 : 3}>
        <Select label={`${t('spinner')}`} value={spinnerValue} onChange={onSpinnerChange} disabled={loading}>
          {spinners.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={isTablet ? 4 : 3}>
        <Select label={`${t('nozzle')}`} value={nozzleValue} onChange={onNozzleChange} disabled={loading}>
          {nozzles.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={isTablet ? 4 : 3}>
        <Select label={`${t('pressure')} (bar)`} value={pressureValue} onChange={onPressureChange} disabled={loading}>
          {pressures.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={isTablet ? 4 : 3}>
        <Select label={`${t('riser')} (cm)`} value={riserValue} onChange={onRiserChange} disabled={loading}>
          {risers.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={isTablet ? 4 : 3}>
        <Select label={`${t('flowRate')} (l/h)`} value={flowRateValue} onChange={onFlowRateChange} disabled={loading}>
          {flowRates.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={isTablet ? 4 : 3}>
        <Select label={`${t('range')} (m)`} value={rangeValue} onChange={onRangeChange} disabled={loading}>
          {ranges.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </Grid>

      <Grid item xs={12}>
        {!!emitterEDCProduct && (
          <ProductEDCSelect
            label={`${t('emitter')}`}
            value={emitterEDCProduct}
            onChange={onEmitterEDCChange}
            options={emittersEDCFiltered}
            loading={loading}
            disabled={loading}
          />
        )}
      </Grid>
    </Grid>
  );
};

const SelectProductButtons: FC = () => {
  const isMobile = useMediaQuery('(max-width:550px)');
  const isTablet = useMediaQuery('(max-width:850px)');
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const openEDCGraphModal = () => dispatch(toggleEDCGraph());
  const openEDCTablesModal = () => dispatch(toggleEDCTables());

  return (
    <Grid item container xs={12} columnSpacing={2} mt={2}>
      <Grid item xs={isMobile ? 12 : isTablet ? 6 : 2.6} ml={isTablet ? 0 : 1} mb={isMobile ? 1 : 0}>
        <Button onClick={openEDCGraphModal}>{t('showGraph')}</Button>
      </Grid>
      <Grid item xs={isMobile ? 12 : isTablet ? 6 : 2.6}>
        <Button onClick={openEDCTablesModal}>{t('tables')}</Button>
      </Grid>
    </Grid>
  );
};

export const SelectProductBlock: FC = () => {
  const isTablet = useMediaQuery('(max-width:850px)');
  const { t } = useTranslation();

  return (
    <Box width="100%">
      {isTablet ? (
        <Accordion header={<IconLabel iconSrc={select_product}>{t('selectProduct')}</IconLabel>}>
          <>
            <SelectProductContent />
            <SelectProductButtons />
          </>
        </Accordion>
      ) : (
        <Box>
          <PaperBox>
            <SelectProductContent />
          </PaperBox>
          <ShepherdBlock id="edc-buttons">
            <SelectProductButtons />
          </ShepherdBlock>
        </Box>
      )}
    </Box>
  );
};
