import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EDCCalcResult, SquareSC } from 'shared/lib/calculation/models';

type EDCTablesGraph = {
  item: string;
  values: {
    step: number;
    value: number;
  }[];
};

type EDCTablesResult = Table[];

interface Table {
  step: number;
  cu?: number[];
  du?: number[];
  avg?: number[];
  min?: number[];
}

type EDCCategoryFilter = {
  sprinkler: boolean;
  microSprinkler: boolean;
  fogger: boolean;
};

type EDCValues = {
  emitterSpacing: number | string;
  lateralSpacing: number | string;
  bedWidth: number | string;
  edgeInRow: number | string;
  edgeOffRow: number | string;
  sprinklersPerHa: number | string;
  minimum: number | string;
  maximum: number | string;
  average: number | string;
  du: number | string;
  cu: number | string;
  sc: number | string;
  wettest: number | string;
  placement: number;
  edge: number;
  lateral: number;
  orient: string;
  spinner: string;
  nozzle: string;
  pressure: number | string;
  riser: number | string;
  flowRate: number | string;
  range: number | string;
  max1: number | string;
  max2: number | string;
  min1: number | string;
  min2: number | string;
  step1: number | string;
  step2: number | string;
  tablesCu: boolean;
  tablesDu: boolean;
  tablesAvg: boolean;
  tablesMin: boolean;
  mouseClickPositionY1: number;
  mouseClickPositionY2: number;
  scSquare: SquareSC | null;
  wettestSquare: SquareSC | null;
  scPercent: number;
  wettestPercent: number;
  singleGraphMode: boolean;
  comparisonModeEmitter: {
    orient: string;
    spinner: string;
    nozzle: string;
    pressure: number | string;
    riser: number | string;
    flowRate: number | string;
    range: number | string;
  };
};
type EDCErrors = {
  minimum: boolean;
  maximum: boolean;
  average: boolean;
  du: boolean;
  cu: boolean;
  sc: boolean;
  wettest: boolean;
};
type EDCToolBar = {
  activeGraph: '3D' | '2D';
  showNumbers: boolean;
  showRange: boolean;
  showPlants: boolean;
  showSectionGraph: boolean;
  showGrowth: boolean;
  showZoom: boolean;
  showTimer: boolean;
  displayPlants: boolean;
};
type EDCPlantsValues = {
  betweenPlants: number;
  betweenRows: number;
  plantDiameter: number;
  staggeredRows: boolean;
  inRows: number;
  offRows: number;
  centerPlantsBetweenLaterals: boolean;
};

interface CalculationsState {
  calcResult: EDCCalcResult;
  edcValues: EDCValues;
  edcErrors: EDCErrors;
  edcToolBar: EDCToolBar;
  edcPlantsValues: EDCPlantsValues;
  tablesGraph: EDCTablesGraph;
  tablesResult: EDCTablesResult;
  tablesActiveData: string;
  categoryFilter: EDCCategoryFilter;
}

const initialState: CalculationsState = {
  calcResult: {} as EDCCalcResult,
  edcErrors: {
    minimum: false,
    maximum: false,
    average: false,
    du: false,
    cu: false,
    sc: false,
    wettest: false,
  },
  edcValues: {
    comparisonModeEmitter: {
      orient: 'both',
      spinner: '',
      nozzle: '',
      pressure: '',
      riser: '',
      flowRate: '',
      range: '',
    },
    emitterSpacing: '0',
    lateralSpacing: '0',
    bedWidth: '0',
    edgeInRow: '0',
    edgeOffRow: '0',
    sprinklersPerHa: '0',
    minimum: '0',
    maximum: '0',
    average: '0',
    du: '0',
    cu: '0',
    sc: '0',
    wettest: '0',
    placement: 0,
    edge: 0,
    lateral: 3,
    orient: 'both',
    spinner: '',
    nozzle: '',
    pressure: '',
    riser: '',
    flowRate: '',
    range: '',
    max1: 2,
    max2: 2,
    min1: 0.4,
    min2: 0.4,
    step1: 0.4,
    step2: 0.4,
    tablesCu: true,
    tablesDu: true,
    tablesAvg: true,
    tablesMin: true,
    mouseClickPositionY1: 0,
    mouseClickPositionY2: 0,
    scSquare: null,
    wettestSquare: null,
    scPercent: 10,
    wettestPercent: 10,
    singleGraphMode: true,
  },
  edcToolBar: {
    activeGraph: '2D',
    showNumbers: false,
    showRange: false,
    showPlants: false,
    showSectionGraph: false,
    showGrowth: true,
    showZoom: false,
    showTimer: false,
    displayPlants: false,
  },
  edcPlantsValues: {
    betweenPlants: 0,
    betweenRows: 0,
    plantDiameter: 0,
    staggeredRows: true,
    inRows: 0,
    offRows: 0,
    centerPlantsBetweenLaterals: false,
  },
  tablesGraph: {} as EDCTablesGraph,
  tablesResult: [],
  tablesActiveData: '',
  categoryFilter: {
    sprinkler: true,
    fogger: true,
    microSprinkler: true,
  },
};

export const edcStateSlice = createSlice({
  name: 'edcState',
  initialState,
  reducers: {
    setEDCCalcResult(state, action: PayloadAction<EDCCalcResult>) {
      state.calcResult = action.payload;
    },
    setEDCStateValue: (state, action: PayloadAction<Partial<EDCValues>>) => {
      state.edcValues = { ...state.edcValues, ...action.payload };
    },
    setEDCStateError: (state, action: PayloadAction<Partial<EDCErrors>>) => {
      state.edcErrors = { ...state.edcErrors, ...action.payload };
    },
    setEDCStateToolBar: (state, action: PayloadAction<Partial<EDCToolBar>>) => {
      state.edcToolBar = { ...state.edcToolBar, ...action.payload };
    },
    setEDCStatePlantsValues: (state, action: PayloadAction<Partial<EDCPlantsValues>>) => {
      state.edcPlantsValues = { ...state.edcPlantsValues, ...action.payload };
    },
    setEDCTablesGraph: (state, action: PayloadAction<EDCTablesGraph>) => {
      state.tablesGraph = action.payload;
    },
    setEDCTablesResult: (state, action: PayloadAction<EDCTablesResult>) => {
      state.tablesResult = action.payload;
    },
    setEDCTablesActiveData: (state, action: PayloadAction<string>) => {
      state.tablesActiveData = action.payload;
    },
    setEDCCategoryFilter: (state, action: PayloadAction<Partial<EDCCategoryFilter>>) => {
      state.categoryFilter = { ...state.categoryFilter, ...action.payload };
    },
    setEDCStateComparisonEmitterValue: (state, action: PayloadAction<Partial<EDCValues['comparisonModeEmitter']>>) => {
      state.edcValues.comparisonModeEmitter = { ...state.edcValues.comparisonModeEmitter, ...action.payload };
    },
  },
});

export const {
  setEDCCalcResult,
  setEDCStateValue,
  setEDCTablesResult,
  setEDCTablesActiveData,
  setEDCStateError,
  setEDCTablesGraph,
  setEDCStateToolBar,
  setEDCStatePlantsValues,
  setEDCStateComparisonEmitterValue,
  setEDCCategoryFilter,
} = edcStateSlice.actions;

export const edcStateReducer = edcStateSlice.reducer;
