import {
  Autocomplete,
  Box,
  CircularProgress,
  createFilterOptions,
  TextField,
  Tooltip,
  Stack,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { formatEDCProductName } from 'shared/lib';
import { EmitterEDC } from 'shared/models';

interface Props {
  onChange: (e: any, item: EmitterEDC) => void;
  value: EmitterEDC;
  options: EmitterEDC[];
  loading?: boolean;
  disabled?: boolean;
  label?: string;
  tooltip?: string;
  count?: number;
}

export const ProductEDCSelect: FC<Props> = ({ onChange, options, value, disabled, loading, tooltip, label, count }) => {
  return (
    <Box sx={{ width: '100%' }}>
      {label && (
        <Box display="flex">
          <Tooltip title={tooltip ?? ''} placement="top">
            <Typography
              noWrap
              sx={{
                ml: count ? '20px' : 0,
                fontSize: '13px',
                // textTransform: 'uppercase',
                color: 'secondary.main',
                marginBottom: '3px',
              }}
            >
              {label}
            </Typography>
          </Tooltip>
        </Box>
      )}
      <Stack direction="row" alignItems="center">
        {count && (
          <Typography
            sx={{
              fontSize: '14px',
              textTransform: 'uppercase',
              color: '#4D4E4C',
              fontWeight: 500,
              mr: 1,
            }}
          >
            {`${count}:`}
          </Typography>
        )}
        <Autocomplete
          disableClearable
          disabled={disabled}
          onChange={onChange}
          value={value}
          options={options}
          getOptionLabel={formatEDCProductName}
          isOptionEqualToValue={(option, value) => option.recnumber === value.recnumber}
          sx={{
            flexGrow: 1,
            background: '#FFF',
            '.MuiOutlinedInput-root': {
              height: 34,
              fontSize: 14,
              borderRadius: '9px',
              p: '0 8px',
            },
            button: {
              p: 0,
              '&: hover': {
                bgcolor: 'transparent',
              },
            },
          }}
          filterOptions={createFilterOptions({ matchFrom: 'any' })}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </Stack>
    </Box>
  );
};
