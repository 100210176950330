import { Grid, MenuItem, Typography, useMediaQuery } from '@mui/material';
import { EDCComparisonEmitter } from 'features/EDCComparisonEmitter';
import { useTranslation } from 'react-i18next';
import { ResponsiveContainer, LineChart, XAxis, YAxis, CartesianGrid, Legend, Line, Tooltip } from 'recharts';
import { useAppDispatch, useAppSelector, useEDCFilters } from 'shared/hooks';
import { formatEDCProductName, getEmitterTableValues } from 'shared/lib';
import { setEDCStateValue, toggleEDCGraph } from 'shared/slices';
import { Modal, PaperBox, ProductEDCSelect, RadioSwitcher, Select } from 'shared/ui';

const renderColorfulLegendText = (value: string) => <Typography component="span">{value}</Typography>;

export const EDCGraph = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isTablet = useMediaQuery('(max-width:850px)');
  const isMobile = useMediaQuery('(max-width:550px)');
  const { openEDCGraph } = useAppSelector((st) => st.modals);
  const { loading, currentEmitter, comparisonCurrentEmitter } = useAppSelector((st) => st.emittersEDC);
  const {
    edcValues: { singleGraphMode },
  } = useAppSelector((st) => st.edcState);

  const integratedValues = [t('singleGraph'), t('comparisonMode')];

  const {
    emitterFamilies,
    currentEmitterFamily,
    onEmitterFamilyChange,
    orientValue,
    orients,
    onOrientChange,
    spinners,
    spinnerValue,
    onSpinnerChange,
    nozzles,
    nozzleValue,
    onNozzleChange,
    pressures,
    pressureValue,
    onPressureChange,
    risers,
    riserValue,
    onRiserChange,
    flowRates,
    flowRateValue,
    onFlowRateChange,
    ranges,
    rangeValue,
    onRangeChange,
    emittersEDCFiltered,
    emitterEDCProduct,
    onEmitterEDCChange,
  } = useEDCFilters();

  const toggle = () => dispatch(toggleEDCGraph());

  const emitterTableValues = getEmitterTableValues(currentEmitter);
  const comparisonEmitterTableValues = getEmitterTableValues(comparisonCurrentEmitter);

  const handleToggleIntegrated = () => {
    dispatch(setEDCStateValue({ singleGraphMode: !singleGraphMode }));
  };

  const chartData = singleGraphMode
    ? emitterTableValues.filter((item) => item.emission !== 0)
    : emitterTableValues
        .filter((item) => item.emission !== 0)
        .map((item, idx) => ({
          emission1: item.emission,
          emission2: comparisonEmitterTableValues[idx].emission,
        }));

  return (
    <Modal maxWidth="lg" title={t('emitterGraph')} open={openEDCGraph} onClose={toggle}>
      <Grid mb={2}>
        <RadioSwitcher values={integratedValues} trueFlag={singleGraphMode} onChange={handleToggleIntegrated} />
        <PaperBox mb={2}>
          <Grid item container xs={12} columnSpacing={isMobile ? 1 : 3} rowSpacing={1}>
            <Grid item xs={isTablet ? 6 : 3}>
              <Select
                label={`${t('name')}`}
                value={currentEmitterFamily}
                // onChange={onEmitterFamilyChange}
                loading={loading}
                disabled={loading}
              >
                {emitterFamilies.map((item) => (
                  <MenuItem onChange={() => onEmitterFamilyChange(item)} key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={isTablet ? 6 : 3}>
              <Select label={`${t('orientation')}`} value={orientValue} onChange={onOrientChange} disabled={loading}>
                {orients.map(({ value, label }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={isTablet ? 4 : 3}>
              <Select label={`${t('spinner')}`} value={spinnerValue} onChange={onSpinnerChange} disabled={loading}>
                {spinners.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={isTablet ? 4 : 3}>
              <Select label={`${t('nozzle')}`} value={nozzleValue} onChange={onNozzleChange} disabled={loading}>
                {nozzles.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={isTablet ? 4 : 3}>
              <Select label={`${t('pressure')}`} value={pressureValue} onChange={onPressureChange} disabled={loading}>
                {pressures.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={isTablet ? 4 : 3}>
              <Select label={`${t('riser')}`} value={riserValue} onChange={onRiserChange} disabled={loading}>
                {risers.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={isTablet ? 4 : 3}>
              <Select label={`${t('flowRate')}`} value={flowRateValue} onChange={onFlowRateChange} disabled={loading}>
                {flowRates.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={isTablet ? 4 : 3}>
              <Select label={`${t('range')}`} value={rangeValue} onChange={onRangeChange} disabled={loading}>
                {ranges.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid item xs={12}>
              {!!emitterEDCProduct && (
                <ProductEDCSelect
                  label={`${t('emitter')}`}
                  value={emitterEDCProduct}
                  onChange={onEmitterEDCChange}
                  options={emittersEDCFiltered}
                  loading={loading}
                  disabled={loading}
                />
              )}
            </Grid>
          </Grid>
        </PaperBox>
        {!singleGraphMode && <EDCComparisonEmitter />}
      </Grid>
      {singleGraphMode && (
        <Grid mb={2}>
          <Grid container rowGap={1} columnSpacing={0.1} justifyContent={{ md: 'space-between' }}>
            {emitterTableValues.map((item) => (
              <Grid item md={0.9} sm={2} xs={3} key={item.distance}>
                <Grid item xs={12} sx={{ bgcolor: 'primary.main' }}>
                  <Typography color="#fff" align="center">
                    {item.distance}
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ bgcolor: 'lightgray' }}>
                  <Typography align="center">{item.emission}</Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
      <Grid>
        <ResponsiveContainer width="100%" height={singleGraphMode ? 200 : 300}>
          <LineChart
            style={{ overflow: 'hidden' }}
            data={chartData}
            margin={{
              top: 5,
              left: -20,
              right: 5,
            }}
          >
            <XAxis stroke="black" dataKey="distance" />
            <YAxis stroke="black" />
            <Tooltip />
            <CartesianGrid strokeDasharray="3 3" />
            <Legend formatter={renderColorfulLegendText} iconSize={25} />
            <Line
              name={formatEDCProductName(currentEmitter)}
              dot={{ stroke: 'black', strokeWidth: 2 }}
              type="monotone"
              dataKey={singleGraphMode ? 'emission' : 'emission1'}
              stroke="#245e2b"
              strokeWidth={3}
              activeDot={{ r: 5 }}
            />
            {!singleGraphMode && (
              <Line
                name={formatEDCProductName(comparisonCurrentEmitter)}
                dot={{ stroke: 'black', strokeWidth: 2 }}
                type="monotone"
                dataKey={'emission2'}
                stroke="#55aaff"
                strokeWidth={3}
                activeDot={{ r: 5 }}
              />
            )}
          </LineChart>
        </ResponsiveContainer>
      </Grid>
    </Modal>
  );
};
