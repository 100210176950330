import { Box, Grid, useMediaQuery } from '@mui/material';
import { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector, useToast } from 'shared/hooks';
import { formattedInputValue, mapSlopesForCalculation, scrollToResults } from 'shared/lib';
import { ManifoldCalcResult } from 'shared/lib/calculation/models';
import { manifoldCalculate } from 'shared/lib/calculation/ManifoldDesign/ManifoldCalculate';
import { CatalogItem } from 'shared/models';
import { setMainlineStateValue, setProjectValue, setSubmainErrorValue, setSubmainStateValue } from 'shared/slices';
import { Accordion, Button, IconLabel, Input, PaperBox, ProductSelect, ShepherdBlock } from 'shared/ui';
import { ValidationError } from 'features';
import { pipe_dia } from 'shared/assets';

interface Props {
  submainPipe1: CatalogItem;
  submainPipe2: CatalogItem;
  submainPipe3: CatalogItem;
  submainsFiltered: CatalogItem[];
  submainsLoading: boolean;
  submainGroupsLoading: boolean;
  onSubmain1Change: (e: any, item: CatalogItem) => void;
  onSubmain2Change: (e: any, item: CatalogItem) => void;
  onSubmain3Change: (e: any, item: CatalogItem) => void;
}

const SimulationContent: FC<Props> = ({
                                        onSubmain1Change,
                                        onSubmain2Change,
                                        onSubmain3Change,
                                        submainGroupsLoading,
                                        submainPipe1,
                                        submainPipe2,
                                        submainPipe3,
                                        submainsFiltered,
                                        submainsLoading,
                                      }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { units } = useAppSelector((state) => state.units);
  const { projectData } = useAppSelector((st) => st.projectData);
  const { submainValues, submainErrors } = useAppSelector((st) => st.submainState);
  const isTablet = useMediaQuery('(max-width:850px)');

  return (
    <Box>
      {(submainErrors.length1 || submainErrors.length2 || submainErrors.length3) && (
        <ValidationError content={t('msgInvalidManifoldLengths')} />
      )}
      <Grid item container xs={12} columnSpacing={isTablet ? 1 : 3} rowSpacing={1} pl={isTablet ? 2 : 0}>
        <Grid item xs={isTablet ? 14 : 7} ml={isTablet ? -2 : 0}>
          <ProductSelect
            label={`${t('pipe1FromValve')}`}
            count={1}
            value={submainPipe1}
            onChange={onSubmain1Change}
            options={submainsFiltered}
            loading={submainsLoading}
            disabled={submainsLoading || submainGroupsLoading}
            error={!submainPipe1}
          />
        </Grid>

        <Grid item xs={isTablet ? 4 : 1.66}>
          <Input
            label={`V (${units.velocity})`}
            tooltip={`${t('velocity')} (${units.velocity})`}
            isResultBox
            value={submainValues.velocity1}
            isErrorBox={submainErrors.velocity1}
          />
        </Grid>

        <Grid item xs={isTablet ? 4 : 1.66}>
          <Input
            label={`I.D (${units.pipeDiameter})`}
            tooltip={`${t('internalDia')} (${units.pipeDiameter})`}
            isNotifyBox={projectData.manifoldPipe1Dia === submainPipe1?.INTRNL}
            value={projectData.manifoldPipe1Dia}
            onChange={(e) =>
              dispatch(setProjectValue({ manifoldPipe1Dia: formattedInputValue(e as ChangeEvent<HTMLInputElement>) }))
            }
          />
        </Grid>
        <Grid item xs={isTablet ? 4 : 1.66}>
          <Input
            label={`L (${units.length})`}
            tooltip={`${t('length')} (${units.length})`}
            isResultBox={submainValues.isCalcLength}
            isErrorBox={submainErrors.length1}
            value={projectData.manifoldPipe1Length}
            onChange={(e) =>
              dispatch(
                setProjectValue({ manifoldPipe1Length: formattedInputValue(e as ChangeEvent<HTMLInputElement>) }),
              )
            }
          />
        </Grid>
      </Grid>
      <Grid item container xs={12} columnSpacing={isTablet ? 1 : 3} mt={0.5} rowSpacing={1} pl={isTablet ? 2 : 0}>
        <Grid item xs={isTablet ? 14 : 7} ml={isTablet ? -2 : 0}>
          <ProductSelect
            label={`${t('pipe2From1')}`}
            count={2}
            value={submainPipe2}
            onChange={onSubmain2Change}
            options={submainsFiltered}
            loading={submainsLoading}
            disabled={submainsLoading || submainGroupsLoading}
            error={!submainPipe2}
          />
        </Grid>

        <Grid item xs={isTablet ? 4 : 1.66}>
          <Input
            label={`V (${units.velocity})`}
            tooltip={`${t('velocity')} (${units.velocity})`}
            isResultBox
            value={submainValues.velocity2}
            isErrorBox={submainErrors.velocity2}
          />
        </Grid>

        <Grid item xs={isTablet ? 4 : 1.66}>
          <Input
            label={`I.D (${units.pipeDiameter})`}
            tooltip={`${t('internalDia')} (${units.pipeDiameter})`}
            isNotifyBox={projectData.manifoldPipe2Dia === submainPipe2?.INTRNL}
            value={projectData.manifoldPipe2Dia}
            onChange={(e) =>
              dispatch(setProjectValue({ manifoldPipe2Dia: formattedInputValue(e as ChangeEvent<HTMLInputElement>) }))
            }
          />
        </Grid>
        <Grid item xs={isTablet ? 4 : 1.66}>
          <Input
            label={`L (${units.length})`}
            tooltip={`${t('length')} (${units.length})`}
            isResultBox={submainValues.isCalcLength}
            isErrorBox={submainErrors.length2}
            value={projectData.manifoldPipe2Length}
            onChange={(e) =>
              dispatch(
                setProjectValue({ manifoldPipe2Length: formattedInputValue(e as ChangeEvent<HTMLInputElement>) }),
              )
            }
          />
        </Grid>
      </Grid>
      <Grid item container xs={12} columnSpacing={isTablet ? 1 : 3} mt={0.5} rowSpacing={1} pl={isTablet ? 2 : 0}>
        <Grid item xs={isTablet ? 14 : 7} ml={isTablet ? -2 : 0}>
          <ProductSelect
            label={`${t('pipe3From2')}`}
            count={3}
            value={submainPipe3}
            onChange={onSubmain3Change}
            options={submainsFiltered}
            loading={submainsLoading}
            disabled={submainsLoading || submainGroupsLoading}
            error={!submainPipe3}
          />
        </Grid>

        <Grid item xs={isTablet ? 4 : 1.66}>
          <Input
            label={`V (${units.velocity})`}
            tooltip={`${t('velocity')} (${units.velocity})`}
            isResultBox
            value={submainValues.velocity3}
            isErrorBox={submainErrors.velocity3}
          />
        </Grid>

        <Grid item xs={isTablet ? 4 : 1.66}>
          <Input
            label={`I.D (${units.pipeDiameter})`}
            tooltip={`${t('internalDia')} (${units.pipeDiameter})`}
            isNotifyBox={projectData.manifoldPipe3Dia === submainPipe3?.INTRNL}
            value={projectData.manifoldPipe3Dia}
            onChange={(e) =>
              dispatch(setProjectValue({ manifoldPipe3Dia: formattedInputValue(e as ChangeEvent<HTMLInputElement>) }))
            }
          />
        </Grid>
        <Grid item xs={isTablet ? 4 : 1.66}>
          <Input
            label={`L (${units.length})`}
            tooltip={`${t('length')} (${units.length})`}
            isErrorBox={submainErrors.length3}
            isResultBox={submainValues.isCalcLength}
            value={projectData.manifoldPipe3Length}
            onChange={(e) =>
              dispatch(
                setProjectValue({ manifoldPipe3Length: formattedInputValue(e as ChangeEvent<HTMLInputElement>) }),
              )
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
};

interface CalcButtonProps {
  submainsFiltered: CatalogItem[];
}

const CalcButton: FC<CalcButtonProps> = ({ submainsFiltered }) => {
  const dispatch = useAppDispatch();
  const { showError } = useToast();
  const { t } = useTranslation();
  const { projectData } = useAppSelector((st) => st.projectData);
  const { submainValues } = useAppSelector((st) => st.submainState);
  const { units } = useAppSelector((state) => state.units);
  const isMobile = useMediaQuery('(max-width:550px)');
  const isTablet = useMediaQuery('(max-width:850px)');

  const onCalculate = () => {
    isMobile && scrollToResults();

    const currentPipe1 = submainsFiltered.find((item) => item.CATLOG === projectData.manifoldPipe1) as CatalogItem;
    const currentPipe2 = submainsFiltered.find((item) => item.CATLOG === projectData.manifoldPipe2) as CatalogItem;
    const currentPipe3 = submainsFiltered.find((item) => item.CATLOG === projectData.manifoldPipe3) as CatalogItem;

    const values = {
      Flow1: +projectData.flow1,
      Flow2: +projectData.flow2,
      ManifoldSpacing: +projectData.manifoldSpacing,
      NumberOfRows: +projectData.totalRows,
      InternalDiameter1: +projectData.manifoldPipe1Dia,
      InternalDiameter2: +projectData.manifoldPipe2Dia,
      InternalDiameter3: +projectData.manifoldPipe3Dia,
      ManifoldHWCoef: +projectData.manifoldHWCoef,
      ManifoldPipe1: +currentPipe1.HWCOF,
      ManifoldPipe2: +currentPipe2.HWCOF,
      ManifoldPipe3: +currentPipe3.HWCOF,
      Pipe1Length: +projectData.manifoldPipe1Length,
      Pipe2Length: +projectData.manifoldPipe2Length,
      Pipe3Length: +projectData.manifoldPipe3Length,
      TotalSubmainLength: +projectData.totalManifoldLength,
      DistanceTo1stLateral: +projectData.firstLateralDistance,
      MultiSlopesManifold: mapSlopesForCalculation(projectData.manifoldSlopes),
      ManifoldMaxVelocity: +projectData.manifoldMaximumVelocity,
      ValvePressure: projectData.valvePressure,
      ValveHeadloss: projectData.manifoldHeadloss,
      LateralInletPressure: +projectData.lateralInletPressure,
      ManifoldAllowedHL: +submainValues.allowedHeadloss,
      ManifoldHeadlossCalculation: projectData.manifoldHeadlossCalc,
    };

    console.log('####: values', values);

    const result = manifoldCalculate(
      values,
      units,
      projectData.isBothSides,
      submainValues.isCalcLength,
      true,
    ) as ManifoldCalcResult;

    console.log('####: result', result);

    dispatch(setSubmainErrorValue({ length1: false, length2: false, length3: false }));

    if (result.error) {
      if (result.error === 'msgInvalidManifoldLengths') {
        dispatch(setSubmainErrorValue({ length1: true, length2: true, length3: true }));
        return;
      }

      showError(t(`${result.error}`));
      return;
    }

    dispatch(
      setProjectValue({
        manifoldPipe1Length: result.length1,
        manifoldPipe2Length: result.length2,
        manifoldPipe3Length: result.length3,
      }),
    );

    dispatch(setMainlineStateValue({ flow: +projectData.totalFlow }));

    dispatch(
      setSubmainStateValue({
        valvePressure: result.ValvePressure,
        totalHeadloss: result.SubmainAllowableHeadloss,
        firstLateral: result.FirstLateralPressure,
        lastLateral: result.LastLateralPressure,
        velocity1: result.velocity1,
        velocity2: result.velocity2,
        velocity3: result.velocity3,
        resultReportArray: result.reportResult?.reportResults,
      }),
    );

    if (result.velocity1 && result.velocity1 > +projectData.manifoldMaximumVelocity) {
      dispatch(setSubmainErrorValue({ velocity1: true }));
    } else {
      dispatch(setSubmainErrorValue({ velocity1: false }));
    }
    if (result.velocity2 && result.velocity2 > +projectData.manifoldMaximumVelocity) {
      dispatch(setSubmainErrorValue({ velocity2: true }));
    } else {
      dispatch(setSubmainErrorValue({ velocity2: false }));
    }
    if (result.velocity3 && result.velocity3 > +projectData.manifoldMaximumVelocity) {
      dispatch(setSubmainErrorValue({ velocity3: true }));
    } else {
      dispatch(setSubmainErrorValue({ velocity3: false }));
    }
  };

  return (
    <Grid item container xs={12} columnSpacing={3} mt={1}>
      <Grid item xs={isTablet ? 8 : 9}></Grid>
      <Grid item xs={isMobile ? 12 : isTablet ? 4 : 2.8}>
        <ShepherdBlock id='submain-calc-button'>
          <Button iconType='calculation' onClick={onCalculate}>
            {t('calculate')}
          </Button>
        </ShepherdBlock>
      </Grid>
    </Grid>
  );
};

export const SimulationBlock: FC<Props> = ({
                                             onSubmain1Change,
                                             onSubmain2Change,
                                             onSubmain3Change,
                                             submainGroupsLoading,
                                             submainPipe1,
                                             submainPipe2,
                                             submainPipe3,
                                             submainsFiltered,
                                             submainsLoading,
                                           }) => {
  const isTablet = useMediaQuery('(max-width:850px)');
  const { t } = useTranslation();

  return (
    <Box width='100%'>
      {isTablet ? (
        <Accordion defaultExpanded header={<IconLabel iconSrc={pipe_dia}>{t('pipeDiameters')}</IconLabel>}>
          <>
            <SimulationContent
              onSubmain1Change={onSubmain1Change}
              onSubmain2Change={onSubmain2Change}
              onSubmain3Change={onSubmain3Change}
              submainGroupsLoading={submainGroupsLoading}
              submainPipe1={submainPipe1}
              submainPipe2={submainPipe2}
              submainPipe3={submainPipe3}
              submainsFiltered={submainsFiltered}
              submainsLoading={submainsLoading}
            />
            <CalcButton submainsFiltered={submainsFiltered} />
          </>
        </Accordion>
      ) : (
        <Box>
          <PaperBox>
            <SimulationContent
              onSubmain1Change={onSubmain1Change}
              onSubmain2Change={onSubmain2Change}
              onSubmain3Change={onSubmain3Change}
              submainGroupsLoading={submainGroupsLoading}
              submainPipe1={submainPipe1}
              submainPipe2={submainPipe2}
              submainPipe3={submainPipe3}
              submainsFiltered={submainsFiltered}
              submainsLoading={submainsLoading}
            />
          </PaperBox>
          <CalcButton submainsFiltered={submainsFiltered} />
        </Box>
      )}
    </Box>
  );
};
