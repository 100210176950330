import {
  getMainlineGroupsThunk,
  getMainlinesThunk,
  setMainlineErrorValue,
  setMainlineStateValue,
  setProjectValue,
} from 'shared/slices';
import { useAppDispatch } from './useAppDispatch';
import { useAppSelector } from './useAppSelector';
import { SelectChangeEvent } from '@mui/material';
import { CatalogItem } from 'shared/models';
import { useEffect, useMemo } from 'react';
import { METRIC_DEFAULTS } from 'shared/constants';
import { Math_round } from 'shared/lib/calculation/mathRound';
import { ConvertUnit } from 'shared/lib/calculation/unitConverter';
import { FilterMainlineCatalogs } from 'shared/lib/calculation/Mainline/FilterMainlineCatalogs';
import { updateMainlineVelocities } from 'shared/lib/calculation/Mainline/updateMainlineVelocities';

const ALL = 'All';

export const useMainlineFilters = () => {
  const dispatch = useAppDispatch();
  const { masterGroups } = useAppSelector((st) => st.masterGroups);
  const { projectData } = useAppSelector((st) => st.projectData);
  const {
    mainlineValues: { mainlineClass, diameter },
  } = useAppSelector((st) => st.mainlineState);
  const { units } = useAppSelector((st) => st.units);
  const { mainlines, mainlineGroups, mainlinesLoading, mainlineGroupsLoading } = useAppSelector((st) => st.mainlines);
  const { mainlineValues } = useAppSelector((st) => st.mainlineState);

  const updateMainlines = async (group: string) => {
    const mainlines = await dispatch(
      getMainlinesThunk({
        group,
        region: projectData.region,
        standard: projectData.standard,
        limited: projectData.limited,
      })
    ).unwrap();

    if (!mainlines) return;

    dispatch(
      setProjectValue({
        mainlineCatalog: mainlines?.GetCatalogsByClassResult.RootResults[0]?.CATLOG,
        mainlinePipe1: mainlines?.GetCatalogsByClassResult.RootResults[0]?.CATLOG,
        mainlinePipe2: mainlines?.GetCatalogsByClassResult.RootResults[0]?.CATLOG,
        mainlinePipe3: mainlines?.GetCatalogsByClassResult.RootResults[0]?.CATLOG,
        mainlinePipe1Dia: Math_round(
          ConvertUnit(
            mainlines?.GetCatalogsByClassResult.RootResults[0]?.INTRNL,
            METRIC_DEFAULTS.PipeDiameter,
            units.pipeDiameter,
            null
          ),
          3
        ),
        mainlinePipe2Dia: Math_round(
          ConvertUnit(
            mainlines?.GetCatalogsByClassResult.RootResults[0]?.INTRNL,
            METRIC_DEFAULTS.PipeDiameter,
            units.pipeDiameter,
            null
          ),
          3
        ),
        mainlinePipe3Dia: Math_round(
          ConvertUnit(
            mainlines?.GetCatalogsByClassResult.RootResults[0]?.INTRNL,
            METRIC_DEFAULTS.PipeDiameter,
            units.pipeDiameter,
            null
          ),
          3
        ),
      })
    );
  };

  const onMasterGroupChange = async (e: SelectChangeEvent<unknown>) => {
    dispatch(setProjectValue({ mainlineMasterGroup: +(e.target.value as string) }));
    resetFilters();

    const groups = await dispatch(getMainlineGroupsThunk({ masterGroup: +(e.target.value as string) })).unwrap();

    if (!groups) return;

    dispatch(setProjectValue({ mainlineGroup: groups.GetGroupTypesResult.RootResults[0].GROUPS }));

    await updateMainlines(groups.GetGroupTypesResult.RootResults[0].GROUPS);
  };

  const onGroupTypeChange = async (e: SelectChangeEvent<unknown>) => {
    dispatch(setProjectValue({ mainlineGroup: e.target.value as string }));
    resetFilters();

    await updateMainlines(e.target.value as string);
  };

  const onMainlineChange = (_: unknown, item: CatalogItem) => {
    dispatch(
      setProjectValue({
        mainlineCatalog: item.CATLOG,
        mainlinePipe1: item.CATLOG,
        mainlinePipe2: item.CATLOG,
        mainlinePipe3: item.CATLOG,
      })
    );
  };

  const onMainline1Change = (_: unknown, item: CatalogItem) => {
    dispatch(
      setProjectValue({
        mainlinePipe1: item.CATLOG,
      })
    );
  };
  const onMainline2Change = (_: unknown, item: CatalogItem) => {
    dispatch(
      setProjectValue({
        mainlinePipe2: item.CATLOG,
      })
    );
  };
  const onMainline3Change = (_: unknown, item: CatalogItem) => {
    dispatch(
      setProjectValue({
        mainlinePipe3: item.CATLOG,
      })
    );
  };

  const onClassTypeChange = (e: SelectChangeEvent<unknown>) => {
    dispatch(setMainlineStateValue({ mainlineClass: e.target.value as string }));

    const mainlinesFiltered = mainlines.filter((item) =>
      e.target.value !== 'All' ? item.Class.trim() === e.target.value : true
    );

    if (e.target.value !== ALL) {
      if (mainlineValues.maxVelocity && mainlineValues.flow) {
        const currentPipes = FilterMainlineCatalogs({
          maxVelocity: +mainlineValues.maxVelocity,
          manifoldClass: e.target.value as string,
          totalFlow: +mainlineValues.flow,
          cbManifoldPipe: mainlinesFiltered,
          units,
        });

        if (!currentPipes) return;

        dispatch(
          setProjectValue({
            mainlinePipe1: currentPipes.manifoldPipe1Selected,
            mainlinePipe2: currentPipes.manifoldPipe2Selected,
            mainlinePipe3: currentPipes.manifoldPipe3Selected,
          })
        );
      } else {
        dispatch(
          setProjectValue({
            mainlinePipe1: mainlinesFiltered[0].CATLOG,
            mainlinePipe2: mainlinesFiltered[0].CATLOG,
            mainlinePipe3: mainlinesFiltered[0].CATLOG,
          })
        );
      }
    }
  };

  const onDiameterChange = (e: SelectChangeEvent<unknown>) => {
    dispatch(
      setMainlineStateValue({ diameter: e.target.value === ALL ? e.target.value : +(e.target.value as string) })
    );
  };

  useEffect(() => {
    const mainline1Dia = mainlinesFiltered.find((item) => item.CATLOG === projectData.mainlinePipe1)?.INTRNL ?? 0;
    const mainline2Dia = mainlinesFiltered.find((item) => item.CATLOG === projectData.mainlinePipe2)?.INTRNL ?? 0;
    const mainline3Dia = mainlinesFiltered.find((item) => item.CATLOG === projectData.mainlinePipe3)?.INTRNL ?? 0;

    dispatch(
      setProjectValue({
        mainlinePipe1Dia: Math_round(
          ConvertUnit(mainline1Dia, METRIC_DEFAULTS.PipeDiameter, units.pipeDiameter, null),
          3
        ),
        mainlinePipe2Dia: Math_round(
          ConvertUnit(mainline2Dia, METRIC_DEFAULTS.PipeDiameter, units.pipeDiameter, null),
          3
        ),
        mainlinePipe3Dia: Math_round(
          ConvertUnit(mainline3Dia, METRIC_DEFAULTS.PipeDiameter, units.pipeDiameter, null),
          3
        ),
      })
    );
  }, [projectData.mainlinePipe1, projectData.mainlinePipe2, projectData.mainlinePipe3]);

  useEffect(() => {
    const result = updateMainlineVelocities(
      {
        flow1: +mainlineValues.flow,
        mainlinePipe1Dia: +projectData.mainlinePipe1Dia,
        mainlinePipe2Dia: +projectData.mainlinePipe2Dia,
        mainlinePipe3Dia: +projectData.mainlinePipe3Dia,
        mainlineMaxVelocity: +mainlineValues.maxVelocity,
      },
      units
    );

    dispatch(
      setMainlineStateValue({
        velocity1: result.velocity1?.value,
        velocity2: result.velocity2?.value,
        velocity3: result.velocity3?.value,
      })
    );

    if (result.velocity1 && result.velocity1.value > +mainlineValues.maxVelocity) {
      dispatch(setMainlineErrorValue({ velocity1: true }));
    } else {
      dispatch(setMainlineErrorValue({ velocity1: false }));
    }
    if (result.velocity2 && result.velocity2.value > +mainlineValues.maxVelocity) {
      dispatch(setMainlineErrorValue({ velocity2: true }));
    } else {
      dispatch(setMainlineErrorValue({ velocity2: false }));
    }
    if (result.velocity3 && result.velocity3.value > +mainlineValues.maxVelocity) {
      dispatch(setMainlineErrorValue({ velocity3: true }));
    } else {
      dispatch(setMainlineErrorValue({ velocity3: false }));
    }
  }, [
    projectData.mainlinePipe1Dia,
    projectData.mainlinePipe2Dia,
    projectData.mainlinePipe3Dia,
    mainlineValues.flow,
    mainlineValues.maxVelocity,
  ]);

  const resetFilters = () => {
    dispatch(setMainlineStateValue({ mainlineClass: ALL }));
  };

  const classTypeFilter = (item: CatalogItem) => {
    if (projectData.region === 'USA' && item.AltClass !== null) {
      return mainlineClass !== ALL ? item.AltClass?.toString().trim() === mainlineClass : true;
    }

    return mainlineClass !== ALL ? item.Class.trim() === mainlineClass : true;
  };

  const diameterFilter = (item: CatalogItem) => {
    // if (projectData.region === 'USA' && item.AltInlet !== null) {
    //   return lateralDiameter ? item.AltInlet === lateralDiameter : true;
    // }
    return diameter !== 'All' ? item.INLET === diameter : true;
  };

  const mainlinesFiltered = useMemo(() => {
    return mainlines.filter((item) => classTypeFilter(item) && diameterFilter(item));
  }, [mainlineClass, diameter, mainlines]);

  useEffect(() => {
    if (mainlinesFiltered.length && !mainlinesFiltered.find((item) => item.CATLOG === projectData.mainlineCatalog)) {
      dispatch(setProjectValue({ mainlineCatalog: mainlinesFiltered[0]?.CATLOG }));
    }
  }, [mainlinesFiltered]);



  const classTypes = useMemo(() => {
    return [
      ALL,
      ...new Set(
        mainlines
          .map((item) =>
            projectData.region === 'USA' && item.AltClass !== null
              ? item.AltClass?.toString().trim()
              : item.Class.trim()
          )
          .sort((a, b) => +a - +b)
      ),
    ];
  }, [mainlines]);

  const diameters = useMemo(() => {
    return [ALL, ...new Set(mainlines.filter((item) => classTypeFilter(item)).map((item) => item.INLET))];
  }, [mainlineClass, mainlines]);

  useEffect(() => {
    if (!mainlinesFiltered.find((item) => item.CATLOG === projectData.mainlineCatalog)) {
      dispatch(setMainlineStateValue({ mainlineClass: ALL }));
    }
  }, [classTypes]);

  useEffect(() => {
    if (!mainlinesFiltered.find((item) => item.CATLOG === projectData.mainlineCatalog)) {
      dispatch(setMainlineStateValue({ diameter: ALL }));
    }
  }, [diameters]);

  const mainlineSubtype = useMemo(
    () =>
      mainlineGroups.find((item) => item.GROUPS === projectData.mainlineGroup?.trim())
        ? projectData.mainlineGroup?.trim()
        : '',
    [projectData.mainlineGroup, mainlineGroups]
  );

  const classTypeValue = useMemo(
    () => classTypes.find((item) => item === mainlineClass) ?? '',
    [mainlineClass, classTypes]
  );

  const diameterValue = useMemo(() => diameters.find((item) => item === diameter) ?? '', [diameter, diameters]);

  const filteredMasterGroups = useMemo(() => masterGroups.filter((item) => item.MAINLINE), [masterGroups]);

  const mainlinePipe1 = useMemo(
    () => mainlinesFiltered.find((item) => item.CATLOG === projectData.mainlinePipe1) ?? mainlinesFiltered[0],
    [projectData.mainlinePipe1, mainlinesFiltered]
  );
  const mainlinePipe2 = useMemo(
    () => mainlinesFiltered.find((item) => item.CATLOG === projectData.mainlinePipe2) ?? mainlinesFiltered[0],
    [projectData.mainlinePipe2, mainlinesFiltered]
  );
  const mainlinePipe3 = useMemo(
    () => mainlinesFiltered.find((item) => item.CATLOG === projectData.mainlinePipe3) ?? mainlinesFiltered[0],
    [projectData.mainlinePipe3, mainlinesFiltered]
  );

  const mainlineProduct = useMemo(
    () => mainlinesFiltered.find((item) => item.CATLOG === projectData.mainlineCatalog) ?? mainlinesFiltered[0],
    [projectData.mainlineCatalog, mainlinesFiltered]
  );

  return {
    diameters,
    diameterValue,
    mainlineProduct,
    mainlinesLoading,
    mainlineGroups,
    mainlineGroupsLoading,
    mainlinePipe1,
    mainlinePipe2,
    mainlinePipe3,
    masterGroups: filteredMasterGroups,
    masterGroupId: projectData.mainlineMasterGroup,
    onMasterGroupChange,
    mainlineSubtype,
    onGroupTypeChange,
    onDiameterChange,
    classType: classTypeValue,
    onClassTypeChange,
    classTypes,
    mainlinesFiltered,
    onMainline1Change,
    onMainline2Change,
    onMainline3Change,
    onMainlineChange,
  };
};
