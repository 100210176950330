import { Box, Button } from '@mui/material';
import {
  getProjectsThunk,
  resetEmitters,
  resetLaterals,
  resetMainlines,
  resetSubmains,
  saveProjectAsThunk,
  toggleNewProject,
} from 'shared/slices';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector, useToast } from 'shared/hooks';
import { Input, Modal, RadioSwitcher } from 'shared/ui';
import { ChangeEvent, FocusEvent, useState } from 'react';
import { EmitterBlock, GeneralBlock, LateralBlock, MainlineBlock, SubmainBlock } from './components';
import { ProjectData } from 'shared/models';

//TODO: NEED FULL REWORK OF LOGIC!

export const NewProject = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { showSuccess, showError } = useToast();
  const { openNewProject } = useAppSelector((st) => st.modals);

  const { projectData } = useAppSelector((st) => st.projectData);

  const integratedValues = [t('integrated'), t('onLine')];

  const [info, setInfo] = useState({
    designer: projectData.designer,
    company: projectData.company,
    phone: projectData.phone,
    mobile: projectData.mobile,
    fax: projectData.fax,
    email: projectData.email,
  });
  const [region, setRegion] = useState(projectData.region);
  const [checkboxes, setCheckboxes] = useState({ standard: projectData.standard, limited: projectData.limited });
  const [integrated, setIntegrated] = useState(true);
  const [projectName, setProjectName] = useState('');

  const [lateralData, setLateralData] = useState({
    masterGroup: projectData.lateralMasterGroup,
    group: projectData.lateralGroup,
    lateral: projectData.lateralCatalog,
  });

  const [emitterData, setEmitterData] = useState({
    masterGroup: projectData.emitterMasterGroup,
    group: projectData.emitterGroup,
    emitter: projectData.emitterCatalog,
  });

  const [submainData, setSubmainData] = useState({
    masterGroup: projectData.submainMasterGroup,
    group: projectData.submainGroup,
    submain: projectData.submainCatalog,
  });

  const [mainlineData, setMainlineData] = useState({
    masterGroup: projectData.mainlineMasterGroup,
    group: projectData.mainlineGroup,
    mainline: projectData.mainlineCatalog,
  });

  const handleLateralDataChange = (value: Partial<typeof lateralData>) => {
    setLateralData((prev) => ({ ...prev, ...value }));
  };

  const handleEmitterDataChange = (value: Partial<typeof emitterData>) => {
    setEmitterData((prev) => ({ ...prev, ...value }));
  };

  const handleSubmainDataChange = (value: Partial<typeof submainData>) => {
    setSubmainData((prev) => ({ ...prev, ...value }));
  };

  const handleMainlineDataChange = (value: Partial<typeof mainlineData>) => {
    setMainlineData((prev) => ({ ...prev, ...value }));
  };

  const handleInfoChange = (e: FocusEvent<HTMLInputElement>) => {
    setInfo((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCheckboxes((prev) => ({ ...prev, [e.target.name]: e.target.checked }));
  };

  const handleToggleIntegrated = () => setIntegrated((prev) => !prev);

  const toggle = () => dispatch(toggleNewProject());

  const onSaveClick = async () => {
    const projectDetails: ProjectData = {
      designer: info.designer,
      company: info.company,
      phone: info.phone,
      mobile: info.mobile,
      fax: info.fax,
      email: info.email,
      region: region,
      standard: checkboxes.standard,
      limited: checkboxes.limited,
      integrated: integrated,
      emitterMasterGroup: emitterData.masterGroup,
      emitterGroup: emitterData.group,
      emitterCatalog: emitterData.emitter,
      emitterSpacing: 0,
      emitterPMin: 0,
      emitterPMax: 0,
      emitterNominalFlow: 0,
      emitterNominalPressure: 0,
      lateralInletPressure: 0,
      lateralInletDia: 0,
      lateralMasterGroup: lateralData.masterGroup,
      lateralGroup: lateralData.group,
      lateralCatalog: lateralData.lateral,
      lateralSlopes: '',
      submainMasterGroup: submainData.masterGroup,
      submainGroup: submainData.group,
      submainCatalog: submainData.submain,
      manifoldSlopes: '',
      mainlineMasterGroup: mainlineData.masterGroup,
      mainlineGroup: mainlineData.group,
      mainlineCatalog: mainlineData.mainline,
      mainlinePipe1: mainlineData.mainline,
      mainlinePipe2: mainlineData.mainline,
      mainlinePipe3: mainlineData.mainline,
      manifoldPipe1: submainData.submain,
      manifoldPipe2: submainData.submain,
      manifoldPipe3: submainData.submain,
      valvePressure: 0,
      manifoldHeadloss: 0,
      headlossCalc: 'D.W',
      lateralLength2: 0,
      manifoldAllowableHeadloss: 5,
      submainClass: '',
      firstLateralDistance: 0,
      manifoldSpacing: 0,
      totalManifoldLength: 0,
      totalRows: 0,
      manifoldPipe1Dia: 0,
      manifoldPipe2Dia: 0,
      manifoldPipe3Dia: 0,
      mainlineAllowedHeadloss: 10,
      mainlineValveHeadloss: 5,
      mainlineLength: 0,
      mainlineFlow: 0,
      mainlineMaxVelocity: 2.5,
      mainlineClass: '',
      mainlinePumpPressure: 0,
      mainlinePressureAtValve: 0,
      mainlinePipeHeadloss: 0,
      mainlinePumpHeadloss: 10,
      mainlinePipe1Dia: 0,
      mainlinePipe2Dia: 0,
      mainlinePipe3Dia: 0,
      flow1: 0,
      flow2: 0,
      zoneIrrigationArea: 0,
      zoneLateralsPerRow: 0,
      avgPeakConsumptiveUse: 0,
      sourceFlowRate: 0,
      zoneMaxIrrigationTimePerDay: 24,
      address: '',
      c_Date: '/Date(1652253546967+0000)/',
      clientEmail: '',
      clientFax: '',
      clientMobile: '',
      clientPhone: '',
      cvv: 0,
      dealer: '',
      emitterA: 0,
      emitterB: 0,
      emitterGroupType: 0,
      euType: 'Classic',
      flushingVelocity: 0,
      growerName: '',
      hwCoef: 150,
      isBothSides: true,
      language: '',
      lateralClass: '',
      lateralDia: 0,
      lateralFlow: 0,
      lateralGroupType: 0,
      lateralKd: 0,
      lateralNomFlow: 0,
      lateralSpacing: 0,
      mainlineGroupType: 0,
      mainlineHWCoef: 140,
      mainlineHeadlossCalc: 'D.W',
      mainlinePipe1Length: 0,
      mainlinePipe2Length: 0,
      mainlinePipe3Length: 0,
      mainlineSlope: 0,
      mainlineSlopeDir: 'Uphill',
      manifoldHWCoef: 140,
      manifoldHeadlossCalc: 'D.W',
      manifoldLateralInletPressure: 0,
      manifoldMaximumVelocity: 0,
      manifoldPipe1Length: 0,
      manifoldPipe2Length: 0,
      manifoldPipe3Length: 0,
      minimumEu: 0,
      notes: '',
      runlengthEnd: 0,
      runlengthIncrement: 0,
      runlengthStart: 0,
      submainGroupType: 0,
      tabMainlineClass: '',
      totalFlow: 0,
      tradingName: '',
      zoneEmitterFlowRate: 0,
      zoneEmitterSpacing: 0,
      zoneLateralSpacing: 0,
    };

    const userId = localStorage.getItem('ht-user-id');

    if (!userId) return;

    const result = await dispatch(
      saveProjectAsThunk({
        projectData: projectDetails,
        projectName,
      }),
    ).unwrap();

    dispatch(resetEmitters());
    dispatch(resetLaterals());
    dispatch(resetMainlines());
    dispatch(resetSubmains());

    if (result?.data) showSuccess(t('projectSaved'));
    if (!result?.data) showError(t('SomethingWentWrong'));

    await dispatch(getProjectsThunk());

    toggle();
  };

  return (
    <Modal maxWidth='md' title={t('newProject')} open={openNewProject} onClose={toggle}>
      <Box>
        <Box mb={1}>
          <Input type='text' label={`${t('projectName')}`} onBlur={(e) => setProjectName(e.target.value)} />
        </Box>
        <GeneralBlock
          checkboxes={checkboxes}
          region={region}
          handleCheckboxChange={handleCheckboxChange}
          handleRegionChange={(e) => setRegion(e.target.value as string)}
          handleInfoChange={handleInfoChange}
        />
        <Box mb={1} mt={2}>
          <RadioSwitcher values={integratedValues} trueFlag={integrated} onChange={handleToggleIntegrated} />
        </Box>
        {!integrated && (
          <EmitterBlock
            handleEmitterDataChange={handleEmitterDataChange}
            region={region}
            standard={checkboxes.standard}
            limited={checkboxes.limited}
          />
        )}
        <LateralBlock
          handleLateralDataChange={handleLateralDataChange}
          region={region}
          standard={checkboxes.standard}
          limited={checkboxes.limited}
          integrated={integrated}
        />
        <SubmainBlock
          handleSubmainDataChange={handleSubmainDataChange}
          region={region}
          standard={checkboxes.standard}
          limited={checkboxes.limited}
        />
        <MainlineBlock
          handleMainlineDataChange={handleMainlineDataChange}
          region={region}
          standard={checkboxes.standard}
          limited={checkboxes.limited}
        />
        <Box display='flex' columnGap={1}>
          <Button
            disabled={
              !projectName.length ||
              !lateralData.lateral ||
              !emitterData.emitter ||
              !submainData.submain ||
              !mainlineData.mainline
            }
            onClick={onSaveClick}
            variant='contained'
            sx={{ flexBasis: '50%' }}
          >
            {t('ok')}
          </Button>

          <Button onClick={toggle} variant='outlined' sx={{ flexBasis: '50%' }}>
            {t('cancel')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
