import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { customAxios } from 'shared/api';
import { CatalogItem, LateralsBody, CatalogsResponse, GroupsBody, GroupsResponse, Group } from 'shared/models';

interface UserState {
  lateralsLoading: boolean;
  lateralGroupsLoading: boolean;
  laterals: CatalogItem[];
  lateralsLoadedOnce: boolean;
  lateralGroupsLoadedOnce: boolean;
  lateralGroups: Group[];
}

const initialState: UserState = {
  lateralsLoading: true,
  lateralsLoadedOnce: false,
  laterals: [],
  lateralGroupsLoadedOnce: false,
  lateralGroupsLoading: true,
  lateralGroups: [],
};

export const getLateralsThunk = createAsyncThunk('getLaterals', async (params: LateralsBody, thunkAPI) => {
  try {
    const response = await customAxios.get<CatalogsResponse>(
      'api/ClientBin/WaterSL-Web-WaterDomainService.svc/JSON/GetCatalogPerDiaClassFlowSpacing',
      { params }
    );
    return response.data;
  } catch (e) {
    if (e instanceof Error) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
});

export const getLateralGroupsThunk = createAsyncThunk(
  'groupTypes/getLateralGroupTypes',
  async (params: GroupsBody, thunkAPI) => {
    try {
      const response = await customAxios.get<GroupsResponse>(
        'api/ClientBin/WaterSL-Web-WaterDomainService.svc/JSON/GetGroupTypes',
        { params }
      );
      return response.data;
    } catch (e) {
      if (e instanceof Error) {
        return thunkAPI.rejectWithValue(e.message);
      }
    }
  }
);

const lateralsSlice = createSlice({
  name: 'laterals',
  initialState,
  reducers: {
    resetLaterals: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLateralsThunk.pending.type, (state) => {
        state.lateralsLoading = true;
      })
      .addCase(getLateralsThunk.fulfilled.type, (state, action: PayloadAction<CatalogsResponse>) => {
        state.laterals = action.payload.GetCatalogPerDiaClassFlowSpacingResult.RootResults;
        state.lateralsLoading = false;
        if (!state.lateralsLoadedOnce) state.lateralsLoadedOnce = true;
      })
      .addCase(getLateralsThunk.rejected.type, (state) => {
        state.lateralsLoading = false;
      })
      .addCase(getLateralGroupsThunk.pending.type, (state) => {
        state.lateralGroupsLoading = true;
      })
      .addCase(getLateralGroupsThunk.fulfilled.type, (state, action: PayloadAction<GroupsResponse>) => {
        state.lateralGroups = action.payload.GetGroupTypesResult.RootResults;
        state.lateralGroupsLoading = false;
        if (!state.lateralGroupsLoadedOnce) state.lateralGroupsLoadedOnce = true;
      })
      .addCase(getLateralGroupsThunk.rejected.type, (state) => {
        state.lateralGroupsLoading = false;
      });
  },
});

export const { resetLaterals } = lateralsSlice.actions;

export const lateralsReducer = lateralsSlice.reducer;
