import { useEffect, useMemo } from 'react';
import { useAppDispatch } from './useAppDispatch';
import { useAppSelector } from './useAppSelector';
import { CatalogItem } from 'shared/models';
import { SelectChangeEvent } from '@mui/material';
import { getLateralGroupsThunk, getLateralsThunk, setLateralStateValue, setProjectValue } from 'shared/slices';
import { Math_round } from 'shared/lib/calculation/mathRound';
import { ConvertUnit } from 'shared/lib/calculation/unitConverter';
import { METRIC_DEFAULTS } from 'shared/constants';

const ALL = 'All';

export const useLateralFilters = () => {
  const dispatch = useAppDispatch();
  const { projectData } = useAppSelector((st) => st.projectData);
  const { laterals, lateralGroups } = useAppSelector((st) => st.laterals);
  const {
    lateralValues: {
      lateralClass,
      lateralDiameter,
      lateralFlowPer,
      lateralNominalFlow,
      lateralSpacing,
      integratedCatalog,
      integratedGroup,
      integratedMasterGroup,
      onlineCatalog,
      onlineGroup,
      onlineMasterGroup,
    },
  } = useAppSelector((state) => state.lateralState);
  const { masterGroups } = useAppSelector((state) => state.masterGroups);
  const { units } = useAppSelector((state) => state.units);

  const updateLaterals = async (group: string) => {
    if (projectData.integrated) {
      dispatch(setLateralStateValue({ integratedGroup: group }));
    }
    if (!projectData.integrated) {
      dispatch(setLateralStateValue({ onlineGroup: group }));
    }

    const laterals = await dispatch(
      getLateralsThunk({
        group,
        region: projectData.region,
        standard: projectData.standard,
        limited: projectData.limited,
      }),
    ).unwrap();

    if (!laterals) return;

    if (
      projectData.integrated &&
      integratedCatalog &&
      laterals.GetCatalogPerDiaClassFlowSpacingResult.RootResults.find((item) => item.CATLOG === integratedCatalog)
    ) {
      const product = laterals.GetCatalogPerDiaClassFlowSpacingResult.RootResults.find(
        (item) => item.CATLOG === integratedCatalog,
      ) as CatalogItem;
      dispatch(setLateralStateValue({ integratedCatalog: integratedCatalog }));
      dispatch(
        setProjectValue({
          lateralCatalog: product.CATLOG,
          ...(projectData.integrated && {
            lateralInletDia: Math_round(
              ConvertUnit(product.INTRNL, METRIC_DEFAULTS.PipeDiameter, units.pipeDiameter, null),
              3,
            ),
            emitterSpacing: Math_round(
              ConvertUnit(product.Spacing, METRIC_DEFAULTS.LateralSpacing, units.lateralSpacing, null),
              3,
            ),
            zoneEmitterSpacing: Math_round(
              ConvertUnit(product.Spacing, METRIC_DEFAULTS.LateralSpacing, units.lateralSpacing, null),
              3,
            ),
            zoneEmitterFlowRate: Math_round(ConvertUnit(product.Qnom ?? 0, METRIC_DEFAULTS.Flow, units.flow, null), 3),
            emitterPMax: Math_round(ConvertUnit(product.Pmax, METRIC_DEFAULTS.Pressure, units.pressure, null), 3),
            emitterPMin: Math_round(ConvertUnit(product.Pmin, METRIC_DEFAULTS.Pressure, units.pressure, null), 3),
            emitterA: laterals.GetCatalogPerDiaClassFlowSpacingResult.RootResults[0]?.EMITTERQA,
            emitterB: laterals.GetCatalogPerDiaClassFlowSpacingResult.RootResults[0]?.EMITTERQB,
            lateralKd: laterals.GetCatalogPerDiaClassFlowSpacingResult.RootResults[0]?.KD,
            cvv: laterals.GetCatalogPerDiaClassFlowSpacingResult.RootResults[0]?.MManuFactCV,
            hwCoef: laterals.GetCatalogPerDiaClassFlowSpacingResult.RootResults[0]?.HWCOF,
            emitterNominalPressure: laterals.GetCatalogPerDiaClassFlowSpacingResult.RootResults[0]?.Qnom,
            emitterNominalFlow: laterals.GetCatalogPerDiaClassFlowSpacingResult.RootResults[0]?.PressureNominal_m_,
          }),
        }),
      );
      return;
    }

    if (
      !projectData.integrated &&
      onlineCatalog &&
      laterals.GetCatalogPerDiaClassFlowSpacingResult.RootResults.find((item) => item.CATLOG === onlineCatalog)
    ) {
      const product = laterals.GetCatalogPerDiaClassFlowSpacingResult.RootResults.find(
        (item) => item.CATLOG === onlineCatalog,
      ) as CatalogItem;
      dispatch(setLateralStateValue({ onlineCatalog: onlineCatalog }));
      dispatch(
        setProjectValue({
          lateralCatalog: product.CATLOG,
          ...(!projectData.integrated && {
            lateralInletDia: Math_round(
              ConvertUnit(product.INTRNL, METRIC_DEFAULTS.PipeDiameter, units.pipeDiameter, null),
              3,
            ),
          }),
        }),
      );
      return;
    }

    if (projectData.integrated) {
      dispatch(
        setLateralStateValue({
          integratedCatalog: laterals.GetCatalogPerDiaClassFlowSpacingResult.RootResults[0]?.CATLOG,
        }),
      );
    }

    if (!projectData.integrated) {
      dispatch(
        setLateralStateValue({ onlineCatalog: laterals.GetCatalogPerDiaClassFlowSpacingResult.RootResults[0]?.CATLOG }),
      );
    }

    dispatch(
      setProjectValue({
        lateralCatalog: laterals.GetCatalogPerDiaClassFlowSpacingResult.RootResults[0]?.CATLOG,
        ...(projectData.integrated && {
          lateralInletDia: Math_round(
            ConvertUnit(
              laterals.GetCatalogPerDiaClassFlowSpacingResult.RootResults[0]?.INTRNL,
              METRIC_DEFAULTS.PipeDiameter,
              units.pipeDiameter,
              null,
            ),
            3,
          ),
          emitterSpacing: Math_round(
            ConvertUnit(
              laterals.GetCatalogPerDiaClassFlowSpacingResult.RootResults[0]?.Spacing,
              METRIC_DEFAULTS.LateralSpacing,
              units.lateralSpacing,
              null,
            ),
            3,
          ),
          zoneEmitterSpacing: Math_round(
            ConvertUnit(
              laterals.GetCatalogPerDiaClassFlowSpacingResult.RootResults[0]?.Spacing,
              METRIC_DEFAULTS.LateralSpacing,
              units.lateralSpacing,
              null,
            ),
            3,
          ),
          zoneEmitterFlowRate: Math_round(
            ConvertUnit(
              laterals.GetCatalogPerDiaClassFlowSpacingResult.RootResults[0]?.Qnom ?? 0,
              METRIC_DEFAULTS.Flow,
              units.flow,
              null,
            ),
            3,
          ),
          emitterPMax: Math_round(
            ConvertUnit(
              laterals.GetCatalogPerDiaClassFlowSpacingResult.RootResults[0]?.Pmax,
              METRIC_DEFAULTS.Pressure,
              units.pressure,
              null,
            ),
            3,
          ),
          emitterPMin: Math_round(
            ConvertUnit(
              laterals.GetCatalogPerDiaClassFlowSpacingResult.RootResults[0]?.Pmin,
              METRIC_DEFAULTS.Pressure,
              units.pressure,
              null,
            ),
            3,
          ),
          emitterA: laterals.GetCatalogPerDiaClassFlowSpacingResult.RootResults[0]?.EMITTERQA,
          emitterB: laterals.GetCatalogPerDiaClassFlowSpacingResult.RootResults[0]?.EMITTERQB,
          lateralKd: laterals.GetCatalogPerDiaClassFlowSpacingResult.RootResults[0]?.KD,
          cvv: laterals.GetCatalogPerDiaClassFlowSpacingResult.RootResults[0]?.MManuFactCV,
          hwCoef: laterals.GetCatalogPerDiaClassFlowSpacingResult.RootResults[0]?.HWCOF,
          emitterNominalPressure: laterals.GetCatalogPerDiaClassFlowSpacingResult.RootResults[0]?.Qnom,
          emitterNominalFlow: laterals.GetCatalogPerDiaClassFlowSpacingResult.RootResults[0]?.PressureNominal_m_,
        }),
        ...(!projectData.integrated && {
          lateralInletDia: Math_round(
            ConvertUnit(
              laterals.GetCatalogPerDiaClassFlowSpacingResult.RootResults[0]?.INTRNL,
              METRIC_DEFAULTS.PipeDiameter,
              units.pipeDiameter,
              null,
            ),
            3,
          ),
        }),
      }),
    );
  };
  const onMasterGroupChange = async (value: number) => {
    dispatch(setProjectValue({ lateralMasterGroup: value }));

    if (projectData.integrated) {
      dispatch(setLateralStateValue({ integratedMasterGroup: value }));
    }

    if (!projectData.integrated) {
      dispatch(setLateralStateValue({ onlineMasterGroup: value }));
    }

    resetFilters();

    const groups = await dispatch(getLateralGroupsThunk({ masterGroup: value })).unwrap();

    if (!groups) return;

    if (
      projectData.integrated &&
      integratedGroup &&
      groups.GetGroupTypesResult.RootResults.find((item) => item.GROUPS === integratedGroup)
    ) {
      dispatch(setProjectValue({ lateralGroup: integratedGroup }));
      await updateLaterals(integratedGroup);
      return;
    }

    if (
      !projectData.integrated &&
      onlineGroup &&
      groups.GetGroupTypesResult.RootResults.find((item) => item.GROUPS === onlineGroup)
    ) {
      dispatch(setProjectValue({ lateralGroup: onlineGroup }));
      await updateLaterals(onlineGroup);
      return;
    }

    dispatch(setProjectValue({ lateralGroup: groups.GetGroupTypesResult.RootResults[0].GROUPS }));

    await updateLaterals(groups.GetGroupTypesResult.RootResults[0].GROUPS);
  };
  const onGroupTypeChange = async (e: SelectChangeEvent<unknown>) => {
    dispatch(setProjectValue({ lateralGroup: e.target.value as string }));

    if (projectData.integrated) {
      dispatch(setLateralStateValue({ integratedGroup: e.target.value as string }));
    }
    if (!projectData.integrated) {
      dispatch(setLateralStateValue({ onlineGroup: e.target.value as string }));
    }

    resetFilters();

    await updateLaterals(e.target.value as string);
  };

  const onLateralChange = (_: any, item: CatalogItem) => {
    if (projectData.integrated) {
      dispatch(setLateralStateValue({ integratedCatalog: item.CATLOG }));
    }

    if (!projectData.integrated) {
      dispatch(setLateralStateValue({ onlineCatalog: item.CATLOG }));
    }

    dispatch(
      setProjectValue({
        lateralCatalog: item.CATLOG,
        ...(projectData.integrated && {
          lateralInletDia: Math_round(
            ConvertUnit(item.INTRNL, METRIC_DEFAULTS.PipeDiameter, units.pipeDiameter, null),
            3,
          ),
          emitterSpacing: Math_round(
            ConvertUnit(item.Spacing, METRIC_DEFAULTS.LateralSpacing, units.lateralSpacing, null),
            3,
          ),
          zoneEmitterSpacing: Math_round(
            ConvertUnit(item.Spacing, METRIC_DEFAULTS.LateralSpacing, units.lateralSpacing, null),
            3,
          ),
          emitterPMax: Math_round(ConvertUnit(item.Pmax, METRIC_DEFAULTS.Pressure, units.pressure, null), 3),
          emitterPMin: Math_round(ConvertUnit(item.Pmin, METRIC_DEFAULTS.Pressure, units.pressure, null), 3),
          emitterA: item.EMITTERQA,
          emitterB: item.EMITTERQB,
          lateralKd: item.KD,
          cvv: item.MManuFactCV,
          hwCoef: item.HWCOF,
          emitterNominalPressure: item.Qnom,
          emitterNominalFlow: item.PressureNominal_m_,
        }),
        ...(!projectData.integrated && {
          lateralInletDia: Math_round(
            ConvertUnit(item.INTRNL, METRIC_DEFAULTS.PipeDiameter, units.pipeDiameter, null),
            3,
          ),
        }),
      }),
    );
  };
  const onDiameterChange = (e: SelectChangeEvent<unknown>) => {
    dispatch(
      setLateralStateValue({ lateralDiameter: e.target.value === ALL ? e.target.value : +(e.target.value as string) }),
    );
  };
  const onClassTypeChange = (e: SelectChangeEvent<unknown>) => {
    dispatch(setLateralStateValue({ lateralClass: e.target.value as string }));
  };
  const onFlowPerUnitChange = (e: SelectChangeEvent<unknown>) => {
    dispatch(
      setLateralStateValue({ lateralFlowPer: e.target.value === ALL ? e.target.value : +(e.target.value as string) }),
    );
  };
  const onNominalFlowChange = (e: SelectChangeEvent<unknown>) => {
    dispatch(
      setLateralStateValue({
        lateralNominalFlow: e.target.value === ALL ? e.target.value : +(e.target.value as string),
      }),
    );
  };
  const onSpacingChange = (e: SelectChangeEvent<unknown>) => {
    dispatch(
      setLateralStateValue({ lateralSpacing: e.target.value === ALL ? e.target.value : +(e.target.value as string) }),
    );
  };

  const diameterFilter = (item: CatalogItem) => {
    if (projectData.region === 'USA' && item.AltInlet !== null) {
      return lateralDiameter !== ALL ? item.AltInlet === lateralDiameter : true;
    }
    return lateralDiameter !== ALL ? item.INLET === lateralDiameter : true;
  };
  const classTypeFilter = (item: CatalogItem) => {
    if (projectData.region === 'USA' && item.AltClass !== null) {
      return lateralClass !== ALL ? item.AltClass?.toString().trim() === lateralClass : true;
    }

    return lateralClass !== ALL ? item.Class.trim() === lateralClass : true;
  };
  const flowPerUnitFilter = (item: CatalogItem) => {
    if (projectData.region === 'USA' && item.Q_gpm_100ft_ !== null) {
      return lateralFlowPer !== ALL ? item.Q_gpm_100ft_ === lateralFlowPer : true;
    }

    return lateralFlowPer !== ALL ? item.Q_l_hr_100m_ === lateralFlowPer : true;
  };
  const nominalFlowFilter = (item: CatalogItem) => {
    if (projectData.region === 'USA' && item.AltQnom !== null) {
      return lateralNominalFlow !== ALL ? item.AltQnom === lateralNominalFlow : true;
    }
    return lateralNominalFlow !== ALL ? item.Qnom === lateralNominalFlow : true;
  };
  const spacingFilter = (item: CatalogItem) => {
    if (projectData.region === 'USA' && item.AltSpacing !== null) {
      return lateralSpacing !== ALL ? item.AltSpacing === lateralSpacing : true;
    }
    return lateralSpacing !== ALL ? item.Spacing === lateralSpacing : true;
  };

  const resetFilters = () => {
    dispatch(
      setLateralStateValue({
        lateralDiameter: ALL,
        lateralClass: ALL,
        lateralFlowPer: ALL,
        lateralNominalFlow: ALL,
        lateralSpacing: ALL,
      }),
    );
  };

  const lateralsFiltered = useMemo(() => {
    const result = laterals.filter(
      (item) =>
        diameterFilter(item) &&
        classTypeFilter(item) &&
        flowPerUnitFilter(item) &&
        nominalFlowFilter(item) &&
        spacingFilter(item),
    );

    return result;
  }, [lateralDiameter, lateralClass, lateralNominalFlow, lateralSpacing, lateralFlowPer, laterals]);

  useEffect(() => {
    if (lateralsFiltered.length && !lateralsFiltered.find((item) => item.CATLOG === projectData.lateralCatalog)) {
      onLateralChange(null, lateralsFiltered[0]);
    }
  }, [lateralsFiltered]);

  const diameters = useMemo(
    () => [
      ALL,
      ...new Set(
        laterals
          .map((item) =>
            projectData.region === 'USA' && item.AltInlet !== null ? item.AltInlet ?? '' : item.INLET ?? '',
          )
          .sort((a, b) => a - b),
      ),
    ],
    [laterals],
  );

  const classTypes = useMemo(() => {
    return [
      ALL,
      ...new Set(
        laterals
          .filter((item) => diameterFilter(item))
          .map((item) =>
            projectData.region === 'USA' && item.AltClass !== null
              ? item.AltClass?.toString().trim()
              : item.Class.trim(),
          )
          .sort((a, b) => +a - +b),
      ),
    ];
  }, [lateralDiameter, laterals]);

  useEffect(() => {
    if (!lateralsFiltered.find((item) => item.CATLOG === projectData.lateralCatalog)) {
      dispatch(
        setLateralStateValue({
          lateralClass: ALL,
          lateralFlowPer: ALL,
          lateralNominalFlow: ALL,
          lateralSpacing: ALL,
        }),
      );
    }
  }, [classTypes]);

  const flowPerUnits = useMemo(() => {
    return [
      ALL,
      ...new Set(
        laterals
          .filter((item) => diameterFilter(item) && classTypeFilter(item))
          .map((item) =>
            projectData.region === 'USA' && item.Q_gpm_100ft_ !== null ? item.Q_gpm_100ft_ ?? 0 : item.Q_l_hr_100m_,
          )
          .sort((a, b) => a - b),
      ),
    ];
  }, [lateralDiameter, lateralClass, laterals]);

  useEffect(() => {
    if (!lateralsFiltered.find((item) => item.CATLOG === projectData.lateralCatalog)) {
      dispatch(
        setLateralStateValue({
          lateralFlowPer: ALL,
          lateralNominalFlow: ALL,
          lateralSpacing: ALL,
        }),
      );
    }
  }, [flowPerUnits]);

  const nominalFlows = useMemo(() => {
    return [
      ALL,
      ...new Set(
        laterals
          .filter((item) => diameterFilter(item) && classTypeFilter(item) && flowPerUnitFilter(item))
          .map((item) => (projectData.region === 'USA' && item.AltQnom !== null ? item.AltQnom ?? 0 : item.Qnom))
          .sort((a, b) => a - b),
      ),
    ];
  }, [lateralDiameter, lateralClass, lateralFlowPer, laterals]);

  useEffect(() => {
    if (!lateralsFiltered.find((item) => item.CATLOG === projectData.lateralCatalog)) {
      dispatch(
        setLateralStateValue({
          lateralNominalFlow: ALL,
          lateralSpacing: ALL,
        }),
      );
    }
  }, [nominalFlows]);

  const spacings = useMemo(() => {
    return [
      ALL,
      ...new Set(
        laterals
          .filter(
            (item) =>
              diameterFilter(item) && classTypeFilter(item) && nominalFlowFilter(item) && flowPerUnitFilter(item),
          )
          .map((item) => (projectData.region === 'USA' && item.AltQnom !== null ? item.AltSpacing ?? 0 : item.Spacing))
          .sort((a, b) => a - b),
      ),
    ];
  }, [lateralDiameter, lateralClass, lateralNominalFlow, lateralFlowPer, laterals]);

  useEffect(() => {
    if (!lateralsFiltered.find((item) => item.CATLOG === projectData.lateralCatalog)) {
      dispatch(
        setLateralStateValue({
          lateralSpacing: ALL,
        }),
      );
    }
  }, [spacings]);

  const lateralSubtype = useMemo(
    () => (lateralGroups.find((item) => item.GROUPS === projectData.lateralGroup) ? projectData.lateralGroup : ''),
    [projectData.lateralGroup, lateralGroups],
  );

  const lateralProduct = useMemo(
    () => lateralsFiltered.find((item) => item.CATLOG === projectData.lateralCatalog) ?? lateralsFiltered[0],
    [projectData.lateralCatalog, lateralsFiltered],
  );

  const lateralClassValue = useMemo(
    () => classTypes.find((item) => item === lateralClass) ?? '',
    [lateralClass, classTypes],
  );

  const lateralFlowPerValue = useMemo(
    () => flowPerUnits.find((item) => item === lateralFlowPer) ?? '',
    [lateralFlowPer, flowPerUnits],
  );

  const lateralNominalFlowValue = useMemo(
    () => nominalFlows.find((item) => item === lateralNominalFlow) ?? '',
    [lateralNominalFlow, nominalFlows],
  );

  const lateralSpacingValue = useMemo(
    () => spacings.find((item) => item === lateralSpacing) ?? '',
    [lateralSpacing, spacings],
  );

  const filteredMasterGroups = useMemo(
    () =>
      masterGroups
        .filter((item) => item.LATERAL)
        .filter((item) =>
          projectData.integrated ? item.MASTERGROUPNAME.includes('Drip') : !item.MASTERGROUPNAME.includes('Drip'),
        ),
    [masterGroups, projectData.integrated],
  );

  const getLateralMasterGroup = () => {
    if (projectData.integrated && integratedMasterGroup) {
      return integratedMasterGroup;
    }

    if (!projectData.integrated && onlineMasterGroup) {
      return onlineMasterGroup;
    }

    return (
      filteredMasterGroups.find((item) => item.ID === projectData.lateralMasterGroup)?.ID ?? filteredMasterGroups[0].ID
    );
  };

  const lateralMasterGroup = getLateralMasterGroup();

  useEffect(() => {
    if (!filteredMasterGroups.find((item) => item.ID === projectData.lateralMasterGroup))
      onMasterGroupChange(lateralMasterGroup);
  }, [lateralMasterGroup]);

  return {
    masterGroups: filteredMasterGroups,
    masterGroupId: lateralMasterGroup,
    onMasterGroupChange,
    lateralSubtype,
    onGroupTypeChange,
    diameter: lateralDiameter,
    onDiameterChange,
    classType: lateralClassValue,
    onClassTypeChange,
    flowPerUnit: lateralFlowPerValue,
    onFlowPerUnitChange,
    nominalFlow: lateralNominalFlowValue,
    onNominalFlowChange,
    spacing: lateralSpacingValue,
    onSpacingChange,
    diameters,
    classTypes,
    flowPerUnits,
    nominalFlows,
    spacings,
    lateralProduct,
    lateralsFiltered,
    onLateralChange,
  };
};
