import { Box, Grid, useMediaQuery } from '@mui/material';
import { ChangeEvent, FC } from 'react';
import { useAppDispatch, useAppSelector, useToast } from 'shared/hooks';
import { MainlineCalcResult } from 'shared/lib/calculation/models';
import { mainLineCalculate } from 'shared/lib/calculation/Mainline/mainLineCalculate';
import { CatalogItem } from 'shared/models';
import { setMainlineStateValue, setProjectValue } from 'shared/slices';
import { Accordion, Button, IconLabel, Input, PaperBox, ProductSelect, ShepherdBlock } from 'shared/ui';
import { useTranslation } from 'react-i18next';
import { pipe_dia } from 'shared/assets';
import { formattedInputValue, scrollToResults } from 'shared/lib';

interface Props {
  mainlinePipe1: CatalogItem;
  mainlinePipe2: CatalogItem;
  mainlinePipe3: CatalogItem;
  mainlinesFiltered: CatalogItem[];
  mainlinesLoading: boolean;
  mainlineGroupsLoading: boolean;
  onMainline1Change: (e: any, item: CatalogItem) => void;
  onMainline2Change: (e: any, item: CatalogItem) => void;
  onMainline3Change: (e: any, item: CatalogItem) => void;
}

const SimulationContent: FC<Props> = ({
                                        mainlinePipe1,
                                        mainlinePipe2,
                                        mainlinePipe3,
                                        mainlinesFiltered,
                                        mainlinesLoading,
                                        mainlineGroupsLoading,
                                        onMainline1Change,
                                        onMainline2Change,
                                        onMainline3Change,
                                      }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { projectData } = useAppSelector((st) => st.projectData);
  const { mainlineValues, mainlineErrors } = useAppSelector((st) => st.mainlineState);
  const { units } = useAppSelector((state) => state.units);
  const isTablet = useMediaQuery('(max-width:850px)');

  return (
    <Box>
      <Grid item container xs={12} columnSpacing={isTablet ? 1 : 3} rowSpacing={1} pl={isTablet ? 2 : 0}>
        <Grid item xs={isTablet ? 14 : 7} ml={isTablet ? -2 : 0}>
          <ProductSelect
            label={`${t('pipe1FromValve')}`}
            count={1}
            value={mainlinePipe1}
            onChange={onMainline1Change}
            options={mainlinesFiltered}
            loading={mainlinesLoading}
            disabled={mainlinesLoading || mainlineGroupsLoading}
            error={!mainlinePipe1}
          />
        </Grid>

        <Grid item xs={isTablet ? 4 : 1.66}>
          <Input
            label={`${t('V')} (${units.velocity})`}
            tooltip={`${t('velocity')} (${units.velocity})`}
            isResultBox
            value={mainlineValues.velocity1}
            isErrorBox={mainlineErrors.velocity1}
          />
        </Grid>

        <Grid item xs={isTablet ? 4 : 1.66}>
          <Input
            label={`${t('I.D')} (${units.pipeDiameter})`}
            tooltip={`${t('internalDia')} (${units.pipeDiameter})`}
            isNotifyBox={projectData.mainlinePipe1Dia === mainlinePipe1?.INTRNL}
            value={projectData.mainlinePipe1Dia}
            onChange={(e) =>
              dispatch(setProjectValue({ mainlinePipe1Dia: formattedInputValue(e as ChangeEvent<HTMLInputElement>) }))
            }
          />
        </Grid>
        <Grid item xs={isTablet ? 4 : 1.66}>
          <Input
            label={`${t('L')} (${units.length})`}
            tooltip={`${t('length')} (${units.length})`}
            isResultBox={mainlineValues.isCalcLength}
            value={projectData.mainlinePipe1Length}
            onChange={(e) =>
              dispatch(
                setProjectValue({ mainlinePipe1Length: formattedInputValue(e as ChangeEvent<HTMLInputElement>) }),
              )
            }
          />
        </Grid>
      </Grid>
      <Grid item container xs={12} columnSpacing={isTablet ? 1 : 3} mt={0.5} rowSpacing={1} pl={isTablet ? 2 : 0}>
        <Grid item xs={isTablet ? 14 : 7} ml={isTablet ? -2 : 0}>
          <ProductSelect
            label={`${t('pipe2From1')}`}
            count={2}
            value={mainlinePipe2}
            onChange={onMainline2Change}
            options={mainlinesFiltered}
            loading={mainlinesLoading}
            disabled={mainlinesLoading || mainlineGroupsLoading}
            error={!mainlinePipe2}
          />
        </Grid>

        <Grid item xs={isTablet ? 4 : 1.66}>
          <Input
            label={`${t('V')} (${units.velocity})`}
            tooltip={`${t('velocity')} (${units.velocity})`}
            isResultBox
            value={mainlineValues.velocity2}
            isErrorBox={mainlineErrors.velocity2}
          />
        </Grid>

        <Grid item xs={isTablet ? 4 : 1.66}>
          <Input
            label={`${t('I.D')} (${units.pipeDiameter})`}
            tooltip={`${t('internalDia')} (${units.pipeDiameter})`}
            isNotifyBox={projectData.mainlinePipe2Dia === mainlinePipe2?.INTRNL}
            value={projectData.mainlinePipe2Dia}
            onChange={(e) =>
              dispatch(setProjectValue({ mainlinePipe2Dia: formattedInputValue(e as ChangeEvent<HTMLInputElement>) }))
            }
          />
        </Grid>
        <Grid item xs={isTablet ? 4 : 1.66}>
          <Input
            label={`${t('L')} (${units.length})`}
            tooltip={`${t('length')} (${units.length})`}
            isResultBox={mainlineValues.isCalcLength}
            value={projectData.mainlinePipe2Length}
            onChange={(e) =>
              dispatch(
                setProjectValue({ mainlinePipe2Length: formattedInputValue(e as ChangeEvent<HTMLInputElement>) }),
              )
            }
          />
        </Grid>
      </Grid>
      <Grid item container xs={12} columnSpacing={isTablet ? 1 : 3} mt={0.5} rowSpacing={1} pl={isTablet ? 2 : 0}>
        <Grid item xs={isTablet ? 14 : 7} ml={isTablet ? -2 : 0}>
          <ProductSelect
            label={`${t('pipe3From2')}`}
            count={3}
            value={mainlinePipe3}
            onChange={onMainline3Change}
            options={mainlinesFiltered}
            loading={mainlinesLoading}
            disabled={mainlinesLoading || mainlineGroupsLoading}
            error={!mainlinePipe3}
          />
        </Grid>

        <Grid item xs={isTablet ? 4 : 1.66}>
          <Input
            label={`${t('V')} (${units.velocity})`}
            tooltip={`${t('velocity')} (${units.velocity})`}
            isResultBox
            value={mainlineValues.velocity3}
            isErrorBox={mainlineErrors.velocity3}
          />
        </Grid>

        <Grid item xs={isTablet ? 4 : 1.66}>
          <Input
            label={`${t('I.D')} (${units.pipeDiameter})`}
            tooltip={`${t('internalDia')} (${units.pipeDiameter})`}
            isNotifyBox={projectData.mainlinePipe3Dia === mainlinePipe3?.INTRNL}
            value={projectData.mainlinePipe3Dia}
            onChange={(e) =>
              dispatch(setProjectValue({ mainlinePipe3Dia: formattedInputValue(e as ChangeEvent<HTMLInputElement>) }))
            }
          />
        </Grid>
        <Grid item xs={isTablet ? 4 : 1.66}>
          <Input
            label={`${t('L')} (${units.length})`}
            tooltip={`${t('length')} (${units.length})`}
            isResultBox={mainlineValues.isCalcLength}
            value={projectData.mainlinePipe3Length}
            onChange={(e) =>
              dispatch(
                setProjectValue({ mainlinePipe3Length: formattedInputValue(e as ChangeEvent<HTMLInputElement>) }),
              )
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
};

interface CalcButtonProps {
  mainlinesFiltered: CatalogItem[];
}

const CalcButton: FC<CalcButtonProps> = () => {
  const dispatch = useAppDispatch();
  const { showError } = useToast();
  const { t } = useTranslation();
  const { projectData } = useAppSelector((st) => st.projectData);
  const { mainlineValues } = useAppSelector((st) => st.mainlineState);
  const { submainValues } = useAppSelector((st) => st.submainState);
  const { units } = useAppSelector((state) => state.units);
  const isMobile = useMediaQuery('(max-width:550px)');
  const isTablet = useMediaQuery('(max-width:850px)');

  const onCalculateClick = () => {
    isMobile && scrollToResults();
    const values = {
      MainlineHWCoef: +projectData.mainlineHWCoef,
      MainlineAddlHeadloss: +mainlineValues.pumpHeadloss,
      MainlineFlow: +mainlineValues.flow,
      MainlinePipe1Length: +projectData.mainlinePipe1Length,
      MainlinePipe2Length: +projectData.mainlinePipe2Length,
      MainlinePipe3Length: +projectData.mainlinePipe3Length,
      MainlinePipe1Dia: +projectData.mainlinePipe1Dia,
      MainlinePipe2Dia: +projectData.mainlinePipe2Dia,
      MainlinePipe3Dia: +projectData.mainlinePipe3Dia,
      MainlinePumpPressure: mainlineValues.pumpPressure,
      MainlinePipeHeadloss: mainlineValues.pipesHeadloss,
      MainlinePressureAtValve: +submainValues.valvePressure + +mainlineValues.valveHeadloss,
      MainlineSlope: +projectData.mainlineSlope,
      MainlineSlopeDirection: projectData.mainlineSlopeDir,
      MainlineHeadlossCalculation: projectData.mainlineHeadlossCalc,
      MainlineLength: +mainlineValues.length,
      MainlineAllowedHL: +mainlineValues.allowableHeadloss,
      MainlinePipe1: projectData.mainlinePipe1,
      MainlinePipe2: projectData.mainlinePipe2,
      MainlinePipe3: projectData.mainlinePipe3,
      MainlineVelocity1: mainlineValues.velocity1,
      MainlineVelocity2: mainlineValues.velocity2,
      MainlineVelocity3: mainlineValues.velocity3,
      MainlineMaxVelocity: +mainlineValues.maxVelocity,
    };

    console.log('####: values', values);

    const result = mainLineCalculate(values, units, mainlineValues.isCalcLength, true) as MainlineCalcResult;

    console.log('####: result', result);

    if (result.error) {
      showError(t(`${result.error}`));
      return;
    }

    dispatch(
      setProjectValue({
        mainlinePipe1Length: result.Length1,
        mainlinePipe2Length: result.Length2,
        mainlinePipe3Length: result.Length3,
      }),
    );

    dispatch(
      setMainlineStateValue({
        pipesHeadloss: result.PipesHeadloss,
        pumpPressure: result.PumpPressure,
        // pressureAtValve: result.PressureAtValve,
      }),
    );
  };

  return (
    <Grid item container xs={12} columnSpacing={3} mt={1}>
      <Grid item xs={isTablet ? 8 : 9}></Grid>
      <Grid item xs={isMobile ? 12 : isTablet ? 4 : 2.8}>
        <ShepherdBlock id='submain-calc-button'>
          <Button iconType='calculation' onClick={onCalculateClick}>
            {t('calculate')}
          </Button>
        </ShepherdBlock>
      </Grid>
    </Grid>
  );
};

export const SimulationBlock: FC<Props> = ({
                                             mainlinePipe1,
                                             mainlinePipe2,
                                             mainlinePipe3,
                                             mainlinesFiltered,
                                             mainlinesLoading,
                                             mainlineGroupsLoading,
                                             onMainline1Change,
                                             onMainline2Change,
                                             onMainline3Change,
                                           }) => {
  const isTablet = useMediaQuery('(max-width:850px)');
  const { t } = useTranslation();

  return (
    <Box width='100%'>
      {isTablet ? (
        <Accordion defaultExpanded header={<IconLabel iconSrc={pipe_dia}>{t('pipeDiameters')}</IconLabel>}>
          <>
            <SimulationContent
              mainlinePipe1={mainlinePipe1}
              mainlinePipe2={mainlinePipe2}
              mainlinePipe3={mainlinePipe3}
              mainlinesFiltered={mainlinesFiltered}
              mainlinesLoading={mainlinesLoading}
              mainlineGroupsLoading={mainlineGroupsLoading}
              onMainline1Change={onMainline1Change}
              onMainline2Change={onMainline2Change}
              onMainline3Change={onMainline3Change}
            />
            <CalcButton mainlinesFiltered={mainlinesFiltered} />
          </>
        </Accordion>
      ) : (
        <Box>
          <PaperBox>
            <SimulationContent
              mainlinePipe1={mainlinePipe1}
              mainlinePipe2={mainlinePipe2}
              mainlinePipe3={mainlinePipe3}
              mainlinesFiltered={mainlinesFiltered}
              mainlinesLoading={mainlinesLoading}
              mainlineGroupsLoading={mainlineGroupsLoading}
              onMainline1Change={onMainline1Change}
              onMainline2Change={onMainline2Change}
              onMainline3Change={onMainline3Change}
            />
          </PaperBox>
          <CalcButton mainlinesFiltered={mainlinesFiltered} />
        </Box>
      )}
    </Box>
  );
};
