import { SelectChangeEvent } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { customAxios } from 'shared/api';
import { useAppSelector } from 'shared/hooks';
import { CatalogItem } from 'shared/models';

const ALL = 'All';

export const useSubmains = ({
                              limited,
                              region,
                              standard,
                              handleSubmainDataChange,
                            }: {
  region: string;
  standard: boolean;
  limited: boolean;
  handleSubmainDataChange: (
    value: Partial<{
      masterGroup: number;
      group: string;
      submain: string;
    }>,
  ) => void;
}) => {
  const { submainGroups, submains: defaultSubmains } = useAppSelector((state) => state.submains);
  const { projectData } = useAppSelector((state) => state.projectData);

  const [groupTypes, setGroupTypes] = useState<any>(submainGroups);
  const [submains, setSubmains] = useState<CatalogItem[]>(defaultSubmains);

  const [groupsLoading, setGroupsLoading] = useState(false);
  const [submainsLoading, setSubmainsLoading] = useState(false);

  const [masterGroupId, setMasterGroupId] = useState(projectData.submainMasterGroup);
  const [groupTypeId, setGroupTypeId] = useState(projectData.submainGroup.trim());
  const [classType, setClassType] = useState<string | number>(ALL);
  const [submain, setSubmain] = useState<CatalogItem>(
    () => submains.find((item) => item.CATLOG === projectData.submainCatalog) as CatalogItem,
  );

  useEffect(() => {
    handleSubmainDataChange({ submain: submain?.CATLOG });
  }, [submain]);

  useEffect(() => {
    handleSubmainDataChange({ group: groupTypeId });
  }, [groupTypeId]);

  useEffect(() => {
    handleSubmainDataChange({ masterGroup: masterGroupId });
  }, [masterGroupId]);

  useEffect(() => {
    updateSubmains(groupTypeId, region, standard, limited);
  }, [region, standard, limited]);

  const updateSubmains = async (group: string, region: string, standard: boolean, limited: boolean) => {
    setSubmainsLoading(true);
    const submainsResponse = await customAxios.get<any>(
      'api/ClientBin/WaterSL-Web-WaterDomainService.svc/JSON/GetCatalogsByClass',
      {
        params: {
          group,
          region,
          standard,
          limited,
        },
      },
    );

    setSubmains(submainsResponse.data.GetCatalogsByClassResult.RootResults as CatalogItem[]);
    setSubmain(submainsResponse.data.GetCatalogsByClassResult.RootResults[0] ?? {});
    setSubmainsLoading(false);
  };

  const onMasterGroupChange = async (e: SelectChangeEvent<unknown>) => {
    setMasterGroupId(+(e.target.value as string));
    resetFilters();

    setGroupsLoading(true);
    const groupTypesResponse = await customAxios.get<any>(
      'api/ClientBin/WaterSL-Web-WaterDomainService.svc/JSON/GetGroupTypes',
      { params: { masterGroup: e.target.value } },
    );

    setGroupTypes(groupTypesResponse.data.GetGroupTypesResult.RootResults);
    setGroupTypeId(groupTypesResponse.data.GetGroupTypesResult.RootResults[0].GROUPS);
    setGroupsLoading(false);

    await updateSubmains(groupTypesResponse.data.GetGroupTypesResult.RootResults[0].GROUPS, region, standard, limited);
  };
  const onGroupTypeChange = async (e: SelectChangeEvent<unknown>) => {
    setGroupTypeId(e.target.value as string);
    resetFilters();

    await updateSubmains(e.target.value as string, region, standard, limited);
  };
  const onSubmainChange = (e: any, item: CatalogItem) => {
    setSubmain(item);
  };

  const onClassTypeChange = (e: SelectChangeEvent<unknown>) => {
    setClassType(e.target.value as string);
  };

  const classTypeFilter = (item: CatalogItem) => {
    // if (projectData.Region === 'USA' && item.AltClass !== null) {
    //   return submainClassType ? item.AltClass?.toString().trim() === submainClassType : true;
    // }

    return classType !== 'All' ? item.Class.trim() === classType : true;
  };

  const resetFilters = () => {
    setClassType(ALL);
  };

  const submainsFiltered = useMemo(() => {
    const result = submains.filter((item) => classTypeFilter(item));

    if (!result.find((item) => item.CATLOG === submain?.CATLOG)) {
      setSubmain(result[0]);
    }

    return result;
  }, [classType, submains]);

  const classTypes = useMemo(() => {
    setClassType(ALL);

    return [ALL, ...new Set(submains.map((item) => item.Class))];
  }, [submains]);

  return {
    masterGroupId,
    onMasterGroupChange,
    onGroupTypeChange,
    classType,
    onClassTypeChange,
    classTypes,
    submain,
    onSubmainChange,
    submainsFiltered,
    groupTypes,
    groupsLoading,
    submainsLoading,
    groupTypeId,
  };
};
