import { CatalogItem, Unit } from 'shared/models';
import { METRIC_DEFAULTS } from 'shared/constants';
import { Math_round } from '../../mathRound';
import { ConvertUnit } from '../../unitConverter';

export const calculateNominalPressure = (catalogItem: CatalogItem, units: Unit, value: number) => {
  const nominalFlow = ConvertUnit(value, units.flow, METRIC_DEFAULTS.Flow, 'Flow');
  const roundedCatalogItemEmitterQA = Math_round(catalogItem.EMITTERQA, 3);
  const roundedCatalogItemEmitterQB = Math_round(catalogItem.EMITTERQB, 3);

  // if (!nominalFlow) {
  //   return value;
  // }

  // if (!roundedCatalogItemEmitterQA) {
  //   return value;
  // }

  // if (!roundedCatalogItemEmitterQB) {
  //   return value;
  // }

  const nominalPressure = Math_round(
    Math.pow(nominalFlow / roundedCatalogItemEmitterQA, 1.0 / roundedCatalogItemEmitterQB),
    3,
  );

  return ConvertUnit(
    isNaN(nominalPressure) ? catalogItem.PressureNominal_m_ : nominalPressure,
    METRIC_DEFAULTS.Pressure,
    units.pressure,
    '',
  );
};
