import {
  Autocomplete,
  Box,
  CircularProgress,
  createFilterOptions,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'shared/hooks';
import { CatalogItem } from 'shared/models';

interface Props {
  onChange: (e: any, item: CatalogItem) => void;
  value: CatalogItem;
  options: CatalogItem[];
  loading?: boolean;
  disabled?: boolean;
  label?: string;
  tooltip?: string;
  count?: number;
  error?: boolean;
}

export const ProductSelect: FC<Props> = ({
                                           onChange,
                                           options,
                                           value,
                                           disabled,
                                           loading,
                                           tooltip,
                                           label,
                                           count,
                                           error,
                                         }) => {
  const { palette } = useTheme();
  const { t } = useTranslation();
  const { projectData } = useAppSelector((state) => state.projectData);

  return (
    <Box sx={{ width: '100%' }}>
      {label && (
        <Box display='flex'>
          <Tooltip title={tooltip ?? ''} placement='top'>
            <Typography
              noWrap
              sx={{
                ml: count ? '20px' : 0,
                fontSize: '12px',
                // textTransform: 'uppercase',
                color: 'secondary.main',
                marginBottom: '3px',
              }}
            >
              {error ? <span style={{ color: palette.error.main }}>{t('productNotAvailable')}</span> : label}
            </Typography>
          </Tooltip>
        </Box>
      )}
      <Stack direction='row' alignItems='center'>
        {count && (
          <Typography
            sx={{
              fontSize: '14px',
              textTransform: 'uppercase',
              color: '#4D4E4C',
              fontWeight: 500,
              mr: '5px',
            }}
          >
            {`${count}:`}
          </Typography>
        )}
        <Autocomplete
          disableClearable
          disabled={disabled}
          onChange={onChange}
          value={value}
          options={error ? [] : options}
          getOptionLabel={(item) =>
            projectData.region === 'USA' ? item.AltCATLOGDESC.trim() ?? item.CATLOGDESC.trim() : item.CATLOGDESC.trim()
          }
          isOptionEqualToValue={(option, value) => option.CATLOG === value.CATLOG}
          sx={{
            flexGrow: 1,
            background: '#FFF',
            borderRadius: '9px',
            '.MuiOutlinedInput-root': {
              height: 34,
              fontSize: 14,
              borderRadius: '9px',
              p: '0 8px',
            },
            button: {
              p: 0,
              '&: hover': {
                bgcolor: 'transparent',
              },
            },
            ...(error && {
              background: palette.error.light,
              '& .MuiOutlinedInput-root': { '& > fieldset': { border: `1px solid ${palette.error.main}` } },
            }),
          }}
          filterOptions={createFilterOptions({ matchFrom: 'any' })}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color='inherit' size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </Stack>
    </Box>
  );
};
