import { Box, Grid, MenuItem, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { combined_ico, lateral_ico } from 'shared/assets';
import { useAppSelector, useLateralFilters } from 'shared/hooks';
import { AvailableInfo, IconLabel, PaperBox, ProductSelect, Select } from 'shared/ui';

export const LateralBlock = () => {
  const { lateralGroups, lateralGroupsLoading, lateralsLoading } = useAppSelector((state) => state.laterals);
  const { projectData } = useAppSelector((st) => st.projectData);
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width: 550px)');

  const {
    masterGroups,
    masterGroupId,
    onMasterGroupChange,
    lateralSubtype,
    onGroupTypeChange,
    diameter,
    onDiameterChange,
    classType,
    onClassTypeChange,
    flowPerUnit,
    onFlowPerUnitChange,
    nominalFlow,
    onNominalFlowChange,
    spacing,
    onSpacingChange,
    diameters,
    classTypes,
    flowPerUnits,
    nominalFlows,
    spacings,
    lateralProduct,
    lateralsFiltered,
    onLateralChange,
  } = useLateralFilters();

  return (
    <Box mb={1}>
      <Box sx={{ mb: 1 }}>
        <IconLabel iconSrc={projectData.integrated ? lateral_ico : combined_ico}>{t('lateral')}</IconLabel>
      </Box>
      <PaperBox>
        <Grid container columnSpacing={1}>
          <Grid item xs={isMobile ? 6 : 3}>
            <Select
              label={`${t('type')}`}
              value={masterGroupId}
              onChange={(e) => onMasterGroupChange(+(e.target.value as string))}
            >
              {masterGroups.map((item) => (
                <MenuItem key={item.ID} value={item.ID}>
                  {item.MASTERGROUPNAME}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={isMobile ? 6 : 3}>
            <Select
              label={`${t('subtype')}`}
              value={lateralSubtype}
              onChange={onGroupTypeChange}
              loading={lateralGroupsLoading}
              disabled={lateralGroupsLoading}
            >
              {lateralGroups.map((item) => (
                <MenuItem key={item.GROUPS} value={item.GROUPS}>
                  {item.GROUPTYPENAME}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={isMobile ? 6 : 3}>
            <Select
              label={`${t('dia')}`}
              value={diameter}
              onChange={onDiameterChange}
              disabled={lateralGroupsLoading || lateralsLoading}
            >
              {diameters.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={isMobile ? 6 : 3}>
            <Select
              label={`${t('wall_thickness')}`}
              value={classType}
              onChange={onClassTypeChange}
              disabled={lateralGroupsLoading || lateralsLoading}
            >
              {classTypes.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
        {projectData.integrated && (
          <Grid container columnSpacing={1}>
            <Grid item xs={isMobile ? 6 : 3}>
              <Select
                label={`${t('flowPer')}`}
                value={flowPerUnit}
                onChange={onFlowPerUnitChange}
                disabled={lateralGroupsLoading || lateralsLoading}
              >
                {flowPerUnits.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={isMobile ? 6 : 3}>
              <Select
                label={`${t('nominalFlow')}`}
                value={nominalFlow}
                onChange={onNominalFlowChange}
                disabled={lateralGroupsLoading || lateralsLoading}
              >
                {nominalFlows.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={isMobile ? 6 : 3}>
              <Select
                label={`${t('spacing')}`}
                value={spacing}
                onChange={onSpacingChange}
                disabled={lateralGroupsLoading || lateralsLoading}
              >
                {spacings.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        )}
        <Grid container columnSpacing={1} mt={1}>
          <Grid item xs={isMobile ? 12 : 9}>
            <ProductSelect
              value={lateralProduct}
              onChange={onLateralChange}
              options={lateralsFiltered}
              loading={lateralsLoading}
              disabled={lateralsLoading || lateralGroupsLoading}
              error={!lateralProduct}
            />
          </Grid>
          <Grid item xs={isMobile ? 12 : 3} mt={1}>
            <AvailableInfo amount={lateralsFiltered.length} />
          </Grid>
        </Grid>
      </PaperBox>
    </Box>
  );
};
