import { Box, Typography } from '@mui/material';
import { setProjectValue, togglePressureFlowCalculator } from 'shared/slices';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { Input, Modal } from 'shared/ui';
import { calculateNominalPressure } from 'shared/lib/calculation/laterals/helpers/calculateNominalPressure';
import { calculateNominalFlow } from 'shared/lib/calculation/laterals/helpers/calculateNominalFlow';
import { Math_round } from 'shared/lib/calculation/mathRound';
import { formattedInputValue } from 'shared/lib';

export const PressureFlowCalculator = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { openPressureFlowCalc } = useAppSelector((st) => st.modals);
  const { projectData } = useAppSelector((st) => st.projectData);
  const { laterals } = useAppSelector((st) => st.laterals);
  const { emitters } = useAppSelector((st) => st.emitters);
  const { units } = useAppSelector((st) => st.units);

  const toggle = () => dispatch(togglePressureFlowCalculator());

  const onNominalFlowChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currentLateral = laterals.find((item) => item.CATLOG === projectData.lateralCatalog);
    const currentEmitter = emitters.find((item) => item.CATLOG === projectData.emitterCatalog);

    if (!currentLateral || !currentEmitter) return;

    const nominalFlow = +formattedInputValue(e);
    const nominalPressure = calculateNominalPressure(
      projectData.integrated ? currentLateral : currentEmitter,
      units,
      nominalFlow
    );

    const roundedNominalPressure = Math_round(nominalPressure, 3);

    dispatch(
      setProjectValue({
        emitterNominalFlow: nominalFlow,
        emitterNominalPressure: isFinite(roundedNominalPressure) ? roundedNominalPressure : 0,
      })
    );
  };

  const onPressureChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currentLateral = laterals.find((item) => item.CATLOG === projectData.lateralCatalog);
    const currentEmitter = emitters.find((item) => item.CATLOG === projectData.emitterCatalog);

    if (!currentLateral || !currentEmitter) return;

    const nominalPressure = formattedInputValue(e);
    const nominalFlow = calculateNominalFlow(
      projectData.integrated ? currentLateral : currentEmitter,
      units,
      +nominalPressure
    );

    const roundedNominalFlow = Math_round(nominalFlow, 3);

    dispatch(
      setProjectValue({
        emitterNominalPressure: +nominalPressure,
        emitterNominalFlow: isFinite(roundedNominalFlow) ? roundedNominalFlow : 0,
      })
    );
  };

  const currentProduct = projectData.integrated
    ? laterals.find((item) => item.CATLOG === projectData.lateralCatalog)
    : emitters.find((item) => item.CATLOG === projectData.emitterCatalog);

  return (
    <Modal maxWidth="xs" title={t('pressureVsFlowCalc')} open={openPressureFlowCalc} onClose={toggle}>
      <Box>
        <Typography
          noWrap
          sx={{
            color: 'primary.main',
            fontSize: '16px',
            fontWeight: 400,
            letterSpacing: '0.01em',
            mb: 1,
          }}
        >
          {projectData.region === 'USA' ? currentProduct?.AltCATLOGDESC : currentProduct?.CATLOGDESC}
        </Typography>
      </Box>
      <Box display="flex" columnGap={1} mb={1}>
        <Box flexBasis="50%">
          <Input
            value={projectData.emitterNominalFlow}
            onChange={onNominalFlowChange}
            name="nominalFlow"
            label={`${t('nominalFlow')} (${units.flow})`}
          />
        </Box>
        <Box flexBasis="50%">
          <Input
            value={projectData.emitterNominalPressure}
            onChange={onPressureChange}
            name="nominalPressure"
            label={`${t('nominalPr')} (${units.pressure})`}
          />
        </Box>
      </Box>
    </Modal>
  );
};
