import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  SelectChangeEvent,
  useMediaQuery,
} from '@mui/material';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { REGIONS } from 'shared/constants';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import {
  getEmittersThunk,
  getLateralsThunk,
  getMainlinesThunk,
  getSubmainsThunk,
  setProjectValue,
} from 'shared/slices';
import { Input, PaperBox, Select } from 'shared/ui';

export const GeneralBlock = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width: 550px)');

  const dispatch = useAppDispatch();
  const { projectData } = useAppSelector((st) => st.projectData);

  const onStandardChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setProjectValue({ standard: e.target.checked }));
    dispatch(
      getLateralsThunk({
        group: projectData.lateralGroup,
        region: projectData.region,
        standard: e.target.checked,
        limited: projectData.limited,
      }),
    );
    dispatch(
      getEmittersThunk({
        group: projectData.emitterGroup,
        region: projectData.region,
        standard: e.target.checked,
        limited: projectData.limited,
      }),
    );
    dispatch(
      getSubmainsThunk({
        group: projectData.submainGroup,
        region: projectData.region,
        standard: e.target.checked,
        limited: projectData.limited,
      }),
    );
    dispatch(
      getMainlinesThunk({
        group: projectData.mainlineGroup,
        region: projectData.region,
        standard: e.target.checked,
        limited: projectData.limited,
      }),
    );
  };

  const onLimitedChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setProjectValue({ limited: e.target.checked }));
    dispatch(
      getLateralsThunk({
        group: projectData.lateralGroup,
        region: projectData.region,
        standard: projectData.standard,
        limited: e.target.checked,
      }),
    );
    dispatch(
      getEmittersThunk({
        group: projectData.emitterGroup,
        region: projectData.region,
        standard: projectData.standard,
        limited: e.target.checked,
      }),
    );
    dispatch(
      getSubmainsThunk({
        group: projectData.submainGroup,
        region: projectData.region,
        standard: projectData.standard,
        limited: e.target.checked,
      }),
    );
    dispatch(
      getMainlinesThunk({
        group: projectData.mainlineGroup,
        region: projectData.region,
        standard: projectData.standard,
        limited: e.target.checked,
      }),
    );
  };

  const onRegionChange = (e: SelectChangeEvent<unknown>) => {
    dispatch(setProjectValue({ region: e.target.value as string }));

    dispatch(
      getLateralsThunk({
        group: projectData.lateralGroup,
        region: e.target.value as string,
        standard: projectData.standard,
        limited: projectData.limited,
      }),
    );
    dispatch(
      getEmittersThunk({
        group: projectData.emitterGroup,
        region: e.target.value as string,
        standard: projectData.standard,
        limited: projectData.limited,
      }),
    );
    dispatch(
      getSubmainsThunk({
        group: projectData.submainGroup,
        region: e.target.value as string,
        standard: projectData.standard,
        limited: projectData.limited,
      }),
    );
    dispatch(
      getMainlinesThunk({
        group: projectData.mainlineGroup,
        region: e.target.value as string,
        standard: projectData.standard,
        limited: projectData.limited,
      }),
    );
  };

  return (
    <PaperBox>
      <Grid container columnSpacing={1}>
        <Grid item xs={isMobile ? 6 : 3}>
          <Input
            type='text'
            name='designer'
            label={`${t('designer')}`}
            value={projectData.designer}
            onChange={(e) => dispatch(setProjectValue({ designer: e.target.value }))}
          />
        </Grid>
        <Grid item xs={isMobile ? 6 : 3}>
          <Input
            type='text'
            name='company'
            label={`${t('company')}`}
            value={projectData.company}
            onChange={(e) => dispatch(setProjectValue({ company: e.target.value }))}
          />
        </Grid>
        <Grid item xs={isMobile ? 6 : 3}>
          <Input
            type='text'
            name='phone'
            label={`${t('phone')}`}
            value={projectData.phone}
            onChange={(e) => dispatch(setProjectValue({ phone: e.target.value }))}
          />
        </Grid>
        <Grid item xs={isMobile ? 6 : 3}>
          <Select label={`${t('region')}`} value={projectData.region} onChange={onRegionChange}>
            {REGIONS.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>

      <Grid container columnSpacing={1}>
        <Grid item xs={isMobile ? 6 : 3}>
          <Input
            type='text'
            name='mobile'
            label={`${t('mobile')}`}
            value={projectData.mobile}
            onChange={(e) => dispatch(setProjectValue({ mobile: e.target.value }))}
          />
        </Grid>
        <Grid item xs={isMobile ? 6 : 3}>
          <Input
            type='text'
            name='fax'
            label={`${t('fax')}`}
            value={projectData.fax}
            onChange={(e) => dispatch(setProjectValue({ fax: e.target.value }))}
          />
        </Grid>
        <Grid item xs={isMobile ? 6 : 3}>
          <Input
            type='text'
            name='email'
            label={`${t('email')}`}
            value={projectData.email}
            onChange={(e) => dispatch(setProjectValue({ email: e.target.value }))}
          />
        </Grid>
        <Grid item xs={isMobile ? 6 : 3} mt={1}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{ pt: 0, pb: 0 }}
                  value={projectData.standard}
                  checked={projectData.standard}
                  onChange={onStandardChange}
                />
              }
              label={t('standard')}
            />
            <FormControlLabel
              control={
                <Checkbox
                  sx={{ pt: 0, pb: 0 }}
                  value={projectData.limited}
                  checked={projectData.limited}
                  onChange={onLimitedChange}
                />
              }
              label={t('limited')}
            />
          </FormGroup>
        </Grid>
      </Grid>
    </PaperBox>
  );
};
