import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { customAxios } from 'shared/api';
import { CatalogItem, Group, GroupsBody, GroupsResponse, SubmainsData, SubmainsResponse } from 'shared/models';

interface UserState {
  mainlinesLoading: boolean;
  mainlineGroupsLoading: boolean;
  mainlines: CatalogItem[];
  mainlinesLoadedOnce: boolean;
  mainlineGroupsLoadedOnce: boolean;
  mainlineGroups: Group[];
}

const initialState: UserState = {
  mainlinesLoading: true,
  mainlineGroupsLoading: true,
  mainlines: [],
  mainlineGroupsLoadedOnce: false,
  mainlinesLoadedOnce: false,
  mainlineGroups: [],
};

export const getMainlinesThunk = createAsyncThunk('catalogs/getMainlines', async (params: SubmainsData, thunkAPI) => {
  try {
    const response = await customAxios.get<SubmainsResponse>(
      'api/ClientBin/WaterSL-Web-WaterDomainService.svc/JSON/GetCatalogsByClass',
      {
        params,
      }
    );
    return response.data;
  } catch (e) {
    if (e instanceof Error) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
});

export const getMainlineGroupsThunk = createAsyncThunk(
  'groupTypes/getMainlineGroupTypes',
  async (params: GroupsBody, thunkAPI) => {
    try {
      const response = await customAxios.get<GroupsResponse>(
        'api/ClientBin/WaterSL-Web-WaterDomainService.svc/JSON/GetGroupTypes',
        { params }
      );
      return response.data;
    } catch (e) {
      if (e instanceof Error) {
        return thunkAPI.rejectWithValue(e.message);
      }
    }
  }
);

const mainlinesSlice = createSlice({
  name: 'mainlines',
  initialState,
  reducers: {
    resetMainlines: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMainlinesThunk.pending.type, (state) => {
        state.mainlinesLoading = true;
      })
      .addCase(getMainlinesThunk.fulfilled.type, (state, action: PayloadAction<SubmainsResponse>) => {
        state.mainlines = action.payload.GetCatalogsByClassResult.RootResults;
        state.mainlinesLoading = false;
        if (!state.mainlinesLoadedOnce) state.mainlinesLoadedOnce = true;
      })
      .addCase(getMainlinesThunk.rejected.type, (state) => {
        state.mainlinesLoading = false;
      })
      .addCase(getMainlineGroupsThunk.pending.type, (state) => {
        state.mainlineGroupsLoading = true;
      })
      .addCase(getMainlineGroupsThunk.fulfilled.type, (state, action: PayloadAction<GroupsResponse>) => {
        state.mainlineGroups = action.payload.GetGroupTypesResult.RootResults;
        state.mainlineGroupsLoading = false;
        if (!state.mainlineGroupsLoadedOnce) state.mainlineGroupsLoadedOnce = true;
      })
      .addCase(getMainlineGroupsThunk.rejected.type, (state) => {
        state.mainlineGroupsLoading = false;
      });
  },
});

export const { resetMainlines } = mainlinesSlice.actions;

export const mainlinesReducer = mainlinesSlice.reducer;
