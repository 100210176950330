import { SelectChangeEvent } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { customAxios } from 'shared/api';
import { useAppSelector } from 'shared/hooks';
import { CatalogItem } from 'shared/models';

const ALL = 'All';

export const useEmitters = ({
                              handleEmitterDataChange,
                              region,
                              standard,
                              limited,
                            }: {
  region: string;
  standard: boolean;
  limited: boolean;
  handleEmitterDataChange: (
    value: Partial<{
      masterGroup: number;
      group: string;
      emitter: string;
    }>,
  ) => void;
}) => {
  const { emitterGroups, emitters: defaultEmitters } = useAppSelector((state) => state.emitters);
  const { projectData } = useAppSelector((state) => state.projectData);

  const [groupTypes, setGroupTypes] = useState<any>(emitterGroups);
  const [emitters, setEmitters] = useState<CatalogItem[]>(defaultEmitters);

  const [groupsLoading, setGroupsLoading] = useState(false);
  const [emittersLoading, setEmittersLoading] = useState(false);

  const [masterGroupId, setMasterGroupId] = useState(projectData.emitterMasterGroup);
  const [groupTypeId, setGroupTypeId] = useState(projectData.emitterGroup.trim());

  const [nominalFlow, setNominalFlow] = useState<string | number>(ALL);
  const [spacing, setSpacing] = useState<string | number>(ALL);
  const [emitter, setEmitter] = useState<CatalogItem>(
    () => emitters.find((item) => item.CATLOG === projectData.emitterCatalog) as CatalogItem,
  );

  useEffect(() => {
    handleEmitterDataChange({ emitter: emitter?.CATLOG });
  }, [emitter]);

  useEffect(() => {
    handleEmitterDataChange({ group: groupTypeId });
  }, [groupTypeId]);

  useEffect(() => {
    handleEmitterDataChange({ masterGroup: masterGroupId });
  }, [masterGroupId]);

  useEffect(() => {
    updateEmitters(groupTypeId, region, standard, limited);
  }, [region, standard, limited]);

  const updateEmitters = async (group: string, region: string, standard: boolean, limited: boolean) => {
    setEmittersLoading(true);
    const emittersResponse = await customAxios.get<any>(
      'api/ClientBin/WaterSL-Web-WaterDomainService.svc/JSON/GetCatalogs',
      {
        params: {
          group,
          region,
          standard,
          limited,
        },
      },
    );

    setEmitters(emittersResponse.data.GetCatalogsResult.RootResults as CatalogItem[]);
    setEmitter(emittersResponse.data.GetCatalogsResult.RootResults[0] ?? {});
    setEmittersLoading(false);
  };

  const onMasterGroupChange = async (e: SelectChangeEvent<unknown>) => {
    setMasterGroupId(+(e.target.value as string));
    resetFilters();

    setGroupsLoading(true);
    const groupTypesResponse = await customAxios.get<any>(
      'api/ClientBin/WaterSL-Web-WaterDomainService.svc/JSON/GetGroupTypes',
      { params: { masterGroup: e.target.value } },
    );

    setGroupTypes(groupTypesResponse.data.GetGroupTypesResult.RootResults);
    setGroupTypeId(groupTypesResponse.data.GetGroupTypesResult.RootResults[0].GROUPS);
    setGroupsLoading(false);

    await updateEmitters(groupTypesResponse.data.GetGroupTypesResult.RootResults[0].GROUPS, region, standard, limited);
  };
  const onGroupTypeChange = async (e: SelectChangeEvent<unknown>) => {
    setGroupTypeId(e.target.value as string);
    resetFilters();

    await updateEmitters(e.target.value as string, region, standard, limited);
  };
  const onEmitterChange = (e: any, item: CatalogItem) => {
    setEmitter(item);
  };

  const onNominalFlowChange = (e: SelectChangeEvent<unknown>) => {
    setNominalFlow(e.target.value as string);
  };
  const onSpacingChange = (e: SelectChangeEvent<unknown>) => {
    setSpacing(e.target.value as string);
  };

  const nominalFlowFilter = (item: CatalogItem) => {
    // if (projectData.Region === 'USA' && item.AltQnom !== null) {
    //   return emitterNominalFlow ? item.AltQnom === emitterNominalFlow : true;
    // }
    return nominalFlow != 'All' ? item.Qnom === nominalFlow : true;
  };
  const spacingFilter = (item: CatalogItem) => {
    // if (projectData.Region === 'USA' && item.AltSpacing !== null) {
    //   return emitterSpacing ? item.AltSpacing === emitterSpacing : true;
    // }
    return spacing != 'All' ? item.Spacing === spacing : true;
  };

  const resetFilters = () => {
    setNominalFlow(ALL);
    setSpacing(ALL);
  };

  const emittersFiltered = useMemo(() => {
    const result = emitters.filter((item) => nominalFlowFilter(item) && spacingFilter(item));

    if (!result.find((item) => item.CATLOG === emitter?.CATLOG)) {
      setEmitter(result[0]);
    }

    return result;
  }, [nominalFlow, spacing, emitters]);

  const nominalFlows = useMemo(() => {
    setNominalFlow(ALL);
    setSpacing(ALL);

    return [ALL, ...new Set(emitters.map((item) => item.Qnom))];
  }, [emitters]);

  const spacings = useMemo(() => {
    setSpacing(ALL);

    return [ALL, ...new Set(emitters.filter((item) => nominalFlowFilter(item)).map((item) => item.Spacing))];
  }, [nominalFlow, emitters]);

  return {
    masterGroupId,
    onMasterGroupChange,
    onGroupTypeChange,
    nominalFlow,
    onNominalFlowChange,
    nominalFlows,
    spacing,
    onSpacingChange,
    spacings,
    emitter,
    onEmitterChange,
    emittersFiltered,
    groupTypes,
    groupsLoading,
    emittersLoading,
    groupTypeId,
  };
};
