import { JsonArray } from 'json-to-csv-in-browser';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'shared/hooks';
import { Math_round } from 'shared/lib/calculation/mathRound';

export const useSystemSummaryReportData = () => {
  const { t } = useTranslation();
  const { units } = useAppSelector((st) => st.units);

  const { username } = useAppSelector((st) => st.auth);
  const { projectData } = useAppSelector((st) => st.projectData);
  const { submainValues } = useAppSelector((st) => st.submainState);
  const { lateralValues } = useAppSelector((st) => st.lateralState);
  const { mainlineValues } = useAppSelector((st) => st.mainlineState);
  // const { laterals } = useAppSelector((st) => st.laterals);
  // const { emitters } = useAppSelector((st) => st.emitters);

  // const currentProduct = projectData.integrated
  //   ? laterals.find((item) => item.CATLOG === projectData.lateralCatalog)
  //   : emitters.find((item) => item.CATLOG === projectData.emitterCatalog);
  //
  // const pressureAtHead = Math_round(
  //   mainlineValues.pumpPressure && mainlineValues.pipesHeadloss
  //     ? mainlineValues.pumpPressure - mainlineValues.pipesHeadloss
  //     : 0,
  //   3,
  // );

  const pressureAtEnd = Math_round(
    lateralValues.resultReportArray[lateralValues.resultReportArray.length - 1]?.inletPressure ?? 0,
    3,
  );

  const infoData = [
    {
      [t('preparedFor')]: `${projectData.tradingName ?? ''} ${projectData.growerName ?? ''}`,
      [t('by')]: username,
      [t('_date')]: new Date(Date.now()).toLocaleString(),
    },
  ];

  const reportDataLateral = [
    {
      [t('emitterConstant')]: '',
      [t('emitterExponent')]: '',
      [`${t('emitterSpacing')} (${units.length})`]: projectData.emitterSpacing,
      [`${t('nominalFlow')} (${units.flow})`]: projectData.emitterNominalFlow,
      [`${t('nominalPr')} (${units.pressure})`]: projectData.emitterNominalPressure,
      // [t('lateralCatalog')]: appState?.laterals?.find(
      //   (item) => item.CATLOG === projectData.lateralCatalog,
      // )?.CATLOGDESC,
      [`${t('internalDia')} (${units.pipeDiameter})`]: projectData.lateralInletDia,
      [t('kd')]: projectData.lateralKd,
      [t('lateralRep')]: projectData.lateralLength2,
      [`${t('slope')} (%)`]: projectData.mainlineSlope,
      [`${t('inletPr')} ${units.pressure}`]: projectData.lateralInletPressure,
      [`${t('lateralFlow')} ${units.totalFlow}`]: lateralValues.resultTotalFlow ?? 0,
      [t('eu')]: lateralValues.resultEU ?? '',
      [t('Qmin/Qmax')]: lateralValues.resultQMinQMax ?? '',
      [t('Du')]: lateralValues.resultMaxLengthDU ?? '',
      [`${t('flushingEndVelocity')} ${units.velocity}`]: '',
      [`${t('flushingEndPr')} ${units.pressure}`]: '',
    },
  ];

  const reportDataManifold = [
    {
      // [t('catalog')]: appState?.manifoldCatalogs?.find(
      //   (item) => item.CATLOG === projectData.ManifoldPipe1,
      // )?.CATLOGDESC,
      [`${t('length')} (${units.length})`]: projectData.manifoldPipe1Length,
      [`${t('dia')} (${units.pipeDiameter})`]: projectData.manifoldPipe1Dia,
      [`${t('veloctiy')} (${units.velocity})`]: submainValues.velocity1,
    },
    {
      // [t('catalog')]: appState?.manifoldCatalogs?.find(
      //   (item) => item.CATLOG === projectData.ManifoldPipe2,
      // )?.CATLOGDESC,
      [`${t('length')} (${units.length})`]: projectData.manifoldPipe2Length,
      [`${t('dia')} (${units.pipeDiameter})`]: projectData.manifoldPipe2Dia,
      [`${t('veloctiy')} (${units.velocity})`]: submainValues.velocity2,
    },
    {
      // [t('catalog')]: appState?.manifoldCatalogs?.find(
      //   (item) => item.CATLOG === projectData.ManifoldPipe3,
      // )?.CATLOGDESC,
      [`${t('length')} (${units.length})`]: projectData.manifoldPipe3Length,
      [`${t('dia')} (${units.pipeDiameter})`]: projectData.manifoldPipe3Length,
      [`${t('veloctiy')} (${units.velocity})`]: submainValues.velocity3,
    },
  ];

  const reportDataMainline = [
    {
      // [t('catalog')]: appState?.manifoldCatalogs?.find(
      //   (item) => item.CATLOG === projectData.MainlinePipe1,
      // )?.CATLOGDESC,
      [`${t('length')} (${units.length})`]: projectData.mainlinePipe1Length,
      [`${t('dia')} (${units.pipeDiameter})`]: projectData.mainlinePipe1Dia,
      [`${t('veloctiy')} (${units.velocity})`]: mainlineValues.velocity1,
    },
    {
      // [t('catalog')]: appState?.manifoldCatalogs?.find(
      //   (item) => item.CATLOG === projectData.MainlinePipe2,
      // )?.CATLOGDESC,
      [`${t('length')} (${units.length})`]: projectData.mainlinePipe2Length,
      [`${t('dia')} (${units.pipeDiameter})`]: projectData.mainlinePipe2Dia,
      [`${t('veloctiy')} (${units.velocity})`]: mainlineValues.velocity2,
    },
    {
      // [t('catalog')]: appState?.manifoldCatalogs?.find(
      //   (item) => item.CATLOG === projectData.MainlinePipe3,
      // )?.CATLOGDESC,
      [`${t('length')} (${units.length})`]: projectData.mainlinePipe3Length,
      [`${t('dia')} (${units.pipeDiameter})`]: projectData.mainlinePipe3Length,
      [`${t('veloctiy')} (${units.velocity})`]: mainlineValues.velocity3,
    },
  ];

  const reportDataSystem = [
    {
      [`${t('pumpPressure')} (${units.pressure})`]: projectData.mainlinePumpPressure,
      [`${t('pumpHeadloss')} (${units.pressure})`]: mainlineValues.pumpHeadloss,
      [`${t('pressureAtHead')} (${units.pressure})`]:
      +projectData.mainlinePumpPressure - +mainlineValues.pumpHeadloss,
      [`${t('pipesHeadloss')} (${units.pressure})`]: projectData.mainlinePipeHeadloss,
      [`${t('nominalPr')} (${units.pressure})`]: projectData.emitterNominalPressure,
      [`${t('pressureAtValve')} (${units.pressure})`]: projectData.mainlinePressureAtValve,
      [`${t('valveHeadloss')} (${units.pressure})`]: mainlineValues.valveHeadloss,
      [`${t('prAtManifoldInlet')} (${units.pressure})`]: projectData.valvePressure,
      [`${t('manifoldHeadloss')} (${units.pressure})`]: projectData.manifoldHeadloss,
      [`${t('inletPr')} (${units.pressure})`]: projectData.lateralInletPressure,
      [`${t('lateralHL')} (${units.pressure})`]:
      lateralValues.resultTotalHeadloss || lateralValues.resultMaxLengthTotalHeadloss || 0,
      [`${t('prAtLateralEnd')} (${units.pressure})`]: isNaN(pressureAtEnd) ? 0 : pressureAtEnd,
      // projectData.lateralInletPressure - lateralCalcResult.totalHeadloss,
    },
  ];

  const generalDataManifold = [
    {
      [t('flowRate')]: projectData.totalFlow ?? '',
      [t('headloss')]: projectData.manifoldHeadloss ?? '',
    },
  ];

  const generalDataManiline = [
    {
      [t('flowRate')]: projectData.totalFlow ?? '',
      [t('headloss')]: projectData.mainlinePipeHeadloss ?? '',
    },
  ];

  if (
    reportDataLateral &&
    reportDataManifold &&
    reportDataMainline &&
    reportDataSystem &&
    generalDataManifold &&
    generalDataManiline &&
    infoData
  ) {
    const jsonArrayInfo = new JsonArray(infoData);
    const jsonArrayGeneralDataManifold = new JsonArray(generalDataManifold);
    const jsonArrayGeneralDataManiline = new JsonArray(generalDataManiline);
    const jsonArrayDataLateral = new JsonArray(reportDataLateral);
    const jsonArrayDataManifold = new JsonArray(reportDataManifold);
    const jsonArrayDataMainline = new JsonArray(reportDataMainline);
    const jsonArrayDataSystem = new JsonArray(reportDataSystem);

    const strInfo = jsonArrayInfo.convertToCSVstring();
    const strGeneralDataManifold = jsonArrayGeneralDataManifold.convertToCSVstring();
    const strGeneralDataManiline = jsonArrayGeneralDataManiline.convertToCSVstring();
    const strDataLateral = jsonArrayDataLateral.convertToCSVstring();
    const strDataManifold = jsonArrayDataManifold.convertToCSVstring();
    const strDataMainline = jsonArrayDataMainline.convertToCSVstring();
    const strDataSystem = jsonArrayDataSystem.convertToCSVstring();

    const strDisclaimer = t('repDisclaimer').trim();

    return `${strInfo}

                      ${t('lateralRep')}

                      ${strDataLateral}

                      ${t('manifoldRep')}

                      ${strDataManifold}

                      ${strGeneralDataManifold}

                      ${t('mainlineRep')}
                      
                      ${strDataMainline}

                      ${strGeneralDataManiline}

                      ${t('systemRep')}

                      ${strDataSystem}
                      
                      ${strDisclaimer}`;
  }
};
