import { SelectChangeEvent } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { customAxios } from 'shared/api';
import { useAppSelector } from 'shared/hooks';
import { CatalogItem } from 'shared/models';

const ALL = 'All';

export const useMainlines = ({
                               handleMainlineDataChange,
                               limited,
                               region,
                               standard,
                             }: {
  region: string;
  standard: boolean;
  limited: boolean;
  handleMainlineDataChange: (
    value: Partial<{
      masterGroup: number;
      group: string;
      mainline: string;
    }>,
  ) => void;
}) => {
  const { mainlineGroups, mainlines: defaultMainlines } = useAppSelector((state) => state.mainlines);
  const { projectData } = useAppSelector((state) => state.projectData);

  const [groupTypes, setGroupTypes] = useState(mainlineGroups);
  const [mainlines, setMainlines] = useState<CatalogItem[]>(defaultMainlines);

  const [groupsLoading, setGroupsLoading] = useState(false);
  const [mainlinesLoading, setMainlinesLoading] = useState(false);

  const [masterGroupId, setMasterGroupId] = useState(projectData.mainlineMasterGroup);
  const [groupTypeId, setGroupTypeId] = useState(projectData.mainlineGroup.trim());
  const [classType, setClassType] = useState<string | number>(ALL);
  const [mainline, setMainline] = useState<CatalogItem>(
    () => mainlines.find((item) => item.CATLOG === projectData.mainlineCatalog) as CatalogItem,
  );

  useEffect(() => {
    handleMainlineDataChange({ mainline: mainline?.CATLOG });
  }, [mainline]);

  useEffect(() => {
    handleMainlineDataChange({ group: groupTypeId });
  }, [groupTypeId]);

  useEffect(() => {
    handleMainlineDataChange({ masterGroup: masterGroupId });
  }, [masterGroupId]);

  useEffect(() => {
    updateMainlines(groupTypeId, region, standard, limited);
  }, [region, standard, limited]);

  const updateMainlines = async (group: string, region: string, standard: boolean, limited: boolean) => {
    setMainlinesLoading(true);
    const mainlinesResponse = await customAxios.get<any>(
      'api/ClientBin/WaterSL-Web-WaterDomainService.svc/JSON/GetCatalogsByClass',
      {
        params: {
          group,
          region,
          standard,
          limited,
        },
      },
    );

    setMainlines(mainlinesResponse.data.GetCatalogsByClassResult.RootResults as CatalogItem[]);
    setMainline(mainlinesResponse.data.GetCatalogsByClassResult.RootResults[0] ?? {});
    setMainlinesLoading(false);
  };

  const onMasterGroupChange = async (e: SelectChangeEvent<unknown>) => {
    setMasterGroupId(+(e.target.value as string));
    resetFilters();

    setGroupsLoading(true);
    const groupTypesResponse = await customAxios.get<any>(
      'api/ClientBin/WaterSL-Web-WaterDomainService.svc/JSON/GetGroupTypes',
      { params: { masterGroup: e.target.value } },
    );

    setGroupTypes(groupTypesResponse.data.GetGroupTypesResult.RootResults);
    setGroupTypeId(groupTypesResponse.data.GetGroupTypesResult.RootResults[0].GROUPS);
    setGroupsLoading(false);

    await updateMainlines(groupTypesResponse.data.GetGroupTypesResult.RootResults[0].GROUPS, region, standard, limited);
  };
  const onGroupTypeChange = async (e: SelectChangeEvent<unknown>) => {
    setGroupTypeId(e.target.value as string);
    resetFilters();

    await updateMainlines(e.target.value as string, region, standard, limited);
  };
  const onMainlineChange = (e: any, item: CatalogItem) => {
    setMainline(item);
  };

  const onClassTypeChange = (e: SelectChangeEvent<unknown>) => {
    setClassType(e.target.value as string);
  };

  const classTypeFilter = (item: CatalogItem) => {
    // if (projectData.Region === 'USA' && item.AltClass !== null) {
    //   return mainlineClassType ? item.AltClass?.toString().trim() === mainlineClassType : true;
    // }

    return classType !== 'All' ? item.Class.trim() === classType : true;
  };

  const resetFilters = () => {
    setClassType(ALL);
  };

  const mainlinesFiltered = useMemo(() => {
    const result = mainlines.filter((item) => classTypeFilter(item));

    if (!result.find((item) => item.CATLOG === mainline?.CATLOG)) {
      setMainline(result[0]);
    }

    return result;
  }, [classType, mainlines]);

  const classTypes = useMemo(() => {
    setClassType(ALL);

    return [ALL, ...new Set(mainlines.map((item) => item.Class))];
  }, [mainlines]);

  return {
    masterGroupId,
    onMasterGroupChange,
    onGroupTypeChange,
    classType,
    onClassTypeChange,
    classTypes,
    mainline,
    onMainlineChange,
    mainlinesFiltered,
    groupTypes,
    groupsLoading,
    mainlinesLoading,
    groupTypeId,
  };
};
