import { useMediaQuery, Grid, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'shared/hooks';
import { useEDCComparisonFilters } from 'shared/hooks/useEDCComparisonFilters';
import { PaperBox, ProductEDCSelect, Select } from 'shared/ui';

export const EDCComparisonEmitter = () => {
  const { t } = useTranslation();

  const isTablet = useMediaQuery('(max-width:850px)');
  const isMobile = useMediaQuery('(max-width:550px)');

  const { comparisonEmitterLoading: loading } = useAppSelector((st) => st.emittersEDC);

  const {
    emitterFamilies,
    currentEmitterFamily,
    onEmitterFamilyChange,
    orientValue,
    orients,
    onOrientChange,
    spinners,
    spinnerValue,
    onSpinnerChange,
    nozzles,
    nozzleValue,
    onNozzleChange,
    pressures,
    pressureValue,
    onPressureChange,
    risers,
    riserValue,
    onRiserChange,
    flowRates,
    flowRateValue,
    onFlowRateChange,
    ranges,
    rangeValue,
    onRangeChange,
    emittersEDCFiltered,
    emitterEDCProduct,
    onEmitterEDCChange,
  } = useEDCComparisonFilters();

  return (
    <PaperBox>
      <Grid item container xs={12} columnSpacing={isMobile ? 1 : 3} rowSpacing={1}>
        <Grid item xs={isTablet ? 6 : 3}>
          <Select
            label={`${t('name')}`}
            value={currentEmitterFamily}
            onChange={onEmitterFamilyChange}
            loading={loading}
            disabled={loading}
          >
            {emitterFamilies.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={isTablet ? 6 : 3}>
          <Select label={`${t('orientation')}`} value={orientValue} onChange={onOrientChange} disabled={loading}>
            {orients.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={isTablet ? 4 : 3}>
          <Select label={`${t('spinner')}`} value={spinnerValue} onChange={onSpinnerChange} disabled={loading}>
            {spinners.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={isTablet ? 4 : 3}>
          <Select label={`${t('nozzle')}`} value={nozzleValue} onChange={onNozzleChange} disabled={loading}>
            {nozzles.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={isTablet ? 4 : 3}>
          <Select label={`${t('pressure')}`} value={pressureValue} onChange={onPressureChange} disabled={loading}>
            {pressures.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={isTablet ? 4 : 3}>
          <Select label={`${t('riser')}`} value={riserValue} onChange={onRiserChange} disabled={loading}>
            {risers.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={isTablet ? 4 : 3}>
          <Select label={`${t('flowRate')}`} value={flowRateValue} onChange={onFlowRateChange} disabled={loading}>
            {flowRates.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={isTablet ? 4 : 3}>
          <Select label={`${t('range')}`} value={rangeValue} onChange={onRangeChange} disabled={loading}>
            {ranges.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={12}>
          {!!emitterEDCProduct && (
            <ProductEDCSelect
              label={`${t('emitter')}`}
              value={emitterEDCProduct}
              onChange={onEmitterEDCChange}
              options={emittersEDCFiltered}
              loading={loading}
              disabled={loading}
            />
          )}
        </Grid>
      </Grid>
    </PaperBox>
  );
};
