import { METRIC_DEFAULTS } from 'shared/constants';
import { CatalogItem, Unit } from 'shared/models';
import { ConvertUnit } from '../unitConverter';

export function FilterMainlineCatalogs({
                                         maxVelocity,
                                         totalFlow,
                                         cbManifoldPipe,
                                         manifoldClass,
                                         units,
                                       }: FilterManifoldProps) {
  if (cbManifoldPipe.length == 0) return;
  if (maxVelocity <= 0 || totalFlow <= 0) {
    return;
  }

  totalFlow = ConvertUnit(totalFlow, units.totalFlow, METRIC_DEFAULTS.TotalFlow, null);
  maxVelocity = ConvertUnit(maxVelocity, units.velocity, METRIC_DEFAULTS.Velocity, null);

  const minDia = Math.sqrt(((totalFlow / 3600 / maxVelocity) * 4) / Math.PI) * 1000;

  let dia1 = 0;
  let dia2 = 0;
  let dia3 = 0;

  if (cbManifoldPipe.length <= 0) return;

  let cbManifoldPipe1SelectedItem = -1;
  let cbManifoldPipe2SelectedItem = -1;
  let cbManifoldPipe3SelectedItem = -1;

  if (manifoldClass) {
    if (cbManifoldPipe.length >= 2) {
      let dr = null;
      let index = 0;

      for (const dr_loopVariable of cbManifoldPipe) {
        dr = dr_loopVariable;
        if (dia1 == 0 && dr.INTRNL >= minDia) {
          dia1 = dr.INTRNL;
          break;
        }
        index += 1;

        if (index >= cbManifoldPipe.length) {
          index = cbManifoldPipe.length - 1;
        }

        if (index >= 0) {
          cbManifoldPipe3SelectedItem = index;
          cbManifoldPipe2SelectedItem = Math.min(index + 1, cbManifoldPipe.length - 1);
        }

        if (index < cbManifoldPipe.length - 2) {
          cbManifoldPipe1SelectedItem = Math.min(index + 2, cbManifoldPipe.length - 1);
        } else {
          cbManifoldPipe1SelectedItem = cbManifoldPipe.length - 1;
        }
      }
    }
    if (cbManifoldPipe1SelectedItem == -1) {
      cbManifoldPipe1SelectedItem = 0;
    }
    if (cbManifoldPipe2SelectedItem == -1) {
      cbManifoldPipe2SelectedItem = 0;
    }
    if (cbManifoldPipe3SelectedItem == -1) {
      cbManifoldPipe3SelectedItem = 0;
    }
  } else {
    if (cbManifoldPipe1SelectedItem == -1) {
      cbManifoldPipe1SelectedItem = 0;
    }
    if (cbManifoldPipe2SelectedItem == -1) {
      cbManifoldPipe2SelectedItem = 0;
    }
    if (cbManifoldPipe3SelectedItem == -1) {
      cbManifoldPipe3SelectedItem = 0;
    }
  }

  return {
    manifoldPipe1Selected: cbManifoldPipe[cbManifoldPipe1SelectedItem].CATLOG,
    manifoldPipe2Selected: cbManifoldPipe[cbManifoldPipe2SelectedItem].CATLOG,
    manifoldPipe3Selected: cbManifoldPipe[cbManifoldPipe3SelectedItem].CATLOG,
  };
}

export type FilterManifoldProps = {
  maxVelocity: number;
  totalFlow: number;
  cbManifoldPipe: CatalogItem[];
  manifoldClass: string;
  units: Unit;
};
