import { EmitterEDC } from 'shared/models';
import { Math_round } from './calculation/mathRound';

export const getEmitterTableValues = (emitter: EmitterEDC) => {
  const arr = Object.entries(emitter)
    .filter((item) => item[0].includes('place'))
    .sort((a, b) => +a[0].replace('place', '') - +b[0].replace('place', ''))
    .map((item, idx) => ({ distance: emitter.Basedis * idx, emission: Math_round(item[1], 2) }));
  arr.pop();

  return arr;
};
